import React from "react";
import Layout from "../../utils/layout";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import LoaderScreen from "../../utils/loaderScreen";
import TestimonyProfilePage from "../../components/DashboardComponent/testimonyProfile";
import { GetSingleTestimony } from "../../features/testimony/testimonyServicesSync";

function SingleTestimony() {
  const dispatch = useDispatch();

  const { singleTestimonyId } = useParams();

  const { testimonyPageLoad } = useSelector((state) => state.testimony);

  useEffect(() => {
    dispatch(GetSingleTestimony(singleTestimonyId));
  }, [singleTestimonyId]);

  return (
    <Layout>
      {testimonyPageLoad ? <LoaderScreen /> : <TestimonyProfilePage />}
    </Layout>
  );
}

export default SingleTestimony;
