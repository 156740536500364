import { styled } from "styled-components";

export const SideBarDiv = styled.div`
  height: 100%;
  width: 18%;
  background-color: #0716a5;
  margin: 0;
  position: fixed;
  z-index: 30;
  transition: all 0.5s;

  .sidebarConatiner {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    .header {
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 0.3px solid #fff;
      position: relative;

      a {
        text-decoration: none;
      }

      h2 {
        color: #fff;
        font-size: 32px;
        font-weight: 600;
        padding: 15px 0px;
      }

      .header_body {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        margin: 10px;
        display: none;

        .header_icon {
          color: #ffffff;
          font-size: 24px;
        }

        @media screen and (max-width: 920px) {
          display: flex;
        }
      }
    }

    .sidebar_body {
      margin: 20px 0px;
      display: flex;
      flex-direction: column;
      gap: 30px;
      overflow-y: scroll;
      padding-bottom: 150px;
      margin-bottom: 100px;

      &::-webkit-scrollbar-track {
        /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
        border-radius: 5px;
        /* background-color: #f5f5f5; */
      }

      &::-webkit-scrollbar {
        width: 5px;
        /* background-color: #f5f5f5; */
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
        background-color: #98a2b3;
      }

      .sidebar_body_content {
        display: flex;
        align-items: center;
        padding: 8px 12px;
        gap: 15px;
        cursor: pointer;

        &:hover {
          background-color: #00000044;
          border-left: 5px solid #ffffff;
        }

        &.Selected {
          background-color: #00000044;
          border-left: 5px solid #ffffff;
        }

        .sidebar_body_icon_body {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .sidebar_body_text {
          margin: 0;
          color: #fff;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }

    .bottom {
      position: absolute;
      bottom: 0;
      display: flex;
      align-items: center;
      gap: 10px;
      margin: 20px 0px;
      padding: 0px 10px;

      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }

      .subbottom {
        display: flex;
        flex-direction: column;
        gap: 5px;

        h3 {
          margin: 0;
          color: #fff;
          font-size: 18px;
          font-weight: 600;
          text-transform: capitalize;
        }

        p {
          margin: 0;
          color: #fff;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }

  @media screen and (max-width: 1486px) {
    width: 22%;
  }

  @media screen and (max-width: 1164px) {
    width: 25%;
  }

  @media screen and (max-width: 1036px) {
    width: 28%;
  }

  @media screen and (max-width: 920px) {
    position: fixed;
    display: ${({ content }) => (content ? "block" : "none")};
  }

  @media screen and (max-width: 860px) {
    width: 35%;
  }

  @media screen and (max-width: 720px) {
    width: 45%;
  }

  @media screen and (max-width: 550px) {
    width: 55%;
  }

  @media screen and (max-width: 460px) {
    width: 75%;
  }

  @media screen and (max-width: 360px) {
    width: 85%;
  }
`;
