import React, { useEffect } from 'react';
import Layout from '../../utils/layout';
import { MessageDiv } from './styles/message.style';
import UsersComponent from '../../components/DashboardComponent/usersComponent';
import { useDispatch, useSelector } from 'react-redux';
import { GetAllUserDetail } from '../../features/user/userServiceSync';
import {
  upDateCompleteCounter,
  upDateCompleteSkip,
} from '../../features/user/userSlices';
import { useNavigate } from 'react-router-dom';

const UserCollection = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { userCollectionPage, userCollectionLimit } = useSelector(
    (state) => state.user
  );

  const { userDetails } = useSelector((state) => state.user);

  useEffect(() => {
    if (
      userDetails?.admintype === 'normal_level' ||
      userDetails?.admintype === 'mid_level'
    ) {
      navigate('/dashboard');
    }

    dispatch(
      GetAllUserDetail({ page: userCollectionPage, limit: userCollectionLimit })
    );

    return () => {
      dispatch(upDateCompleteSkip(1));

      dispatch(upDateCompleteCounter(0));
    };
  }, []);

  return (
    <Layout>
      <MessageDiv>
        <div className="message_header">
          <h2>Users</h2>
        </div>
        <div className="message_chat">
          <UsersComponent />
        </div>
      </MessageDiv>
    </Layout>
  );
};

export default UserCollection;
