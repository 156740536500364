import React, { useEffect } from "react";
import Layout from "../../utils/layout";
import { StoriesDiv } from "./styles/message.style";
import { useDispatch, useSelector } from "react-redux";
import { reset } from "../../features/resources/resourcesSlices";
import { GetResources } from "../../features/resources/resourcesServicesSync";
import ResourcesComponent from "../../components/DashboardComponent/resourcesComponents";

const Resources = () => {
  const dispatch = useDispatch();

  const { ordersLimit } = useSelector((state) => state.resources);

  useEffect(() => {
    dispatch(GetResources(ordersLimit));

    return () => {
      dispatch(reset());
    };
  }, []);

  return (
    <Layout>
      <StoriesDiv>
        <div className="stories_header">
          <h2>Rosource Page</h2>
        </div>

        <div className="stories_content">
          <ResourcesComponent />
        </div>
      </StoriesDiv>
    </Layout>
  );
};

export default Resources;
