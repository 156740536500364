import { toast } from "react-toastify";
import APIs from "../apiUrl";

const GalleryImageUpload = async (payload) => {
  try {
    const response = await APIs.post("/uploadfiles", payload);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const editAGallery = async (payload) => {
  try {
    const response = await APIs.patch("/edit_gallery", payload);

    if (response?.data?.message === "success") {
      toast("Posted successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const postGallery = async (payload) => {
  try {
    const response = await APIs.post("/create_gallery", payload);

    if (response?.data?.message === "success") {
      toast("Posted successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getGallery = async (payload) => {
  try {
    const response = await APIs.get(
      `/all_gallery/${payload.page}/${payload.limit}`
    );

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getMyCreatedGallery = async (payload) => {
  try {
    const response = await APIs.get(
      `/all_gallery_for_admin/${payload.page}/${payload.limit}`
    );

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const GalleryServices = {
  GalleryImageUpload,
  postGallery,
  getGallery,
  editAGallery,
  getMyCreatedGallery,
};

export default GalleryServices;
