import { styled } from "styled-components";

export const MessageDiv = styled.div`
  width: 100%;

  .message_header {
    display: flex;
    flex-direction: column;
    gap: 12px;

    h2 {
      margin: 0;
      color: #101828;
      font-size: 32px;
      /* font-family: Roboto; */
      font-weight: 700;
      line-height: 30px;
    }

    p {
      margin: 0;
      color: #101828;
      font-size: 14px;
      /* font-family: Roboto; */
      font-weight: 400;
      line-height: 30px;
    }
  }

  .message_chat {
    width: 100%;
    margin-top: 65px;
  }
`;

export const DashboardDiv = styled.div`
  width: 100%;

  .dashboard_header {
    display: flex;
    flex-direction: column;
    gap: 12px;

    h2 {
      margin: 0;
      color: #101828;
      font-size: 24px;
      /* font-family: Roboto; */
      font-weight: 700;
      line-height: 30px;
    }

    p {
      margin: 0;
      color: #101828;
      font-size: 14px;
      /* font-family: Roboto; */
      font-weight: 400;
      line-height: 30px;
    }
  }

  .dashbaord_content {
    width: 100%;
    margin-top: 65px;
  }
`;

export const StoriesDiv = styled.div`
  width: 100%;

  .stories_header {
    display: flex;
    flex-direction: column;
    gap: 12px;

    h2 {
      margin: 0;
      color: #101828;
      font-size: 24px;
      /* font-family: Roboto; */
      font-weight: 700;
      line-height: 30px;
    }

    p {
      margin: 0;
      color: #101828;
      font-size: 14px;
      /* font-family: Roboto; */
      font-weight: 400;
      line-height: 30px;
    }
  }

  .stories_content {
    width: 100%;
    margin-top: 65px;
  }
`;

export const TaskDiv = styled.div`
  width: 100%;

  .task_header {
    display: flex;
    flex-direction: column;
    gap: 12px;

    h2 {
      margin: 0;
      color: #101828;
      font-size: 24px;
      /* font-family: Roboto; */
      font-weight: 700;
      line-height: 30px;
    }

    p {
      margin: 0;
      color: #101828;
      font-size: 14px;
      /* font-family: Roboto; */
      font-weight: 400;
      line-height: 30px;
    }
  }

  .task_content {
    width: 100%;
    margin-top: 65px;
  }
`;

export const CreatePostDiv = styled.div`
  width: 100%;

  .createpost_header {
    display: flex;
    flex-direction: column;
    gap: 12px;

    h2 {
      margin: 0;
      color: #101828;
      font-size: 24px;
      /* font-family: Roboto; */
      font-weight: 700;
      line-height: 30px;
    }

    p {
      margin: 0;
      color: #101828;
      font-size: 14px;
      /* font-family: Roboto; */
      font-weight: 400;
      line-height: 30px;
    }
  }

  .createpost_content {
    width: 100%;
    margin-top: 65px;
  }
`;

export const ProfileDiv = styled.div`
  width: 100%;

  .profile_header {
    display: flex;
    flex-direction: column;
    gap: 12px;

    h2 {
      margin: 0;
      color: #101828;
      font-size: 24px;
      /* font-family: Roboto; */
      font-weight: 700;
      line-height: 30px;
    }

    p {
      margin: 0;
      color: #101828;
      font-size: 14px;
      /* font-family: Roboto; */
      font-weight: 400;
      line-height: 30px;
    }
  }

  .profile_content {
    width: 100%;
    margin-top: 65px;
  }
`;
