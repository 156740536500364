import { toast } from 'react-toastify';
import APIs from '../apiUrl';

const registerUser = async (payload) => {
  try {
    console.log(payload, 'david is coming home today');
    const response = await APIs.post('/add_admin', payload);

    if (response?.data?.message === 'success') {
      toast('Registered Successfully');
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const loginUser = async (payload) => {
  try {
    const response = await APIs.post('/admin_login', payload);

    if (response?.data?.message === 'success') {
      toast('Login Successfully');

      APIs.defaults.headers[
        'Authorization'
      ] = `Bearer ${response?.data?.data?.token}`;

      localStorage.setItem('token', response?.data?.data?.token);
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const subscribeUser = async (payload) => {
  try {
    const response = await APIs.post('/subscribe', payload);

    if (response?.data?.message === 'success') {
      toast('Subscribe Successfully');

      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const contactSupport = async (payload) => {
  try {
    const response = await APIs.post('/contactus', payload);

    if (response?.data?.message === 'success') {
      toast('sent Successfully');

      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getAdminUsers = async () => {
  try {
    const response = await APIs.get(`/get_admin_user`);

    if (response?.data?.message === 'success') {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const AuthServices = {
  registerUser,
  loginUser,
  subscribeUser,
  contactSupport,
  getAdminUsers,
};

export default AuthServices;
