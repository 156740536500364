import React, { useEffect } from 'react';
import Layout from '../../utils/layout';
import { CreatePostDiv } from './styles/message.style';
import CreateFAQComponent from '../../components/DashboardComponent/faqComponents';
import { useDispatch, useSelector } from 'react-redux';
import { GetFaq } from '../../features/faq/faqServicesSyn';
import { reset } from '../../features/faq/faqSlices';

const Faq = () => {
  return (
    <Layout>
      <CreatePostDiv>
        <div className="createpost_header">
          <h2>Create FAQ</h2>
        </div>
        <div className="createpost_content">
          <CreateFAQComponent />
        </div>
      </CreatePostDiv>
    </Layout>
  );
};

export default Faq;
