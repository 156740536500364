import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import SermonServices from "./sermonServices";

export const UploadImageSermon = createAsyncThunk(
  "sermon/uploadimage",
  async (payload) => {
    try {
      let response = await SermonServices.SermonImageUpload(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const UploadPdfFile = createAsyncThunk(
  "sermon/uploadpdf",
  async (content) => {
    try {
      let checkValidity = await SermonServices.uploadPdfFile(content);

      toast("successful");
      return checkValidity;
    } catch (err) {
      toast(err.message);
      throw err;
    }
  }
);

export const PostSermon = createAsyncThunk(
  "sermon/postSermon",
  async (payload) => {
    try {
      let response = await SermonServices.postSermons(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const EditSermon = createAsyncThunk(
  "sermon/editSermon",
  async (payload) => {
    try {
      let response = await SermonServices.editASermon(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetSermons = createAsyncThunk(
  "sermon/getSermons",
  async (payload) => {
    try {
      let response = await SermonServices.getSermons(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetSermonsAdvanced = createAsyncThunk(
  "sermon/getAdvSermons",
  async (payload) => {
    try {
      let response = await SermonServices.getSermons(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetMySermons = createAsyncThunk(
  "sermon/getmySermons",
  async (payload) => {
    try {
      let response = await SermonServices.getMySermons(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetMySermonsAdvanced = createAsyncThunk(
  "sermon/getmyAdvSermons",
  async (payload) => {
    try {
      let response = await SermonServices.getMySermons(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);
