import React from "react";
import Layout from "../../utils/layout";
import { ProfileDiv } from "./styles/message.style";
import MinistersComponents from "../../components/DashboardComponent/ministersComponents";

const CreateTeams = () => {
  return (
    <Layout>
      <ProfileDiv>
        <div className="profile_header">
          <h2>Create Teams</h2>
        </div>
        <div className="profile_content">
          <MinistersComponents />
        </div>
      </ProfileDiv>
    </Layout>
  );
};

export default CreateTeams;
