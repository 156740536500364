import React, { useRef, useState } from 'react';
import CardImage from '../../../Assets/program.svg';
import ProfilePic from '../../../Assets/images/avatar-profile-photo.svg';
import { FiEdit, FiShare2 } from 'react-icons/fi';
import { MinistriesCardDiv, NotFound } from './styles/ministriesCard.style';
import HTMLReactParser from 'html-react-parser';
import moment from 'moment';
import { BsLinkedin, BsFacebook, BsTwitter, BsWhatsapp } from 'react-icons/bs';
import { BiSolidCopy } from 'react-icons/bi';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { NavLink, useNavigate } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { baseUrl } from '../../../utils/baseUrl';
import { useDispatch } from 'react-redux';
import { updateSingleMinistriesDetails } from '../../../features/ministries/ministriesSlices';

const MinistriesCard = ({ cardArry }) => {
  const dropRef = useRef();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [selectedId, setSelectedId] = useState();

  useOnClickOutside(dropRef, () => setSelectedId());
  return (
    <>
      {cardArry?.length > 0 ? (
        cardArry?.map((item, key) => (
          <MinistriesCardDiv key={key}>
            <div className="cards_component">
              <div className="cards_image">
                <img
                  src={item?.cover_pic ? `${item?.cover_pic}` : CardImage}
                  loading="lazy"
                />
              </div>

              <div className="cards_content">
                <NavLink
                  to={`${baseUrl}/dashboard/ministry/${item?._id}`}
                  target="blank"
                  className="cards_content_header"
                >
                  <div className="cards_content_header_Profile">
                    <img
                      src={item?.profile_pic ? item?.profile_pic : ProfilePic}
                    />
                    <h4>{item?.username}</h4>
                  </div>
                  <div className="cards_content_header_content">
                    <h3>{item?.title}</h3>

                    <p>{HTMLReactParser(HTMLReactParser(item?.article))}</p>
                  </div>
                </NavLink>
                <div className="cards_content_bottom">
                  <div className="cards_content_bottom_time">
                    <p>{`${moment(item?.date).format(
                      'MMM DD, YYYY hh:mm'
                    )}`}</p>
                  </div>

                  <div className="cards_content_container">
                    <div
                      className="cards_content_container_edit"
                      onClick={() => {
                        dispatch(updateSingleMinistriesDetails(item));

                        navigate('/dashboard/create_project');
                      }}
                    >
                      <FiEdit className="cards_content_container_edit_icon" />
                    </div>

                    <div className="cards_content_bottom_share">
                      <div
                        className="cards_content_bottom_share_icon"
                        onClick={() => setSelectedId(item?._id)}
                      >
                        <FiShare2 className="cards_content_bottom_share_icon_body" />
                      </div>

                      {selectedId === item?._id && (
                        <div className="social_media" ref={dropRef}>
                          <CopyToClipboard
                            text={`${baseUrl}/${item?._id}`}
                            onCopy={() => toast('successfully copied')}
                          >
                            <div className="social_media_container">
                              <div className="social_media_container_icon_body">
                                <BiSolidCopy className="social_media_container_icon" />
                              </div>
                              <p className="social_media_container_paragraph">
                                CopyLink
                              </p>
                            </div>
                          </CopyToClipboard>

                          <NavLink
                            to={`https://www.linkedin.com/shareArticle?url=${baseUrl}/${item._id}&title=${item?.title}`}
                            target="blank"
                            className="social_media_container"
                          >
                            <div className="social_media_container_icon_body">
                              <BsLinkedin className="social_media_container_icon" />
                            </div>
                            <p className="social_media_container_paragraph">
                              Linkedin
                            </p>
                          </NavLink>

                          <NavLink
                            to={`https://www.facebook.com/sharer.php?u=${baseUrl}/${item._id}`}
                            target="blank"
                            className="social_media_container"
                          >
                            <div className="social_media_container_icon_body">
                              <BsFacebook className="social_media_container_icon" />
                            </div>
                            <p className="social_media_container_paragraph">
                              FaceBook
                            </p>
                          </NavLink>

                          <NavLink
                            to={`https://twitter.com/share?url=${baseUrl}/${item._id}&text=${item?.title}`}
                            target="blank"
                            className="social_media_container"
                          >
                            <div className="social_media_container_icon_body">
                              <BsTwitter className="social_media_container_icon" />
                            </div>
                            <p className="social_media_container_paragraph">
                              Twitter
                            </p>
                          </NavLink>

                          <NavLink
                            to={`https://api.whatsapp.com/send?text=${item?.title} ${baseUrl}/${item._id}`}
                            target="blank"
                            className="social_media_container"
                          >
                            <div className="social_media_container_icon_body">
                              <BsWhatsapp className="social_media_container_icon" />
                            </div>
                            <p className="social_media_container_paragraph">
                              Whatsapp
                            </p>
                          </NavLink>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </MinistriesCardDiv>
        ))
      ) : (
        <NotFound>
          <div className="not_found_wrapper">
            <p>No Project found</p>
            <button onClick={() => navigate('/dashboard/create_project')}>
              Create one
            </button>
          </div>
        </NotFound>
      )}
    </>
  );
};

export default MinistriesCard;
