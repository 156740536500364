import { styled } from "styled-components";

export const LayoutDiv = styled.div`
  margin-left: calc(100% - 82%);
  background-color: #f7f7f7;

  .layout {
    width: 90%;
    margin: 0px auto;
    padding: 50px 0px 20px 0px;

    @media screen and (max-width: 720px) {
      width: 95%;
    }
  }

  @media screen and (max-width: 1486px) {
    margin-left: calc(100% - 78%);
  }

  @media screen and (max-width: 1164px) {
    margin-left: calc(100% - 75%);
  }

  @media screen and (max-width: 1036px) {
    margin-left: calc(100% - 72%);
  }

  @media screen and (max-width: 920px) {
    margin-left: 0;
  }
`;

export const LoaderScreenDiv = styled.div`
  width: 90%;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 720px) {
    width: 95%;
  }
`;

export const OutSideLoaderScreenDiv = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 720px) {
    width: 100%;
  }
`;

export const MiniLoaderScreenDiv = styled.div`
  width: 90%;
  height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 720px) {
    width: 95%;
  }
`;
