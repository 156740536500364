import React from 'react';
import Layout from '../../utils/layout';
import { CreatePostDiv } from './styles/message.style';
import CreatePostComponent from '../../components/DashboardComponent/createpost';

const Createpost = () => {
  return (
    <Layout>
      <CreatePostDiv>
        <div className="createpost_header">
          <h2>Create a Blog</h2>
          <p>We are Glad to see you again!</p>
        </div>
        <div className="createpost_content">
          <CreatePostComponent />
        </div>
      </CreatePostDiv>
    </Layout>
  );
};

export default Createpost;
