import React from 'react';
import StoriesTab from './storiesTab';
import StoriesCard from './storiesCard';
import { StoriesComponentDiv } from './styles/storiescomponent.styles';
import { useDispatch, useSelector } from 'react-redux';
import MiniLoader from '../../../utils/miniLoader';
import InfiniteScroll from 'react-infinite-scroller';
import { InfinitySpin } from 'react-loader-spinner';
import {
  GetFaqAdvanced,
  GetMyFaqAdvanced,
} from '../../../features/faq/faqServicesSyn';

const ViewFaqComponent = () => {
  const dispatch = useDispatch();

  const {
    isFaqLoading,
    singleFaqPageDetails,
    faqArticleDetails,
    faqPageLoad,
    faqTab,
    orderPagination,
    faqMyArticleDetails,
    ordersFaqCount,
    myFaqCount,
    myPagination,
    mylimit,
    ordersLimit,
    averageLimit,
  } = useSelector((state) => state.faq);

  return (
    <StoriesComponentDiv>
      <div>
        <StoriesTab />
      </div>

      {!faqPageLoad ? (
        <>
          {faqTab === 'all' ? (
            <InfiniteScroll
              pageStart={orderPagination}
              loadMore={async () => {
                if (ordersFaqCount > faqArticleDetails.length) {
                  dispatch(GetFaqAdvanced(ordersLimit + averageLimit));
                }
              }}
              hasMore={true || false}
              loader={
                ordersFaqCount > faqArticleDetails.length && (
                  <InfinitySpin width="200" color="#007bff" />
                )
              }
            >
              <div className="stories_card">
                <StoriesCard cardArry={faqArticleDetails} />
              </div>
            </InfiniteScroll>
          ) : (
            <InfiniteScroll
              pageStart={myPagination}
              loadMore={async () => {
                if (myFaqCount > faqMyArticleDetails.length) {
                  dispatch(GetMyFaqAdvanced(mylimit + averageLimit));
                }
              }}
              hasMore={true || false}
              loader={
                myFaqCount > faqMyArticleDetails.length && (
                  <InfinitySpin width="200" color="#007bff" />
                )
              }
            >
              <div className="stories_card">
                <StoriesCard cardArry={faqMyArticleDetails} />
              </div>
            </InfiniteScroll>
          )}
        </>
      ) : (
        <MiniLoader />
      )}
    </StoriesComponentDiv>
  );
};

export default ViewFaqComponent;
