import React from "react";
import { CirclesWithBar } from "react-loader-spinner";
import { LoaderScreenDiv } from "./styles/layout.styles";

const LoaderScreen = () => {
  return (
    <LoaderScreenDiv>
      <CirclesWithBar
        height="100"
        width="100"
        color="#0716A5"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        outerCircleColor=""
        innerCircleColor=""
        barColor=""
        ariaLabel="circles-with-bar-loading"
      />
    </LoaderScreenDiv>
  );
};

export default LoaderScreen;
