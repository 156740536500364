import { createSlice } from "@reduxjs/toolkit";
import {
  EditMinistry,
  GetMinistries,
  GetMinistriesAdvanced,
  GetMyMinistries,
  GetMyMinistriesAdvanced,
  GetSingleMinistry,
  PostMinistries,
  uploadImageMinistries,
} from "./ministriesServicesSync";

const initialState = {
  isError: false,
  isSuccess: false,
  isPicLoading: false,
  isBlogLoading: false,
  articleImageLoader: false,
  message: "",
  contentDetails: {},
  singleMinistriesDetails: {},
  ministriesArticleDetails: [],
  ministriesMyArticleDetails: [],
  mylimit: 10,
  myPagination: 0,
  ordersLimit: 10,
  orderPagination: 0,
  myMinistriesCount: 0,
  ordersMinistriesCount: 0,
  averageLimit: 10,
  ministriesPageLoad: false,
  ministriesTab: "all",
  singleMinistryPageLoader: false,
  singleMinistryPageDetails: {},
};

export const ministriesSlice = createSlice({
  name: "ministries",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isPicLoading = false;
      state.isSuccess = false;
      state.isBlogLoading = false;
      state.message = "";
      state.contentDetails = {};
      state.ministriesArticleDetails = [];
      state.ministriesMyArticleDetails = [];
      state.ministriesPageLoad = false;
      state.mylimit = 10;
      state.myPagination = 0;
      state.ordersLimit = 10;
      state.orderPagination = 0;
      state.myMinistriesCount = 0;
      state.ordersMinistriesCount = 0;
      state.singleMinistryPageLoader = false;
      state.singleMinistryPageDetails = {};
      state.ministriesTab = "all";
    },

    checkMessage: (state, action) => {
      state.message = action.payload;
    },

    updateCoverPics: (state, action) => {
      state.contentDetails = action.payload;
    },

    updateMinistriesPageLoader: (state, action) => {
      state.ministriesPageLoad = action.payload;
    },

    updateOrderMinistriesCount: (state, action) => {
      state.ordersMinistriesCount = action.payload;
    },

    updateSingleMinistriesDetails: (state, action) => {
      state.singleMinistriesDetails = action.payload;
    },

    updataeSingleMinistryPageLoader: (state, action) => {
      state.singleMinistryPageLoader = action.payload;
    },

    updateMinistriesTab: (state, action) => {
      state.ministriesTab = action.payload;
    },
  },
  extraReducers: {
    [uploadImageMinistries.pending]: (state, action) => {
      state.isPicLoading = true;
    },

    [uploadImageMinistries.fulfilled]: (state, action) => {
      state.isPicLoading = false;
      state.message = action.payload?.message;
      state.contentDetails = action.payload?.data;
    },

    [uploadImageMinistries.rejected]: (state, action) => {
      state.isPicLoading = false;
      state.message = action.payload?.message;
    },

    [PostMinistries.pending]: (state, action) => {
      state.isBlogLoading = true;
    },

    [PostMinistries.fulfilled]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
    },

    [PostMinistries.rejected]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
    },

    [EditMinistry.pending]: (state, action) => {
      state.isBlogLoading = true;
    },

    [EditMinistry.fulfilled]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
    },

    [EditMinistry.rejected]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
    },

    [GetMinistries.pending]: (state, action) => {
      state.ministriesPageLoad = true;
    },

    [GetMinistries.fulfilled]: (state, action) => {
      state.ministriesPageLoad = false;
      state.message = action.payload?.message;
      state.ministriesArticleDetails = action?.payload?.data;
      state.ordersMinistriesCount = action?.payload?.count;
    },

    [GetMinistries.rejected]: (state, action) => {
      state.ministriesPageLoad = false;
      state.message = action.payload?.message;
    },

    [GetMinistriesAdvanced.pending]: (state, action) => {
      state.ministriesPageLoad = false;
    },

    [GetMinistriesAdvanced.fulfilled]: (state, action) => {
      state.ministriesPageLoad = false;
      state.message = action.payload?.message;
      state.orderPagination = state.orderPagination + 1;
      state.ordersLimit = state.ordersLimit + state.averageLimit;
      state.ministriesArticleDetails = [...action?.payload?.data];
      state.ordersMinistriesCount = action?.payload?.count;
    },

    [GetMinistriesAdvanced.rejected]: (state, action) => {
      state.ministriesPageLoad = false;
      state.message = action.payload?.message;
    },

    [GetMyMinistries.pending]: (state, action) => {
      state.ministriesPageLoad = true;
    },

    [GetMyMinistries.fulfilled]: (state, action) => {
      state.ministriesPageLoad = false;
      state.message = action.payload?.message;
      state.ministriesMyArticleDetails = action?.payload?.data;
      state.myMinistriesCount = action?.payload?.count;
    },

    [GetMyMinistries.rejected]: (state, action) => {
      state.ministriesPageLoad = false;
      state.message = action.payload?.message;
    },

    [GetMyMinistriesAdvanced.pending]: (state, action) => {
      state.ministriesPageLoad = false;
    },

    [GetMyMinistriesAdvanced.fulfilled]: (state, action) => {
      state.ministriesPageLoad = false;
      state.message = action.payload?.message;
      state.myPagination = state.myPagination + 1;
      state.mylimit = state.mylimit + state.averageLimit;
      state.ministriesMyArticleDetails = [...action?.payload?.data];
      state.myMinistriesCount = action?.payload?.count;
    },

    [GetMyMinistriesAdvanced.rejected]: (state, action) => {
      state.ministriesPageLoad = false;
      state.message = action.payload?.message;
    },

    [GetSingleMinistry.pending]: (state, action) => {
      state.singleMinistryPageLoader = true;
    },

    [GetSingleMinistry.fulfilled]: (state, action) => {
      state.singleMinistryPageLoader = false;
      state.message = action.payload?.message;
      state.singleMinistryPageDetails = action.payload?.data;
    },

    [GetSingleMinistry.rejected]: (state, action) => {
      state.singleMinistryPageLoader = false;
      state.message = action.payload?.message;
    },
  },
});

export const {
  reset,
  checkMessage,
  updateCoverPics,
  updateMinistriesPageLoader,
  updateOrderMinistriesCount,
  updateSingleMinistriesDetails,
  updataeSingleMinistryPageLoader,
  updateMinistriesTab,
} = ministriesSlice.actions;

export default ministriesSlice.reducer;
