import React, { useEffect, useState } from "react";
import { FaHive, FaMagic, FaSignal, FaSitemap, FaTasks } from "react-icons/fa";
import { DashboardCardDiv } from "./styles/dashboardCard.style";
import { useSelector } from "react-redux";
import { Dna } from "react-loader-spinner";

const CardArrays = [
  { icon: FaSitemap, title: "Total User", count: 0 },
  { icon: FaTasks, title: "All Assessment", count: 0 },
  { icon: FaHive, title: "Marked Assessment", count: 0 },
  { icon: FaSignal, title: "UnMarked Assessment", count: 0 },
  { icon: FaMagic, title: "Total Article", count: 0 },
];

const DashboardCard = () => {
  const { countDetails, isLoading } = useSelector((state) => state.user);

  return (
    <DashboardCardDiv>
      {!isLoading ? (
        <>
          {countDetails?.map((item, key) => (
            <div className="cardComponent" key={key}>
              <div className="cardComponent_content">
                <p className="cardComponent_content_title">{item?.title}</p>
                <p className="cardComponent_content_score">{item?.count}</p>
              </div>

              {item?.icon && (
                <div className="cardComponent_icon_body">
                  <item.icon className="cardComponent_icon" />
                </div>
              )}
            </div>
          ))}
        </>
      ) : (
        <Dna
          visible={true}
          height="80"
          width="80"
          ariaLabel="dna-loading"
          wrapperStyle={{}}
          wrapperClass="dna-wrapper"
        />
      )}
    </DashboardCardDiv>
  );
};

export default DashboardCard;
