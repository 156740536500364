import React from 'react';
import { MinistriesTabDiv, StoriesTabDiv } from './styles/ministriesTab.styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  GetMyCreatedSponsors,
  GetSponsors,
} from '../../../features/sponsor/sponsorServicesSync';
import { updateSponsorTab } from '../../../features/sponsor/sponsorSlice';

const MinistriesTab = () => {
  const dispatch = useDispatch();

  const { sponsorTab } = useSelector((state) => state.sponsor);

  const handleChangeTab = (tab) => {
    dispatch(updateSponsorTab(tab));

    if (tab === 'all') {
      dispatch(GetSponsors());
    } else {
      dispatch(GetMyCreatedSponsors());
    }
  };

  return (
    <MinistriesTabDiv>
      <div className="storiesTab">
        <div className="storiesTab_container">
          <button
            className={`${sponsorTab === 'all' ? 'selected' : ''}`}
            onClick={() => handleChangeTab('all')}
          >
            All Gallery
          </button>
        </div>
        <div className="storiesTab_container">
          <button
            className={`${sponsorTab === 'my' ? 'selected' : ''}`}
            onClick={() => handleChangeTab('my')}
          >
            My Gallery
          </button>
        </div>
      </div>
    </MinistriesTabDiv>
  );
};

export default MinistriesTab;
