import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import VolunteersServices from "./volunteerServices";

export const uploadImageVolunteer = createAsyncThunk(
  "volunteer/uploadimage",
  async (payload) => {
    try {
      let response = await VolunteersServices.VolunteersImageUpload(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const Postvolunteers = createAsyncThunk(
  "volunteer/postVolunteer",
  async (payload) => {
    try {
      let response = await VolunteersServices.postVolunteer(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const EditVolunteers = createAsyncThunk(
  "volunteer/editVolunteers",
  async (payload) => {
    try {
      let response = await VolunteersServices.editAVolunteer(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const EditVolunteersForApproval = createAsyncThunk(
  "volunteer/editVolunteersForApproval",
  async (payload) => {
    try {
      let response = await VolunteersServices.editAVolunteerForApproval(
        payload
      );

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const EditVolunteersAdvanced = createAsyncThunk(
  "volunteer/editVolunteerAdv",
  async (payload) => {
    try {
      let response = await VolunteersServices.editAVolunteer(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetVolunteers = createAsyncThunk(
  "volunteer/getVolunteers",
  async (payload) => {
    try {
      let response = await VolunteersServices.getVolunteer(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetSingleVolunteers = createAsyncThunk(
  "volunteer/getSingleVolunteer",
  async (payload) => {
    try {
      let response = await VolunteersServices.getSingleVolunteer(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);
