import React from "react";
import UserProfilePage from "../../components/DashboardComponent/UserProfile";
import Layout from "../../utils/layout";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { GetSingleMinister } from "../../features/minister/ministerServiceSync";
import { useParams } from "react-router-dom";
import LoaderScreen from "../../utils/loaderScreen";

function SingleMinister() {
  const dispatch = useDispatch();

  const { singleMinisterId } = useParams();

  const { ministerPageLoad } = useSelector((state) => state.minister);

  useEffect(() => {
    dispatch(GetSingleMinister(singleMinisterId));
  }, [singleMinisterId]);

  return (
    <Layout>{ministerPageLoad ? <LoaderScreen /> : <UserProfilePage />}</Layout>
  );
}

export default SingleMinister;
