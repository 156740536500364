import React, { useEffect } from "react";
import Layout from "../../utils/layout";
import { MessageDiv } from "./styles/message.style";
import AllMinisterComponent from "../../components/DashboardComponent/allMinisterComponent";
import { useDispatch, useSelector } from "react-redux";
import { GetMinister } from "../../features/minister/ministerServiceSync";
import MiniLoader from "../../utils/miniLoader";

function AllMinister() {
  const dispatch = useDispatch();

  const { ministerPageLoad } = useSelector((state) => state.minister);

  useEffect(() => {
    dispatch(GetMinister());
  }, []);

  return (
    <Layout>
      <MessageDiv>
        <div className="message_header">
          <h2>All Teams</h2>
        </div>
        {ministerPageLoad ? (
          <MiniLoader />
        ) : (
          <div className="message_chat">
            <AllMinisterComponent />
          </div>
        )}
      </MessageDiv>
    </Layout>
  );
}

export default AllMinister;
