import React, { useEffect } from "react";
import Layout from "../../utils/layout";
import { MessageDiv } from "./styles/message.style";
import SingleUserComponent from "../../components/DashboardComponent/singleUserComponent";
import { useDispatch, useSelector } from "react-redux";
import { GetOtherUserDetail } from "../../features/user/userServiceSync";
import { useParams } from "react-router-dom";
import MiniLoader from "../../utils/miniLoader";

const SingleUser = () => {
  const dispatch = useDispatch();

  const { userId } = useParams();

  const { otherUserLoader } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(GetOtherUserDetail(userId));
  }, []);

  return (
    <Layout>
      <MessageDiv>
        <div className="message_header">
          <h2>View User</h2>
        </div>
        {otherUserLoader ? (
          <MiniLoader />
        ) : (
          <div className="message_chat">
            <SingleUserComponent />
          </div>
        )}
      </MessageDiv>
    </Layout>
  );
};

export default SingleUser;
