import React from "react";
import Layout from "../../utils/layout";
import { MessageDiv } from "./styles/message.style";
import MiniLoader from "../../utils/miniLoader";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { GetSermons } from "../../features/sermon/sermonServicesSync";
import AllSermonsComponents from "../../components/DashboardComponent/allSermonsComponent";

const SermonDisplayPage = () => {
  const dispatch = useDispatch();

  const { sermonPageLoad } = useSelector((state) => state.sermon);

  useEffect(() => {
    dispatch(GetSermons());
  }, []);

  return (
    <Layout>
      <MessageDiv>
        <div className="message_header">
          <h2>All Minister</h2>
          <p>We are Glad to see you again!</p>
        </div>
        {sermonPageLoad ? (
          <MiniLoader />
        ) : (
          <div className="message_chat">
            {" "}
            <AllSermonsComponents />{" "}
          </div>
        )}
      </MessageDiv>
    </Layout>
  );
};

export default SermonDisplayPage;
