import React from 'react';
import { UserMajorDivcomponent, UsersComponentDiv } from './style/user.style';
import UsersSearch from './usersTab';
import UsersTable from './usersTable';
import './style/user.state.css';
import { useNavigate } from 'react-router-dom';

const UsersComponent = () => {
  const navigate = useNavigate();

  return (
    <UserMajorDivcomponent>
      <div className="mainOrMajorComponet">
        <UsersSearch />

        <div className="profile_input_field_button">
          <button
            onClick={() => {
              navigate('/dashboard/create-admin');
            }}
          >
            Create Admins
          </button>
        </div>
      </div>

      <div>
        <UsersTable />
      </div>
    </UserMajorDivcomponent>
  );
};

export default UsersComponent;
