import React from 'react';
import ProfilePic from '../../../Assets/images/avatar-profile-photo.svg';
import { ShowViewDiv } from './styles/showView.style';
import { useSelector } from 'react-redux';
import moment from 'moment';

const ShowFaq = () => {
  const { singleFaqPageDetails } = useSelector((state) => state.faq);

  return (
    <ShowViewDiv>
      <div className="showView_header">
        <div className="showView_profile_details">
          <img src={ProfilePic} />

          <div className="showView_profile_details_name">
            <h4>{singleFaqPageDetails?.fullname}</h4>
            <p>
              {moment(singleFaqPageDetails?.date).format('MMM DD, YYYY hh:mm')}
            </p>
          </div>
        </div>
      </div>
      <div className="showView_Wrapper_title">
        <div className="showView_articleWrapper__titleDetails">
          <h2 className="showView_articleWrapper__titleDetailsItem">
            {singleFaqPageDetails?.question}
          </h2>
        </div>
      </div>
      <div className="showView_articleWrapper__articleDetails">
        <p className="showView_articleWrapper__articleDetailsItem">
          {singleFaqPageDetails?.answer && singleFaqPageDetails?.answer}
        </p>
      </div>
    </ShowViewDiv>
  );
};

export default ShowFaq;
