import { createSlice } from "@reduxjs/toolkit";
import {
  EditSponsor,
  GetMyCreatedSponsors,
  GetSponsors,
  PostSponsor,
  SponsorUploadImage,
} from "./sponsorServicesSync";

const initialState = {
  isError: false,
  isSuccess: false,
  isPicLoading: false,
  isSponsorLoading: false,
  sponsorImageLoader: false,
  contentLoader: false,
  message: "",
  contentDetails: {},
  sponsorPageLoad: false,
  allSponsor: [],
  myCreatedSponsor: [],
  singleSponsorPageDetails: {},
  editSponsorDetails: {},
  sponsorTab: "all",
};

export const sponsorSlice = createSlice({
  name: "sponsor",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isPicLoading = false;
      state.isSuccess = false;
      state.isSponsorLoading = false;
      state.message = "";
      state.contentDetails = {};
      state.sponsorImageLoader = false;
      state.sponsorPageLoad = false;
      state.contentLoader = false;
      state.allSponsor = [];
      state.singleSponsorPageDetails = {};
      state.editSponsorDetails = {};
      state.sponsorTab = "all";
      state.myCreatedSponsor = [];
    },

    checkMessage: (state, action) => {
      state.message = action.payload;
    },

    updateSponsorTab: (state, action) => {
      state.sponsorTab = action.payload;
    },

    updateCoverPics: (state, action) => {
      state.contentDetails = action.payload;
    },

    updateEditSponsorDetails: (state, action) => {
      state.singleSponsorPageDetails = action.payload;
    },
  },
  extraReducers: {
    [SponsorUploadImage.pending]: (state, action) => {
      state.isPicLoading = true;
    },

    [SponsorUploadImage.fulfilled]: (state, action) => {
      state.isPicLoading = false;
      state.message = action.payload?.message;
      state.contentDetails = action.payload?.data;
    },

    [SponsorUploadImage.rejected]: (state, action) => {
      state.isPicLoading = false;
      state.message = action.payload?.message;
    },

    [PostSponsor.pending]: (state, action) => {
      state.isSponsorLoading = true;
    },

    [PostSponsor.fulfilled]: (state, action) => {
      state?.allSponsor?.unshift(action?.payload?.data);

      state.isSponsorLoading = false;
      state.message = action.payload?.message;
    },

    [PostSponsor.rejected]: (state, action) => {
      state.isSponsorLoading = false;
      state.message = action.payload?.message;
    },

    [EditSponsor.pending]: (state, action) => {
      state.isSponsorLoading = true;
    },

    [EditSponsor.fulfilled]: (state, action) => {
      console.log(action.payload?.data, "seasong worlk");

      const findIndex = state?.allSponsor?.findIndex(
        (item) => item?._id === action.payload?.data?._id
      );

      state?.allSponsor?.splice(findIndex, 1, action.payload?.data);

      state.isSponsorLoading = false;
      state.message = action.payload?.message;
    },

    [EditSponsor.rejected]: (state, action) => {
      state.isSponsorLoading = false;
      state.message = action.payload?.message;
    },

    [GetSponsors.pending]: (state, action) => {
      state.contentLoader = true;
    },

    [GetSponsors.fulfilled]: (state, action) => {
      state.contentLoader = false;
      state.message = action.payload?.message;
      state.allSponsor = action?.payload?.data;
    },

    [GetSponsors.rejected]: (state, action) => {
      state.contentLoader = false;
      state.message = action.payload?.message;
    },

    [GetMyCreatedSponsors.pending]: (state, action) => {
      state.contentLoader = true;
    },

    [GetMyCreatedSponsors.fulfilled]: (state, action) => {
      state.contentLoader = false;
      state.message = action.payload?.message;
      state.myCreatedSponsor = action.payload?.data;
    },

    [GetMyCreatedSponsors.rejected]: (state, action) => {
      state.contentLoader = false;
      state.message = action.payload?.message;
    },
  },
});

export const {
  reset,
  checkMessage,
  updateCoverPics,
  updateEditSponsorDetails,
  updateSponsorTab,
} = sponsorSlice.actions;

export default sponsorSlice.reducer;
