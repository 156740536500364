import React, { useEffect, useState } from 'react';
import { CreatePostComponentDiv } from './styles/createpost.style';
import { MdInsertPhoto } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Hearts, LineWave } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import {
  updateCoverPics,
  updateEditSponsorDetails,
} from '../../../features/sponsor/sponsorSlice';
import {
  EditSponsor,
  PostSponsor,
  SponsorUploadImage,
} from '../../../features/sponsor/sponsorServicesSync';
import MinistriesTab from './ministriesTab';
import SponserCard from './SponserCard';
import LoaderScreen from '../../../utils/loaderScreen';

const CreateSponsorComponent = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [images, setImages] = useState();
  const [title, setTitle] = useState();

  const {
    contentDetails,
    isPicLoading,
    isSponsorLoading,
    sponsorImageLoader,
    singleSponsorPageDetails,
    contentLoader,
    allSponsor,
    myCreatedSponsor,
    sponsorTab,
  } = useSelector((state) => state.sponsor);

  useEffect(() => {
    if (Object.keys(singleSponsorPageDetails).length > 0) {
      setTitle(singleSponsorPageDetails?.title);
      setImages(singleSponsorPageDetails?.cover_pic);

      dispatch(
        updateCoverPics({
          secure_url: singleSponsorPageDetails?.cover_pic,
          public_id: singleSponsorPageDetails?.cover_pic_id,
        })
      );
    }
  }, [singleSponsorPageDetails]);

  function getbase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  const handleCoverImage = (e) => {
    const image = e.target.files[0];

    if (image) {
      let promise = getbase64(image);
      promise
        .then((data) => {
          dispatch(SponsorUploadImage({ file: data }));
          return data;
        })
        .then((res) => {
          setImages(image?.name);
        });
    }

    // const reader = new FileReader();
    // reader.readAsDataURL(image);

    // reader.onloadend = () => {
    //   console.log(reader.result);
    //   dispatch(uploadImage({ file: reader.result }));
    // };
  };

  // const handleSelectPdf = async (e) => {
  //   e.preventDefault();

  //   setLoadFile(true);
  //   const files = e.target.files[0];

  //   let formData = new FormData();
  //   formData.append("file", files);

  //   console.log(files, "seasons");

  //   dispatch(uploadImage({ formData }));
  //   setLoadFile(false);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isPicLoading && !sponsorImageLoader) {
      if (!title || Object.keys(contentDetails).length === 0) {
        toast('all field must be filled');
      } else {
        const payload = {
          title: title,
          cover_pic: contentDetails?.secure_url,
          cover_pic_id: contentDetails?.public_id,
        };
        await dispatch(PostSponsor(payload));

        setImages('');
        setTitle('');
        dispatch(updateCoverPics({}));
      }
    } else {
      toast('cover picture is on the way');
    }
  };

  const handleEditBlog = async (e) => {
    e.preventDefault();

    console.log(
      title,
      contentDetails,
      isPicLoading,
      sponsorImageLoader,
      'viewing my mwok'
    );

    if (!isPicLoading && !sponsorImageLoader) {
      if (!title || Object.keys(contentDetails).length <= 0) {
        toast('all field must be filled');
      } else {
        const payload = {
          ...singleSponsorPageDetails,
          title: title,
          cover_pic: contentDetails?.secure_url,
          cover_pic_id: contentDetails?.public_id,
        };

        await dispatch(EditSponsor(payload));

        setImages('');
        setTitle('');
        dispatch(updateCoverPics({}));
      }
    } else {
      toast('cover picture is on the way');
    }
  };

  return (
    <CreatePostComponentDiv>
      {Object.keys(singleSponsorPageDetails).length > 0 && (
        <div className="create__Post__container">
          <button
            className="create__Post"
            onClick={() => {
              dispatch(updateEditSponsorDetails({}));

              setTitle('');
              setImages('');
            }}
          >
            Switch To Create a Sponsor
          </button>
        </div>
      )}

      <div className="createpost_component">
        <div className="createpost_component_header">
          <div className="secondLayout">
            <label>Name of Sponsor</label>
            <textarea
              name="title"
              rows={5}
              value={title || ''}
              onChange={(e) => setTitle(e?.target?.value)}
            ></textarea>
          </div>

          <div className="picture_clone">
            <label>Cover Picture of Sponsor</label>

            <div className="picture_clone_picker">
              <div className="picture_clone_picker_container">
                <div className="picture_clone_picker_container_icon_body">
                  <MdInsertPhoto className="picture_clone_picker_container_icon" />
                </div>

                <input
                  type="file"
                  onChange={(e) => handleCoverImage(e)}
                  className="picture_clone_picker_container_file"
                />
              </div>

              {isPicLoading ? (
                <LineWave
                  height="100"
                  width="100"
                  color="#0716A5"
                  ariaLabel="line-wave"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  firstLineColor=""
                  middleLineColor=""
                  lastLineColor=""
                />
              ) : (
                <p className="picture_clone_picker_content">
                  {images ? images : <>click the icon to select a picture</>}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="create__Post__container create__Post__con">
        {isSponsorLoading ? (
          <Hearts
            height="80"
            width="80"
            color="#0716A5"
            ariaLabel="hearts-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        ) : (
          <>
            {Object.keys(singleSponsorPageDetails).length > 0 ? (
              <button className="create__Post" onClick={handleEditBlog}>
                Edit Sponsor
              </button>
            ) : (
              <button className="create__Post" onClick={handleSubmit}>
                Create Sponsor
              </button>
            )}
          </>
        )}

        {Object.keys(singleSponsorPageDetails).length > 0 && (
          <button
            className="create__Post"
            onClick={() => {
              dispatch(updateEditSponsorDetails({}));

              setTitle('');
              setImages('');
            }}
          >
            Switch To Create a Sponsor
          </button>
        )}
      </div>

      <div className="tabState">
        <MinistriesTab />

        {contentLoader ? (
          <LoaderScreen />
        ) : sponsorTab === 'all' ? (
          <div className="state_wrapper">
            {allSponsor?.map((item, key) => (
              <SponserCard
                key={key}
                image={item?.cover_pic}
                title={item?.title}
                onclick={() => {
                  dispatch(updateEditSponsorDetails(item));

                  dispatch(
                    updateCoverPics({
                      secure_url: item?.cover_pic,
                      public_id: item?.cover_pic_id,
                    })
                  );
                }}
              />
            ))}
          </div>
        ) : (
          <div className="state_wrapper">
            {myCreatedSponsor?.map((item, key) => (
              <SponserCard
                key={key}
                image={item?.cover_pic}
                title={item?.title}
                onclick={() => {
                  dispatch(updateEditSponsorDetails(item));

                  dispatch(
                    updateCoverPics({
                      secure_url: item?.cover_pic,
                      public_id: item?.cover_pic_id,
                    })
                  );
                }}
              />
            ))}
          </div>
        )}
      </div>
    </CreatePostComponentDiv>
  );
};

export default CreateSponsorComponent;
