import React from "react";
import { MinistriesTabDiv, StoriesTabDiv } from "./styles/ministriesTab.styles";
import { useDispatch, useSelector } from "react-redux";
import { updateResourcesTab } from "../../../features/resources/resourcesSlices";
import {
  GetMyResources,
  GetResources,
} from "../../../features/resources/resourcesServicesSync";

const MinistriesTab = () => {
  const dispatch = useDispatch();

  const {
    resourcesTab,
    ordersLimit,
    mylimit,
    resourcesArticleDetails,
    resourcesMyArticleDetails,
  } = useSelector((state) => state.resources);

  const handleChangeTab = (tab) => {
    dispatch(updateResourcesTab(tab));

    if (tab === "all") {
      if (resourcesArticleDetails?.length === 0) {
        dispatch(GetResources(ordersLimit));
      }
    } else {
      if (resourcesMyArticleDetails?.length === 0) {
        dispatch(GetMyResources(mylimit));
      }
    }
  };

  return (
    <MinistriesTabDiv>
      <div className="storiesTab">
        <div className="storiesTab_container">
          <button
            className={`${resourcesTab === "all" ? "selected" : ""}`}
            onClick={() => handleChangeTab("all")}
          >
            All Resources
          </button>
        </div>
        <div className="storiesTab_container">
          <button
            className={`${resourcesTab === "my" ? "selected" : ""}`}
            onClick={() => handleChangeTab("my")}
          >
            My Resources
          </button>
        </div>
      </div>
    </MinistriesTabDiv>
  );
};

export default MinistriesTab;
