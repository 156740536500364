import React from "react";
import StoriesTab from "./storiesTab";
import StoriesCard from "./storiesCard";
import { StoriesComponentDiv } from "./styles/storiescomponent.styles";
import { useDispatch, useSelector } from "react-redux";
import MiniLoader from "../../../utils/miniLoader";
import InfiniteScroll from "react-infinite-scroller";
import { InfinitySpin } from "react-loader-spinner";
import {
  GetBloggerAdvanced,
  GetMyBloggerAdvanced,
} from "../../../features/bloggers/bloggersServicesSync";

const StoriesComponent = () => {
  const dispatch = useDispatch();

  const {
    articleDetails,
    myArticleDetails,
    storiesPageLoad,
    orderPagination,
    myPagination,
    ordersLimit,
    mylimit,
    myBloggersCount,
    ordersBloggersCount,
    storyTab,
    averageLimit,
    singleBlogDetails,
  } = useSelector((state) => state.blogger);

  console.log(singleBlogDetails, "missing you bro");

  return (
    <StoriesComponentDiv>
      <div>
        <StoriesTab />
      </div>

      {!storiesPageLoad ? (
        <>
          {storyTab === "all" ? (
            <InfiniteScroll
              pageStart={orderPagination}
              loadMore={async () => {
                if (ordersBloggersCount > articleDetails.length) {
                  dispatch(GetBloggerAdvanced(ordersLimit + averageLimit));
                }
              }}
              hasMore={true || false}
              loader={
                ordersBloggersCount > articleDetails.length && (
                  <InfinitySpin width="200" color="#007bff" />
                )
              }
            >
              <div className="stories_card">
                <StoriesCard cardArry={articleDetails} />
              </div>
            </InfiniteScroll>
          ) : (
            <InfiniteScroll
              pageStart={myPagination}
              loadMore={async () => {
                if (myBloggersCount > myArticleDetails.length) {
                  dispatch(GetMyBloggerAdvanced(mylimit + averageLimit));
                }
              }}
              hasMore={true || false}
              loader={
                myBloggersCount > myArticleDetails.length && (
                  <InfinitySpin width="200" color="#007bff" />
                )
              }
            >
              <div className="stories_card">
                <StoriesCard cardArry={myArticleDetails} />
              </div>
            </InfiniteScroll>
          )}
        </>
      ) : (
        <MiniLoader />
      )}
    </StoriesComponentDiv>
  );
};

export default StoriesComponent;
