import React from "react";
import CserProfileCompontent from "./userProfileCompontent";

const TestimonyProfilePage = () => {
  return (
    <div>
      <CserProfileCompontent />
    </div>
  );
};

export default TestimonyProfilePage;
