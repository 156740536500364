import { configureStore } from '@reduxjs/toolkit';
import utitilitiesReducer from '../features/utilities/utilitiesSlices';
import authReducer from '../features/auth/authSlice';
import userReducer from '../features/user/userSlices';
import BloggerReducer from '../features/bloggers/bloggersSlices';
import SocialReducer from '../features/social/socialSlice';
import MinistriesReducer from '../features/ministries/ministriesSlices';
import MinisterReducer from '../features/minister/ministerSlices';
import SermonReducer from '../features/sermon/sermonSlices';
import ResourcesReducer from '../features/resources/resourcesSlices';
import ServicesReducer from '../features/services/servicesSlices';
import VolunteerReducer from '../features/volunteer/volunteerSlices';
import CharityReducer from '../features/charity/charitySlices';
import TestimonyReducer from '../features/testimony/testimonySlices';
import SponsorReducer from '../features/sponsor/sponsorSlice';
import GalleryReducer from '../features/gallery/gallerySlice';
import PaymentReducer from '../features/payment/paymentSlice';
import faqReducer from '../features/faq/faqSlices';

export const store = configureStore({
  reducer: {
    utitilities: utitilitiesReducer,
    auth: authReducer,
    user: userReducer,
    blogger: BloggerReducer,
    social: SocialReducer,
    ministries: MinistriesReducer,
    minister: MinisterReducer,
    sermon: SermonReducer,
    resources: ResourcesReducer,
    services: ServicesReducer,
    volunteer: VolunteerReducer,
    charity: CharityReducer,
    testimony: TestimonyReducer,
    sponsor: SponsorReducer,
    gallery: GalleryReducer,
    payment: PaymentReducer,
    faq: faqReducer,
  },
});
