import React, { useEffect } from "react";
import Layout from "../../utils/layout";
import { CreatePostDiv } from "./styles/message.style";
import CreateSponsorComponent from "../../components/DashboardComponent/createSponsorComponent";
import { useDispatch, useSelector } from "react-redux";
import { GetSponsors } from "../../features/sponsor/sponsorServicesSync";
import { GetGallery } from "../../features/gallery/galleryServicesSync";
import CreateGallerComponent from "../../components/DashboardComponent/createGalleryComponent";

const CreateGallery = () => {
  const dispatch = useDispatch();

  const { gallerylimit, galleryPage } = useSelector((state) => state.gallery);

  useEffect(() => {
    dispatch(
      GetGallery({
        page: galleryPage,
        limit: gallerylimit,
      })
    );
  }, []);

  return (
    <Layout>
      <CreatePostDiv>
        <div className="createpost_header">
          <h2>Create a Gallery</h2>
        </div>
        <div className="createpost_content">
          <CreateGallerComponent />
        </div>
      </CreatePostDiv>
    </Layout>
  );
};

export default CreateGallery;
