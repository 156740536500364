import { styled } from 'styled-components';

export const SermonTabDiv = styled.div`
  display: flex;
  justify-content: flex-start;

  .storiesTab {
    display: flex;
    align-items: center;
    border: 1px solid #0716a6;
    padding: 1px;
    justify-content: center;
    background-color: #ffffff;
    border-radius: 8px;

    .storiesTab_container {
      button {
        outline: none;
        border: none;
        font-size: 14px;
        font-weight: 500;
        background-color: #ffffff;
        padding: 7px 20px;
        border-radius: 8px;
        color: #101828;
        cursor: pointer;
        transition: all 0.5s ease-in;
      }

      .selected {
        background-color: #0716a6;
        color: #ffffff;
      }
    }
  }
`;
