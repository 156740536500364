import React, { useRef, useState } from 'react';
import { TopBarDiv } from './styles/topbar.style';
import { MdOutlineNotificationsActive } from 'react-icons/md';
import Profile from '../../../Assets/images/avatar-profile-photo.svg';
import { FiMenu } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { ResetNavBar } from '../../../features/utilities/utilitiesSlices';
import { NavLink, useNavigate } from 'react-router-dom';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const TopNavBar = () => {
  const closeRef = useRef();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [displayShow, setDisplayShow] = useState(false);

  const { userDetails } = useSelector((state) => state.user);

  useOnClickOutside(closeRef, () => setDisplayShow(false));

  return (
    <TopBarDiv>
      <div className="top_nav">
        <div className="top_nav_bar">
          <div
            className="top_nav_bar_icon"
            onClick={() => dispatch(ResetNavBar(true))}
          >
            <FiMenu className="top_nav_bar_icon_body" />
          </div>
        </div>
        <div className="top_nav_second_half">
          <div className="top_nav_second_notification">
            <div className="notification_body">
              <MdOutlineNotificationsActive className="notification_icon" />
            </div>

            <div className="notify" />
          </div>
          <div className="image_wrapper">
            <div
              className="image_wrapper_image"
              onClick={() => setDisplayShow(true)}
            >
              <img src={Profile} className="image" />
            </div>

            {displayShow && (
              <div className="profile_dropdown" ref={closeRef}>
                <div className="profile_dropdown_header">
                  <div className="profile_dropdown_header_profile">
                    <img src={Profile} />
                  </div>
                  <div className="profile_dropdown_header_content">
                    {userDetails?.fullname ? (
                      <p>{userDetails?.fullname}</p>
                    ) : (
                      <SkeletonTheme
                        baseColor="#2A29296E"
                        highlightColor="#444"
                      >
                        <p>
                          <Skeleton width="8rem" height="1.5rem" />
                        </p>
                      </SkeletonTheme>
                    )}

                    {userDetails?.email ? (
                      <p>{userDetails?.email}</p>
                    ) : (
                      <SkeletonTheme
                        baseColor="#2A29296E"
                        highlightColor="#444"
                      >
                        <p>
                          <Skeleton width="8rem" height="1.5rem" />
                        </p>
                      </SkeletonTheme>
                    )}
                  </div>
                </div>
                <div className="profile_dropdown_body">
                  <div className="profile_dropdown_body_content">
                    <NavLink
                      to={'/dashboard/profile'}
                      onClick={() => setDisplayShow(false)}
                    >
                      <p>View Profile</p>
                    </NavLink>
                  </div>

                  <div className="profile_dropdown_body_content">
                    <NavLink
                      to={'/dashboard/create_post'}
                      onClick={() => setDisplayShow(false)}
                    >
                      <p>Create Stories</p>
                    </NavLink>
                  </div>

                  <div
                    onClick={async () => {
                      await localStorage.clear();

                      setTimeout(() => {
                        navigate('/');
                      }, 1000);
                    }}
                    className="profile_dropdown_body_content"
                  >
                    <p>Logout</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </TopBarDiv>
  );
};

export default TopNavBar;
