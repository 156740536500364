import React from 'react';
import StoriesTab from './ministriesTab';
import StoriesCard from './ministriesCard';
import { MinistriesComponentDiv } from './styles/ministriescomponent.styles';
import { useDispatch, useSelector } from 'react-redux';
import MiniLoader from '../../../utils/miniLoader';
import InfiniteScroll from 'react-infinite-scroller';
import { InfinitySpin } from 'react-loader-spinner';
import {
  GetMyResourcesAdvanced,
  GetResourcesAdvanced,
} from '../../../features/resources/resourcesServicesSync';

const ResourcesComponent = () => {
  const dispatch = useDispatch();

  const {
    resourcesArticleDetails,
    resourcesMyArticleDetails,
    resourcesPageLoad,
    orderPagination,
    myPagination,
    ordersLimit,
    mylimit,
    myResourcesCount,
    ordersResourcesCount,
    resourcesTab,
    averageLimit,
  } = useSelector((state) => state.resources);

  return (
    <MinistriesComponentDiv>
      <div>
        <StoriesTab />
      </div>

      {!resourcesPageLoad ? (
        <>
          {resourcesTab === 'all' ? (
            <InfiniteScroll
              pageStart={orderPagination}
              loadMore={async () => {
                if (ordersResourcesCount > resourcesArticleDetails.length) {
                  dispatch(GetResourcesAdvanced(ordersLimit + averageLimit));
                }
              }}
              hasMore={true || false}
              loader={
                ordersResourcesCount > resourcesArticleDetails.length && (
                  <InfinitySpin width="200" color="#0716A6" />
                )
              }
            >
              <div className="stories_card">
                <StoriesCard cardArry={resourcesArticleDetails} />
              </div>
            </InfiniteScroll>
          ) : (
            <InfiniteScroll
              pageStart={myPagination}
              loadMore={async () => {
                if (myResourcesCount > resourcesMyArticleDetails.length) {
                  dispatch(GetMyResourcesAdvanced(mylimit + averageLimit));
                }
              }}
              hasMore={true || false}
              loader={
                myResourcesCount > resourcesMyArticleDetails.length && (
                  <InfinitySpin width="200" color="#0716A6" />
                )
              }
            >
              <div className="stories_card">
                <StoriesCard cardArry={resourcesMyArticleDetails} />
              </div>
            </InfiniteScroll>
          )}
        </>
      ) : (
        <MiniLoader />
      )}
    </MinistriesComponentDiv>
  );
};

export default ResourcesComponent;
