import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import SponsorServices from "./sponsorServices";

export const SponsorUploadImage = createAsyncThunk(
  "sponsor/uploadimage",
  async (payload) => {
    try {
      let response = await SponsorServices.SponsorImageUpload(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const PostSponsor = createAsyncThunk(
  "sponsor/PostSponsor",
  async (payload) => {
    try {
      let response = await SponsorServices.postSponsor(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const EditSponsor = createAsyncThunk(
  "sponsor/editSponsor",
  async (payload) => {
    try {
      let response = await SponsorServices.editASponsor(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetSponsors = createAsyncThunk(
  "sponsor/getSponsors",
  async (payload) => {
    try {
      let response = await SponsorServices.getSponsor(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetMyCreatedSponsors = createAsyncThunk(
  "sponsor/getCreatedSponsors",
  async (payload) => {
    try {
      let response = await SponsorServices.getMyCreatedSponsor(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);
