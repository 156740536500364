import React from "react";
import { UserContactDiv } from "./styles/userContact.style";
import { NavLink } from "react-router-dom";

function UserProfileSocialMedia({ facebook, instagram, linkedin, twitter }) {
  return (
    <UserContactDiv>
      <div className="userAdminContactWrapper__SectionOne"></div>
      <div className="userAdminContactWrapper__BodyWrapper">
        <div className="userAdminContactWrapperHeader">
          <p>Social Media Information</p>
        </div>
        <div className="userAdminContactWrapper__Body">
          <div className="userAdminContactWrapper__BodyContainer">
            <div className="userAdminContactWrapper__BodyContainerTop">
              <p className="userAdminContactWrapper__BodyContainerTopOne">
                Face Book
              </p>

              <NavLink to={`${facebook}`}>
                <p className="userAdminContactWrapper__BodyContainerTopTwo">
                  {facebook}
                </p>
              </NavLink>
            </div>
            <div className="userAdminContactWrapper__BodyContainerBottom">
              <p className="userAdminContactWrapper__BodyContainerBottomOne">
                Instagram
              </p>

              <NavLink to={`${instagram}`}>
                <p className="userAdminContactWrapper__BodyContainerBottomTwo">
                  {instagram}
                </p>
              </NavLink>
            </div>

            <div className="userAdminContactWrapper__BodyContainerBottom">
              <p className="userAdminContactWrapper__BodyContainerBottomOne">
                LinkedIn
              </p>

              <NavLink to={`${linkedin}`}>
                <p className="userAdminContactWrapper__BodyContainerBottomTwo">
                  {linkedin}
                </p>
              </NavLink>
            </div>

            <div className="userAdminContactWrapper__BodyContainerBottom">
              <p className="userAdminContactWrapper__BodyContainerBottomOne">
                Twitter
              </p>

              <NavLink to={`${linkedin}`}>
                <p className="userAdminContactWrapper__BodyContainerBottomTwo">
                  {twitter}
                </p>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </UserContactDiv>
  );
}

export default UserProfileSocialMedia;
