import React, { useState } from 'react';
import { InfinitySpin } from 'react-loader-spinner';
import { ProfileInformationDiv } from './styles/profileInformation.styled';
import ProfileAvatar from '../../../Assets/images/avatar-profile-photo.png';
import { FiUploadCloud } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  EditMinisterAdvanced,
  PostMinister,
  uploadImageMinister,
} from '../../../features/minister/ministerServiceSync';
import { ThreeLoaderAppColor } from '../../common/threeLoader';
import { useEffect } from 'react';
import {
  updateCoverPics,
  updateEditMinisterDetails,
} from '../../../features/minister/ministerSlices';
import { useNavigate } from 'react-router-dom';

const baseSchema = Yup.object({
  firstname: Yup.string().required(),
  lastname: Yup.string().required(),
  email: Yup.string().email().required(),
  country: Yup.string().required(),
  state: Yup.string().required(),
  address: Yup.string().required(),
  about: Yup.string().required(),
  office: Yup.string().required(),
  phone_number: Yup.string().required(),
  gender: Yup.string().required(),
  instagram: Yup.string(),
  twitter: Yup.string(),
  linkedln: Yup.string(),
  facebook: Yup.string(),
});

const MinistersComponents = () => {
  const dispatch = useDispatch();

  const navigation = useNavigate();

  const { contentDetails, isBlogLoading, isPicLoading, editMinistersDetails } =
    useSelector((state) => state.minister);

  const [images, setImages] = useState();

  const handleSubmited = async (value, { resetForm }) => {
    // e.preventDefault();

    if (
      value?.gender?.toLowerCase() !== 'male' ||
      value?.gender?.toLowerCase() !== 'female'
    ) {
      if (Object.keys(contentDetails).length > 1) {
        if (Object.keys(editMinistersDetails).length > 0) {
          const payload = {
            ...editMinistersDetails,
            ...value,
            gender: value?.gender?.toLowerCase(),
            cover_pic: contentDetails?.secure_url,
            cover_pic_id: contentDetails?.public_id,
          };

          await dispatch(EditMinisterAdvanced(payload));
        } else {
          const payload = {
            ...value,
            gender: value?.gender?.toLowerCase(),
            cover_pic: contentDetails?.secure_url,
            cover_pic_id: contentDetails?.public_id,
          };

          await dispatch(PostMinister(payload));
        }

        navigation('/dashboard/all_mininster');

        resetForm();
      } else {
        toast('cover picture needed');
      }
    } else {
      toast('gender should be male or female');
    }
  };

  const {
    values,
    field,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    touched,
    errors,
    resetForm,
  } = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      country: '',
      state: '',
      address: '',
      about: '',
      office: '',
      phone_number: '',
      gender: '',
      instagram: '',
      twitter: '',
      linkedln: '',
      facebook: '',
    },
    validationSchema: baseSchema,
    onSubmit: handleSubmited,
  });

  useEffect(() => {
    if (Object.keys(editMinistersDetails).length > 0) {
      setFieldValue('firstname', editMinistersDetails?.firstname);
      setFieldValue('lastname', editMinistersDetails?.lastname);
      setFieldValue('email', editMinistersDetails?.email);
      setFieldValue('country', editMinistersDetails?.country);
      setFieldValue('address', editMinistersDetails?.address);
      setFieldValue('state', editMinistersDetails?.state);
      setFieldValue('about', editMinistersDetails?.about);
      setFieldValue('office', editMinistersDetails?.office);
      setFieldValue('phone_number', editMinistersDetails?.phone_number);
      setFieldValue('gender', editMinistersDetails?.gender);
      setFieldValue('instagram', editMinistersDetails?.instagram);
      setFieldValue('twitter', editMinistersDetails?.twitter);
      setFieldValue('linkedln', editMinistersDetails?.linkedln);
      setFieldValue('facebook', editMinistersDetails?.facebook);
    }
  }, [editMinistersDetails]);

  const handleSubmitDetails = (e) => {
    e.preventDefault();

    handleSubmit();
  };

  function getbase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  const handleCoverImage = (e) => {
    const image = e.target.files[0];

    if (image) {
      let promise = getbase64(image);
      promise
        .then((data) => {
          dispatch(uploadImageMinister({ file: data }));
          return data;
        })
        .then((res) => {
          // const linv = cre
          setImages(res);
        });
    }

    // const reader = new FileReader();
    // reader.readAsDataURL(image);

    // reader.onloadend = () => {
    //   console.log(reader.result);
    //   dispatch(uploadImage({ file: reader.result }));
    // };
  };

  return (
    <ProfileInformationDiv>
      <div className="profile_input_field">
        <div className="profile_input_field_input">
          <div className="profile_input_field_input_image">
            <div className="profile_input_field_input_image_avatar">
              <img
                src={
                  Object.keys(contentDetails).length > 0
                    ? contentDetails?.secure_url
                    : images
                    ? images
                    : ProfileAvatar
                }
                alt="see"
                className="profile_input_field_input_image_avartar_card"
              />

              <div className="profile_input_field_input_image_avatar_upload_card">
                <div className="profile_input_field_input_image_avatar_upload_card_icon_body">
                  <FiUploadCloud className="profile_input_field_input_image_avatar_upload_card_icon" />
                </div>

                {isPicLoading ? <ThreeLoaderAppColor /> : <p>Upload Photo</p>}

                <input
                  type="file"
                  className="fileUpload"
                  onChange={(e) => handleCoverImage(e)}
                />
              </div>
            </div>
          </div>

          <div className="profile_input_field_input_fields">
            <div className="firstLayout">
              <div className="firstLayout_input_wrapper">
                <label>First Name</label>
                <input
                  name="firstname"
                  value={values?.firstname || ''}
                  onChange={handleChange}
                />
              </div>

              <div className="firstLayout_input_wrapper">
                <label>Last Name</label>
                <input
                  name="lastname"
                  value={values?.lastname || ''}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="firstLayout">
              <div className="firstLayout_input_wrapper">
                <label>Email</label>
                <input
                  name="email"
                  value={values.email || ''}
                  onChange={handleChange}
                />
              </div>

              <div className="firstLayout_input_wrapper">
                <label>office</label>
                <input
                  name="office"
                  value={values.office || ''}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="firstLayout">
              <div className="firstLayout_input_wrapper">
                <label>Phone Number</label>
                <input
                  name="phone_number"
                  value={values.phone_number || ''}
                  onChange={handleChange}
                />
              </div>

              <div className="firstLayout_input_wrapper">
                <label>Gender</label>
                <input
                  name="gender"
                  value={values?.gender || ''}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="firstLayout">
              <div className="firstLayout_input_wrapper">
                <label>Country</label>
                <input
                  name="country"
                  value={values?.country || ''}
                  onChange={handleChange}
                />
              </div>

              <div className="firstLayout_input_wrapper">
                <label>State</label>
                <input
                  name="state"
                  value={values?.state || ''}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="firstLayout">
              <div className="firstLayout_input_wrapper">
                <label>Facebook</label>
                <input
                  name="facebook"
                  value={values?.facebook || ''}
                  onChange={handleChange}
                />
              </div>

              <div className="firstLayout_input_wrapper">
                <label>Instagram</label>
                <input
                  name="instagram"
                  value={values?.instagram || ''}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="firstLayout">
              <div className="firstLayout_input_wrapper">
                <label>Twitter</label>
                <input
                  name="twitter"
                  value={values?.twitter || ''}
                  onChange={handleChange}
                />
              </div>

              <div className="firstLayout_input_wrapper">
                <label>LinkedIn</label>
                <input
                  name="linkedln"
                  value={values?.linkedln || ''}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="secondLayout">
              <label>Address</label>
              <input
                name="address"
                value={values?.address || ''}
                onChange={handleChange}
              />
            </div>

            <div className="secondLayout">
              <label>About</label>
              <textarea
                name="about"
                value={values?.about || ''}
                onChange={handleChange}
                rows={5}
              ></textarea>
            </div>
          </div>
        </div>
        <div className="profile_input_field_button">
          {Object.keys(editMinistersDetails).length > 0 && (
            <button
              onClick={() => {
                dispatch(updateCoverPics({}));

                dispatch(updateEditMinisterDetails({}));

                resetForm();
              }}
            >
              Switch to Create Team
            </button>
          )}

          {isBlogLoading ? (
            <InfinitySpin width="150" color="#0716A5" />
          ) : (
            <button onClick={handleSubmitDetails}>
              {Object.keys(editMinistersDetails).length > 0 ? (
                <>Edit Team</>
              ) : (
                <>Create Team</>
              )}
            </button>
          )}
        </div>
      </div>
    </ProfileInformationDiv>
  );
};

export default MinistersComponents;
