import { styled } from 'styled-components';

export const UserMajorDivcomponent = styled.div`
  width: 100%;

  .mainOrMajorComponet {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .profile_input_field_button {
      display: flex;
      justify-content: flex-end;
      gap: 20px;

      button {
        border: none;
        outline: none;
        background-color: #0716a6;
        padding: 4px 49px;
        color: white;
        font-size: 16px;
        /* font-family: Roboto; */
        font-weight: 500;
        line-height: 30px;
        border-radius: 20px;
        cursor: pointer;
      }
    }
  }
`;

export const UsersComponentDiv = styled.div`
  margin: 30px 0px;
  padding: 15px 0px;
  width: 100%;

  .track {
    width: 100%;
    .track_header {
      margin-bottom: 10px;
      h3 {
        margin: 0;
        font-size: 18px;
        font-weight: 600;
        color: #101828;
      }
    }

    .track_table {
      width: 100%;

      .track_table_headers {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #d0d5ddf8;
        width: 100%;

        .track_table_headers_State {
          padding: 8px 8px;
          width: 12%;
          text-align: left;

          &.SN {
            width: 4%;

            @media screen and (max-width: 715px) {
              width: 30%;
            }

            @media screen and (max-width: 676px) {
              width: 27%;
            }

            @media screen and (max-width: 450px) {
              width: 40%;
            }
          }

          &.course {
            width: 18%;

            @media screen and (max-width: 715px) {
              width: 30%;
            }

            @media screen and (max-width: 676px) {
              width: 27%;
            }

            @media screen and (max-width: 450px) {
              width: 40%;
            }
          }

          &.Name {
            width: 15%;

            @media screen and (max-width: 715px) {
              width: 30%;
            }

            @media screen and (max-width: 676px) {
              width: 27%;
            }

            @media screen and (max-width: 450px) {
              width: 40%;
            }
          }

          &.Description {
            width: 12%;

            @media screen and (max-width: 715px) {
              width: 30%;
            }

            @media screen and (max-width: 676px) {
              width: 27%;
            }

            @media screen and (max-width: 450px) {
              width: 40%;
            }
          }

          &.Prerequisite {
            width: 20%;

            @media screen and (max-width: 715px) {
              width: 30%;
            }

            @media screen and (max-width: 676px) {
              width: 27%;
            }

            @media screen and (max-width: 450px) {
              width: 40%;
            }
          }

          &.Time_status {
            width: 18%;

            @media screen and (max-width: 715px) {
              width: 30%;
            }

            @media screen and (max-width: 676px) {
              width: 27%;
            }

            @media screen and (max-width: 450px) {
              width: 40%;
            }
          }

          &.status {
            width: 15%;

            @media screen and (max-width: 715px) {
              width: 30%;
            }

            @media screen and (max-width: 676px) {
              width: 27%;
            }

            @media screen and (max-width: 450px) {
              width: 40%;
            }
          }

          p {
            margin: 0;
            font-size: 16px;
            font-weight: 500;
            color: #101828;
          }

          &.Level {
            width: 18%;
            text-align: center;

            @media screen and (max-width: 715px) {
              width: 30%;
              text-align: left;
            }

            @media screen and (max-width: 676px) {
              width: 27%;
            }

            @media screen and (max-width: 450px) {
              width: 40%;
            }
          }

          @media screen and (max-width: 715px) {
            width: 30%;
            margin-bottom: 15px;
          }

          @media screen and (max-width: 676px) {
            width: 27%;
          }

          @media screen and (max-width: 450px) {
            width: 40%;
          }
        }

        @media screen and (max-width: 1410px) {
          overflow-x: scroll;
          width: 130%;
        }

        @media screen and (max-width: 1150px) {
          width: 150%;
        }

        @media screen and (max-width: 715px) {
          flex-wrap: wrap;
          overflow-x: scroll;
          width: 100%;
        }
      }

      .track_table_body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #d0d5ddf8;
        margin: 10px 0px;
        width: 100%;

        .track_table_body_State {
          padding: 8px 8px;
          width: 12%;
          text-align: left;

          &.S_SN {
            width: 4%;

            @media screen and (max-width: 715px) {
              width: 30%;
            }

            @media screen and (max-width: 676px) {
              width: 27%;
            }

            @media screen and (max-width: 450px) {
              width: 40%;
            }
          }

          &.S_Course {
            width: 18%;

            @media screen and (max-width: 715px) {
              width: 30%;
            }

            @media screen and (max-width: 676px) {
              width: 27%;
            }

            @media screen and (max-width: 450px) {
              width: 40%;
            }
          }

          &.S_Name {
            width: 15%;

            @media screen and (max-width: 715px) {
              width: 30%;
            }

            @media screen and (max-width: 676px) {
              width: 27%;
            }

            @media screen and (max-width: 450px) {
              width: 40%;
            }
          }

          &.S_Description {
            width: 12%;

            @media screen and (max-width: 715px) {
              width: 30%;
            }

            @media screen and (max-width: 676px) {
              width: 27%;
            }

            @media screen and (max-width: 450px) {
              width: 40%;
            }
          }

          &.S_Prerequisite {
            width: 20%;

            @media screen and (max-width: 715px) {
              width: 30%;
            }

            @media screen and (max-width: 676px) {
              width: 27%;
            }

            @media screen and (max-width: 450px) {
              width: 40%;
            }
          }

          &.S_Time_status {
            width: 18%;

            @media screen and (max-width: 715px) {
              width: 30%;
            }

            @media screen and (max-width: 676px) {
              width: 27%;
            }

            @media screen and (max-width: 450px) {
              width: 40%;
            }
          }

          &.S_Status {
            width: 15%;
            @media screen and (max-width: 715px) {
              width: 30%;
            }

            @media screen and (max-width: 676px) {
              width: 27%;
            }

            @media screen and (max-width: 450px) {
              width: 40%;
            }
          }

          p {
            margin: 0;
            font-size: 14px;
            font-weight: 400;
            color: #101828;
          }

          &.S_Level {
            width: 18%;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 15px;

            @media screen and (max-width: 715px) {
              width: 30%;
              text-align: left;
              justify-content: flex-start;
            }

            @media screen and (max-width: 676px) {
              width: 27%;
            }

            @media screen and (max-width: 450px) {
              width: 40%;
            }
          }

          .track_table_body_State_S_Level_Selection {
            font-size: 20px;
            color: #34d07f;
          }

          .track_table_body_State_S_Level {
            font-size: 20px;
            color: #d0d5dd;
          }

          button {
            outline: none;
            border: none;
            color: #2fbd83;
            font-size: 16px;
            font-weight: 500;
            cursor: pointer;
            background-color: #ecfdf3;
            padding: 8px 18px;
          }

          .S_Level_Delete {
            background-color: #fdf0ec;
            color: #bd2f2f;
          }

          @media screen and (max-width: 715px) {
            width: 30%;
            margin-bottom: 15px;
          }

          @media screen and (max-width: 676px) {
            width: 27%;
          }

          @media screen and (max-width: 450px) {
            width: 40%;
          }
        }

        @media screen and (max-width: 1410px) {
          overflow-x: scroll;
          width: 130%;
        }

        @media screen and (max-width: 1150px) {
          width: 150%;
        }

        @media screen and (max-width: 715px) {
          flex-wrap: wrap;
          width: 100%;
          overflow-x: unset;
          border: 1px solid #d0d5ddf8;
        }
      }

      @media screen and (max-width: 1495px) {
        overflow-x: scroll;
        width: 100%;
      }
    }
  }
`;

export const UsersSearchDiv = styled.div``;
