import React, { memo } from "react";
import UserProfileContact from "./userProfileContact";
import UserProfileHeader from "./userProfileHeader";
import UserProfileMoreInformation from "./userProfileMoreInformation";
import { UserProfileMainDiv } from "./styles/userProfileMain.style";
import { useSelector } from "react-redux";
import UserProfileSocialMedia from "./userProfileSocialMedia";
import BlockUser from "./blockUser";
import { useState } from "react";
import { Dialog } from "primereact/dialog";
import ApproveUser from "./approveUser";

const CserProfileCompontent = () => {
  const { singleVolunteerPageDetails } = useSelector(
    (state) => state.volunteer
  );

  const [openBlock, setOpenBlock] = useState(false);

  const [openApprove, setpenApprove] = useState(false);

  return (
    <>
      <Dialog
        // header="Header"
        visible={openBlock}
        style={{ borderRadius: "20px" }}
        onHide={() => setOpenBlock(false)}
        modal={true}
        closeIcon={() => {
          return;
        }}
      >
        <BlockUser
          setOpen={setOpenBlock}
          payload={singleVolunteerPageDetails}
        />
      </Dialog>

      <Dialog
        // header="Header"
        visible={openApprove}
        style={{ borderRadius: "20px" }}
        onHide={() => setpenApprove(false)}
        modal={true}
        closeIcon={() => {
          return;
        }}
      >
        <ApproveUser
          setOpen={setpenApprove}
          payload={singleVolunteerPageDetails}
        />
      </Dialog>
      <UserProfileMainDiv>
        <div className="userAdminWrapperHeader">
          <UserProfileHeader
            firstName={singleVolunteerPageDetails?.firstname}
            lastName={singleVolunteerPageDetails?.lastname}
            jobTitle={singleVolunteerPageDetails?.office}
            cover_pic={singleVolunteerPageDetails?.cover_pic}
            status={singleVolunteerPageDetails?.block}
            statusApprovered={singleVolunteerPageDetails?.Approve}
            setOpen={setOpenBlock}
            setpenApprove={setpenApprove}
          />
        </div>

        <div className="userAdminWrapperBody">
          <div className="userAdminWrapperBodyTwo">
            <UserProfileContact
              email={singleVolunteerPageDetails?.email}
              phone={singleVolunteerPageDetails?.phone_number}
              address={singleVolunteerPageDetails?.address}
            />
          </div>

          <div className="userAdminWrapperBodyOne">
            <UserProfileMoreInformation
              country={singleVolunteerPageDetails?.country}
              state={singleVolunteerPageDetails?.state}
              gender={singleVolunteerPageDetails?.gender}
              about={singleVolunteerPageDetails?.about}
            />
          </div>

          <div className="userAdminWrapperBodyOne">
            <UserProfileSocialMedia
              facebook={singleVolunteerPageDetails?.facebook}
              instagram={singleVolunteerPageDetails?.instagram}
              linkedin={singleVolunteerPageDetails?.linkedln}
              twitter={singleVolunteerPageDetails?.twitter}
            />
          </div>
        </div>
      </UserProfileMainDiv>
    </>
  );
};

export default memo(CserProfileCompontent);
