import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import PaymentServices from './paymentServices';

export const AllTransaction = createAsyncThunk(
  'payment/paymentTransaction',
  async (payload) => {
    try {
      let response = await PaymentServices.getAllTransaction(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const AllTransactionStatus = createAsyncThunk(
  'payment/paymentStatus',
  async (payload) => {
    try {
      let response = await PaymentServices.getAllTransactionStatus(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);
