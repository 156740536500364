import React, { memo } from "react";
import { UserProfileMoreInformationDiv } from "./styles/userProfileMoreInformation.style";

const UserProfileMoreInformation = ({ country, state, gender, about }) => {
  return (
    <UserProfileMoreInformationDiv>
      <div className="userProfileMoreInformationWrapper"></div>
      <div className="userProfileMoreInformationWrapper__Details">
        <div className="userProfileMoreInformationWrapper__DetailsHeader">
          <p>More Information</p>
        </div>
        <div className="userProfileMoreInformationWrapper__DetailsBody">
          <div className="userProfileMoreInformationWrapper__DetailsBodyContainer">
            <div className="userProfileMoreInformationWrapper__DetailsBodyTop">
              <p className="userProfileMoreInformationWrapper__DetailsBodyTopOne">
                Country
              </p>
              <p className="userProfileMoreInformationWrapper__DetailsBodyTopTwo">
                {country}
              </p>
            </div>
            <div className="userProfileMoreInformationWrapper__DetailsBodyBottom">
              <p className="userProfileMoreInformationWrapper__DetailsBodyBottomOne">
                state
              </p>
              <p className="userProfileMoreInformationWrapper__DetailsBodyBottomTwo">
                {state}
              </p>
            </div>

            <div className="userProfileMoreInformationWrapper__DetailsBodyBottom">
              <p className="userProfileMoreInformationWrapper__DetailsBodyBottomOne">
                state
              </p>
              <p className="userProfileMoreInformationWrapper__DetailsBodyBottomTwo">
                {state}
              </p>
            </div>

            <div className="userProfileMoreInformationWrapper__DetailsBodyBottom">
              <p className="userProfileMoreInformationWrapper__DetailsBodyBottomOne">
                gender
              </p>
              <p className="userProfileMoreInformationWrapper__DetailsBodyBottomTwo">
                {gender}
              </p>
            </div>

            <div className="userProfileMoreInformationWrapper__DetailsBodyBottom">
              <p className="userProfileMoreInformationWrapper__DetailsBodyBottomOne">
                About me
              </p>
              <p className="userProfileMoreInformationWrapper__DetailsBodyBottomTwo">
                {about}
              </p>
            </div>
          </div>
        </div>
      </div>
    </UserProfileMoreInformationDiv>
  );
};

export default memo(UserProfileMoreInformation);
