import React, { useEffect } from "react";
import { ProfileDiv } from "./styles/message.style";
import Layout from "../../utils/layout";
import ProfileComponent from "../../components/DashboardComponent/ProfileComponents";
import { useDispatch, useSelector } from "react-redux";
import { GetSocial } from "../../features/social/socialServicesSync";
import MiniLoader from "../../utils/miniLoader";

const SocialMedia = () => {
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.social);

  useEffect(() => {
    dispatch(GetSocial());
  }, []);

  return (
    <Layout>
      <ProfileDiv>
        <div className="profile_header">
          <h2>Social Medias</h2>
        </div>

        {isLoading ? (
          <MiniLoader />
        ) : (
          <div className="profile_content">
            <ProfileComponent />
          </div>
        )}
      </ProfileDiv>
    </Layout>
  );
};

export default SocialMedia;
