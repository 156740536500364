import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import MinisterServices from "./ministerServices";

export const uploadImageMinister = createAsyncThunk(
  "minister/uploadimage",
  async (payload) => {
    try {
      let response = await MinisterServices.MinisterImageUpload(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const PostMinister = createAsyncThunk(
  "minister/postMinister",
  async (payload) => {
    try {
      let response = await MinisterServices.postMinister(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const EditMinister = createAsyncThunk(
  "minister/editMinister",
  async (payload) => {
    try {
      let response = await MinisterServices.editAMinister(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const EditMinisterAdvanced = createAsyncThunk(
  "minister/editMinisterAdv",
  async (payload) => {
    try {
      let response = await MinisterServices.editAMinister(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetMinister = createAsyncThunk(
  "minister/getMinister",
  async (payload) => {
    try {
      let response = await MinisterServices.getMinister(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetSingleMinister = createAsyncThunk(
  "minister/getSingleMinister",
  async (payload) => {
    try {
      let response = await MinisterServices.getSingleMinister(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);
