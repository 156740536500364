import React, { useState } from 'react';
import { BlockUserDiv } from './style/blockUser.style';
import Warning from '../../../Assets/images/warning.svg';
import ThreeLoader from '../../common/threeLoader';
import { useDispatch, useSelector } from 'react-redux';
import {
  DeleteAdmin,
  EditUserFromAdmin,
} from '../../../features/user/userServiceSync';
import { updateUsersDetails } from '../../../features/user/userSlices';

const BlockUser = ({ setOpen, payload }) => {
  const dispatch = useDispatch();

  const { editLoader, completeUserDetails } = useSelector(
    (state) => state.user
  );

  const handleEditUser = async () => {
    await dispatch(DeleteAdmin(payload));

    const filterData = completeUserDetails?.docs?.filter(
      (item) => item._id !== payload?._id
    );

    dispatch(updateUsersDetails({ ...completeUserDetails, docs: filterData }));
  };

  return (
    <BlockUserDiv className="_createTradebuymodal_wrapper">
      {/* {false ? (
        <Loader style={{ height: "150px" }} />
      ) : ( */}
      <>
        <div className="_warning_holder">
          <img src={Warning} alt="warning" width="45px" />
        </div>

        <div className="check__wrapper" style={{ textAlign: 'center' }}></div>
        <div className="check__wrapper __dis" style={{ alignItems: 'center' }}>
          <p className="_areyou_sure">
            Are you sure you want to {!payload.block ? 'block' : 'unblock'} this
            user?
          </p>
        </div>

        <div className="_modalbutton_wrapper">
          <button
            className="__cancel"
            onClick={() => setOpen(false)}
            disabled={editLoader ? true : false}
          >
            Cancel
          </button>
          <button
            className="__confirm accept"
            onClick={handleEditUser}
            disabled={editLoader ? true : false}
          >
            <>{!editLoader ? <p>Delete</p> : <ThreeLoader />}</>
          </button>
        </div>
      </>
      {/* )} */}
    </BlockUserDiv>
  );
};

export default BlockUser;
