import React, { useEffect } from 'react';
import Layout from '../../utils/layout';
import { DashboardDiv } from './styles/message.style';
import DashboardCard from '../../components/DashboardComponent/DashboardIndex/dashboardCard';
import Track from '../../components/DashboardComponent/DashboardIndex/track';
import RecentTask from '../../components/DashboardComponent/DashboardIndex/recentTask';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AllTestimonyComponent from '../../components/DashboardComponent/allTestimonyComponent';
import AllVolunteersComponent from '../../components/DashboardComponent/allVolunteerComponent';

const Dashboard = () => {
  const dispatch = useDispatch();

  return (
    <Layout>
      <DashboardDiv>
        <div className="dashboard_header">
          <h2>{`Welcome Back, JOshua ejike!`}</h2>
          <p>We are Glad to see you again!</p>
        </div>

        <div className="dashbaord_content">
          <DashboardCard />

          <div>
            <h2>All Volunteers</h2>
            <AllVolunteersComponent />
          </div>

          <div>
            <h2>All Testimonys</h2>

            <AllTestimonyComponent />
          </div>
        </div>
      </DashboardDiv>
    </Layout>
  );
};

export default Dashboard;
