import React from "react";
import Layout from "../../utils/layout";
import { CreatePostDiv } from "./styles/message.style";
import CreateProjectComponent from "../../components/DashboardComponent/createMinistryComponent";

const CreateProject = () => {
  return (
    <Layout>
      <CreatePostDiv>
        <div className="createpost_header">
          <h2>Create a Project</h2>
          <p>We are Glad to see you again!</p>
        </div>
        <div className="createpost_content">
          <CreateProjectComponent />
        </div>
      </CreatePostDiv>
    </Layout>
  );
};

export default CreateProject;
