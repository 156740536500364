import { toast } from "react-toastify";
import APIs from "../apiUrl";

const createSocial = async (payload) => {
  try {
    const response = await APIs.post("/create_social", payload);

    if (response?.data?.message === "success") {
      toast("create successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getSocial = async () => {
  try {
    const response = await APIs.get("/get_socials_details");

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const SocialSevices = { createSocial, getSocial };

export default SocialSevices;
