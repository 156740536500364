import React from 'react';
import { ProfileComponentDiv } from './styles/profileComponent.style';
import AdminProfileContent from './profileSocialMedia';

const AdminProfileComponent = () => {
  return (
    <ProfileComponentDiv>
      <div>
        <AdminProfileContent />
      </div>
    </ProfileComponentDiv>
  );
};

export default AdminProfileComponent;
