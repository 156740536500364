import React, { useEffect } from "react";
import Layout from "../../utils/layout";
import { StoriesDiv } from "./styles/message.style";
import { useDispatch, useSelector } from "react-redux";
import { reset } from "../../features/services/servicesSlices";
import { GetServices } from "../../features/services/servicesServicesSync";
import ServicesComponent from "../../components/DashboardComponent/servicesComponent";

const Services = () => {
  const dispatch = useDispatch();

  const { ordersLimit } = useSelector((state) => state.services);

  useEffect(() => {
    dispatch(GetServices(ordersLimit));

    return () => {
      dispatch(reset());
    };
  }, []);

  return (
    <Layout>
      <StoriesDiv>
        <div className="stories_header">
          <h2>Services Page</h2>
        </div>

        <div className="stories_content">
          <ServicesComponent />
        </div>
      </StoriesDiv>
    </Layout>
  );
};

export default Services;
