import React from "react";
import Layout from "../../utils/layout";
import { CreatePostDiv } from "./styles/message.style";
import CreateSermonComponent from "../../components/DashboardComponent/createSermon";

function CreateSermon() {
  return (
    <Layout>
      <CreatePostDiv>
        <div className="createpost_header">
          <h2>Create Sermon</h2>
        </div>

        <div className="createpost_content">
          <CreateSermonComponent />
        </div>
      </CreatePostDiv>
    </Layout>
  );
}

export default CreateSermon;
