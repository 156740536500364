import { styled } from "styled-components";

export const TopBarDiv = styled.div`
  /* width: calc(100% - 18%); */
  margin-left: calc(100% - 82%);
  background-color: #fff;
  box-shadow: 0px 0px 8px 4px #0000001e;
  padding: 12px 0px;

  .top_nav {
    width: 90%;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .top_nav_bar {
      .top_nav_bar_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        display: none;

        .top_nav_bar_icon_body {
          font-size: 24px;
          color: #000;
        }

        @media screen and (max-width: 920px) {
          display: flex;
        }
      }
    }

    .top_nav_second_half {
      display: flex;
      align-items: center;
      gap: 30px;

      .top_nav_second_notification {
        position: relative;

        .notification_body {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          .notification_icon {
            font-size: 24px;
            color: #000;
          }
        }

        .notify {
          position: absolute;
          top: 0;
          right: 0;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #f14437;
        }
      }

      .image_wrapper {
        position: relative;

        .image_wrapper_image {
          width: 42px;
          height: 42px;
          cursor: pointer;

          .image {
            height: 100%;
            width: 100%;
            border-radius: 50%;
          }
        }

        .profile_dropdown {
          position: absolute;
          right: 0;
          background-color: #fff;
          z-index: 33;
          width: 300px;
          padding: 10px 0px;
          box-shadow: 0px 5px 8px 4px #00000013;
          transition: all 0.2s ease-in;

          .profile_dropdown_header {
            display: flex;
            align-items: center;
            gap: 12px;
            padding: 15px 12px;

            .profile_dropdown_header_profile {
              width: 42px;
              height: 42px;
              cursor: pointer;

              img {
                height: 100%;
                width: 100%;
                border-radius: 50%;
              }
            }

            .profile_dropdown_header_content {
              p {
                margin: 0;
                color: #101828;
                font-size: 18px;
                /* font-family: Roboto; */
                font-weight: 400;
                line-height: 30px;
              }
            }
          }

          .profile_dropdown_body {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .profile_dropdown_body_content {
              padding: 6px 12px;
              cursor: pointer;
              a {
                text-decoration: none;
              }

              p {
                margin: 0;
                color: #101828;
                font-size: 18px;
                /* font-family: Roboto; */
                font-weight: 400;
                line-height: 30px;
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: 720px) {
      width: 95%;
    }
  }

  @media screen and (max-width: 1486px) {
    margin-left: calc(100% - 78%);
  }

  @media screen and (max-width: 1164px) {
    margin-left: calc(100% - 75%);
  }

  @media screen and (max-width: 1036px) {
    margin-left: calc(100% - 72%);
  }

  @media screen and (max-width: 920px) {
    margin-left: 0;
  }
`;
