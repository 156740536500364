import { toast } from "react-toastify";
import APIs from "../apiUrl";

const VolunteersImageUpload = async (payload) => {
  try {
    const response = await APIs.post("/uploadfiles", payload);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const postVolunteer = async (payload) => {
  try {
    const response = await APIs.post("/create_volunteer", payload);

    if (response?.data?.message === "success") {
      toast("Posted successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const editAVolunteer = async (payload) => {
  try {
    const response = await APIs.patch("/edit_volunteer", payload);

    if (response?.data?.message === "success") {
      toast("Posted successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const editAVolunteerForApproval = async (payload) => {
  try {
    const response = await APIs.patch("/edit_volunteer_for_Approval", payload);

    if (response?.data?.message === "success") {
      toast("Posted successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getVolunteer = async () => {
  try {
    const response = await APIs.get(`/all_volunteer`);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getSingleVolunteer = async (payload) => {
  try {
    const response = await APIs.get(`/get_single_volunteer/${payload}`);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const VolunteersServices = {
  VolunteersImageUpload,
  postVolunteer,
  editAVolunteer,
  getVolunteer,
  getSingleVolunteer,
  editAVolunteerForApproval,
};

export default VolunteersServices;
