import React, { useEffect } from 'react';
import TaskComponent from '../../components/DashboardComponent/Task.js';
import Layout from '../../utils/layout.js';
import { TaskDiv } from './styles/message.style.js';
import { useDispatch, useSelector } from 'react-redux';
import { reset } from '../../features/payment/paymentSlice.js';
import { AllTransaction } from '../../features/payment/paymentServicesSync.js';
import { useNavigate } from 'react-router-dom';

const Task = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { page, mylimit } = useSelector((state) => state.payment);

  const { userDetails } = useSelector((state) => state.user);

  useEffect(() => {
    if (userDetails?.admintype === 'normal_level') {
      navigate('/dashboard');
    }

    dispatch(AllTransaction({ page: page, limit: mylimit }));

    return () => {
      dispatch(reset());
    };
  }, []);

  return (
    <Layout>
      <TaskDiv>
        <div className="task_header">
          <h2>Task</h2>
          <p>We are Glad to see you again!</p>
        </div>
        <div className="task_content">
          <TaskComponent />
        </div>
      </TaskDiv>
    </Layout>
  );
};

export default Task;
