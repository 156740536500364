import React from 'react';
import { SideBarDiv } from './styles/sidebar.style';
import mainImage from '../../../Assets/images/avatar-profile-photo.svg';
import { FiBarChart2, FiEdit3, FiHome } from 'react-icons/fi';
import { BsFillChatQuoteFill } from 'react-icons/bs';
import {
  FaBloggerB,
  FaBookOpen,
  FaBookReader,
  FaComments,
  FaCreditCard,
  FaFileContract,
  FaHandHoldingHeart,
  FaHandsHelping,
  FaImages,
  FaPeopleCarry,
  FaQuestionCircle,
  FaTasks,
  FaTools,
  FaUsers,
} from 'react-icons/fa';
import {
  FaHandshakeAngle,
  FaUsersBetweenLines,
  FaUsersViewfinder,
} from 'react-icons/fa6';
import {
  MdBuildCircle,
  MdChatBubble,
  MdCreateNewFolder,
  MdGroupAdd,
  MdLibraryBooks,
  MdOutlineClose,
} from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { ResetNavBar } from '../../../features/utilities/utilitiesSlices';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const SideBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { showNavBar } = useSelector((state) => state.utitilities);

  const { userDetails } = useSelector((state) => state.user);

  return (
    <SideBarDiv content={showNavBar}>
      <div className="sidebarConatiner">
        <div className="header">
          <NavLink>
            <h2>ICTHARAE</h2>
          </NavLink>

          <div
            className="header_body"
            onClick={() => dispatch(ResetNavBar(false))}
          >
            <MdOutlineClose className="header_icon" />
          </div>
        </div>

        <div className="sidebar_body">
          {SideBarArray?.map((item, key) => (
            <>
              {((userDetails?.admintype === 'normal_level' &&
                item?.page !== 'Paystack' &&
                item?.page !== 'Users') ||
                (userDetails?.admintype === 'mid_level' &&
                  item?.page !== 'Users') ||
                userDetails?.admintype === 'top_level') && (
                <div
                  key={key}
                  className={`sidebar_body_content ${
                    location?.pathname === item?.path ? 'Selected' : ''
                  }`}
                  onClick={() => navigate(`${item?.path}`)}
                >
                  <div className="sidebar_body_icon_body">
                    <item.icon
                      fontSize={25}
                      color={'#FAFAFA'}
                      stroke="#FFFFFF"
                      aria-hidden
                      className="sidebar_body_icon"
                    />
                  </div>
                  <p className="sidebar_body_text">{item?.page}</p>
                </div>
              )}
            </>
          ))}
        </div>

        <div className="bottom">
          <img src={mainImage} alt="profile" />
          <div className="subbottom">
            {userDetails?.fullname ? (
              <h3>{userDetails?.fullname}</h3>
            ) : (
              <SkeletonTheme baseColor="#2A29296E" highlightColor="#444">
                <p>
                  <Skeleton width="8rem" height="1.5rem" />
                </p>
              </SkeletonTheme>
            )}

            {userDetails?.email ? (
              <p>{userDetails?.email}</p>
            ) : (
              <SkeletonTheme baseColor="#2A29296E" highlightColor="#444">
                <p>
                  <Skeleton width="8rem" height="1.5rem" />
                </p>
              </SkeletonTheme>
            )}
          </div>
        </div>
      </div>
    </SideBarDiv>
  );
};

const SideBarArray = [
  {
    icon: FiHome,
    page: 'Dashboard',
    path: '/dashboard',
    index: 0,
  },
  {
    icon: FiEdit3,
    page: 'Create Blog',
    path: '/dashboard/create_post',
    index: 1,
  },
  {
    icon: MdCreateNewFolder,
    page: 'Create Project',
    path: '/dashboard/create_project',
    index: 2,
  },

  {
    icon: FaBookReader,
    page: 'Create Resources',
    path: '/dashboard/create_resources',
    index: 3,
  },

  {
    icon: FaTools,
    page: 'Create Services',
    path: '/dashboard/create_services',
    index: 4,
  },

  {
    icon: FaHandHoldingHeart,
    page: 'Create Donation',
    path: '/dashboard/create_charity',
    index: 5,
  },

  {
    icon: FaComments,
    page: 'Social Media Handle',
    path: '/dashboard/socialmedia',
    index: 6,
  },

  {
    icon: FaHandshakeAngle,
    page: 'Create Sponsor',
    path: '/dashboard/create_sponsor',
    index: 7,
  },

  {
    icon: FaImages,
    page: 'Create Gallery',
    path: '/dashboard/create_gallery',
    index: 8,
  },

  {
    icon: FaUsers,
    page: 'Create Team',
    path: '/dashboard/create_team',
    index: 9,
  },

  {
    icon: MdGroupAdd,
    page: 'Create Volunteer',
    path: '/dashboard/create_volunteers',
    index: 10,
  },

  {
    icon: FaUsersBetweenLines,
    page: 'Users',
    path: '/dashboard/user_collection',
    index: 11,
  },

  // {
  //   icon: FaFileContract,
  //   page: 'Create Assessment',
  //   path: '/dashboard/create_assessment',
  //   index: 12,
  // },

  {
    icon: FaCreditCard,
    page: 'Paystack',
    path: '/dashboard/paystack',
    index: 13,
  },

  {
    icon: FaQuestionCircle,
    page: 'FAQ',
    path: '/dashboard/faq',
    index: 13,
  },

  {
    icon: FaQuestionCircle,
    page: 'View FAQs',
    path: '/dashboard/view_faqs',
    index: 13,
  },

  {
    icon: FaBookOpen,
    page: 'View Blogs',
    path: '/dashboard/stories',
    index: 14,
  },

  {
    icon: FaTasks,
    page: 'Projects',
    path: '/dashboard/project',
    index: 15,
  },

  {
    icon: MdLibraryBooks,
    page: 'Resources',
    path: '/dashboard/resources',
    index: 16,
  },

  {
    icon: MdBuildCircle,
    page: 'Services',
    path: '/dashboard/services',
    index: 17,
  },

  {
    icon: FaHandsHelping,
    page: 'Donations',
    path: '/dashboard/charity',
    index: 18,
  },

  {
    icon: FaUsersViewfinder,
    page: 'Teams',
    path: '/dashboard/all_mininster',
    index: 19,
  },

  {
    icon: FaPeopleCarry,
    page: 'Volunteers',
    path: '/dashboard/all_volunteers',
    index: 20,
  },

  {
    icon: MdChatBubble,
    page: 'Testimony',
    path: '/dashboard/all_testimony',
    index: 21,
  },

  // {
  //   icon: FaFileContract,
  //   page: 'All Sermon',
  //   path: '/dashboard/sermon',
  //   index: 22,
  // },
];

export default SideBar;
