import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import CharityServices from "./charityServices";

export const uploadImageCharity = createAsyncThunk(
  "charity/uploadimage",
  async (payload) => {
    try {
      let response = await CharityServices.CharityImageUpload(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const PostCharity = createAsyncThunk(
  "charity/postCharity",
  async (payload) => {
    try {
      let response = await CharityServices.postCharity(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const EditCharity = createAsyncThunk(
  "charity/editCharity",
  async (payload) => {
    try {
      let response = await CharityServices.editACharity(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetCharity = createAsyncThunk(
  "charity/getMinistries",
  async (payload) => {
    try {
      let response = await CharityServices.getCharity(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetCharityAdvanced = createAsyncThunk(
  "charity/getAdvCharity",
  async (payload) => {
    try {
      let response = await CharityServices.getCharity(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetMyCharity = createAsyncThunk(
  "charity/getmyCharity",
  async (payload) => {
    try {
      let response = await CharityServices.getMyCharity(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetMyCharityAdvanced = createAsyncThunk(
  "charity/getmyAdvCharity",
  async (payload) => {
    try {
      let response = await CharityServices.getMyCharity(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetSingleCharity = createAsyncThunk(
  "charity/getSingleCharity",
  async (payload) => {
    try {
      let response = await CharityServices.getSingleCharity(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);
