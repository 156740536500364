import { createSlice } from '@reduxjs/toolkit';
import {
  EditFaq,
  GetFaq,
  GetFaqAdvanced,
  GetMyFaq,
  GetMyFaqAdvanced,
  GetSingleFaq,
  PostFaq,
} from './faqServicesSyn';

const initialState = {
  isError: false,
  isSuccess: false,
  isPicLoading: false,
  isFaqLoading: false,
  message: '',
  faqDetails: {},
  faqArticleDetails: [],
  faqMyArticleDetails: [],
  averageLimit: 10,
  mylimit: 10,
  myPagination: 0,
  ordersLimit: 10,
  orderPagination: 0,
  myFaqCount: 0,
  ordersFaqCount: 0,
  faqPageLoad: false,
  faqTab: 'all',
  singleFaqPageLoader: false,
  singleFaqPageDetails: {},
};

export const faqSlice = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isPicLoading = false;
      state.isSuccess = false;
      state.isFaqLoading = false;
      state.message = '';
      state.faqDetails = {};
      state.faqArticleDetails = [];
      state.faqMyArticleDetails = [];
      state.faqPageLoad = false;
      state.mylimit = 10;
      state.averageLimit = 10;
      state.myPagination = 0;
      state.ordersLimit = 10;
      state.orderPagination = 0;
      state.myFaqCount = 0;
      state.ordersFaqCount = 0;
      state.singleFaqPageLoader = false;
      state.singleFaqPageDetails = {};
      state.faqTab = 'all';
    },

    checkMessage: (state, action) => {
      state.message = action.payload;
    },

    updateFaqPageLoader: (state, action) => {
      state.faqPageLoad = action.payload;
    },

    updateOrderFaqCount: (state, action) => {
      state.ordersFaqCount = action.payload;
    },

    updataesingleFaqPageLoader: (state, action) => {
      state.singleFaqPageLoader = action.payload;
    },

    updataesingleFaqPageDetails: (state, action) => {
      state.singleFaqPageDetails = action.payload;
    },

    updateFaqTab: (state, action) => {
      state.faqTab = action.payload;
    },
  },
  extraReducers: {
    [PostFaq.pending]: (state, action) => {
      state.isFaqLoading = true;
    },

    [PostFaq.fulfilled]: (state, action) => {
      state.isFaqLoading = false;
      state.message = action.payload?.message;
    },

    [PostFaq.rejected]: (state, action) => {
      state.isFaqLoading = false;
      state.message = action.payload?.message;
    },

    [EditFaq.pending]: (state, action) => {
      state.isFaqLoading = true;
    },

    [EditFaq.fulfilled]: (state, action) => {
      state.isFaqLoading = false;
      state.message = action.payload?.message;
    },

    [EditFaq.rejected]: (state, action) => {
      state.isFaqLoading = false;
      state.message = action.payload?.message;
    },

    [GetFaq.pending]: (state, action) => {
      state.faqPageLoad = true;
    },

    [GetFaq.fulfilled]: (state, action) => {
      state.faqPageLoad = false;
      state.message = action.payload?.message;
      state.faqArticleDetails = action?.payload?.data;
      state.ordersFaqCount = action?.payload?.count;
    },

    [GetFaq.rejected]: (state, action) => {
      state.faqPageLoad = false;
      state.message = action.payload?.message;
    },

    [GetFaqAdvanced.pending]: (state, action) => {
      state.faqPageLoad = false;
    },

    [GetFaqAdvanced.fulfilled]: (state, action) => {
      state.faqPageLoad = false;
      state.message = action.payload?.message;
      state.orderPagination = state.orderPagination + 1;
      state.ordersLimit = state.ordersLimit + state.averageLimit;
      state.faqArticleDetails = [...action?.payload?.data];
      state.ordersFaqCount = action?.payload?.count;
    },

    [GetFaqAdvanced.rejected]: (state, action) => {
      state.faqPageLoad = false;
      state.message = action.payload?.message;
    },

    [GetMyFaq.pending]: (state, action) => {
      state.faqPageLoad = true;
    },

    [GetMyFaq.fulfilled]: (state, action) => {
      state.faqPageLoad = false;
      state.message = action.payload?.message;
      state.faqMyArticleDetails = action?.payload?.data;
      state.myFaqCount = action?.payload?.count;
    },

    [GetMyFaq.rejected]: (state, action) => {
      state.faqPageLoad = false;
      state.message = action.payload?.message;
    },

    [GetMyFaqAdvanced.pending]: (state, action) => {
      state.faqPageLoad = false;
    },

    [GetMyFaqAdvanced.fulfilled]: (state, action) => {
      state.faqPageLoad = false;
      state.message = action.payload?.message;
      state.myPagination = state.myPagination + 1;
      state.mylimit = state.mylimit + state.averageLimit;
      state.faqMyArticleDetails = [...action?.payload?.data];
      state.myFaqCount = action?.payload?.count;
    },

    [GetMyFaqAdvanced.rejected]: (state, action) => {
      state.faqPageLoad = false;
      state.message = action.payload?.message;
    },

    [GetSingleFaq.pending]: (state, action) => {
      state.singleFaqPageLoader = true;
    },

    [GetSingleFaq.fulfilled]: (state, action) => {
      state.singleFaqPageLoader = false;
      state.message = action.payload?.message;
      state.singleFaqPageDetails = action.payload?.data;
    },

    [GetSingleFaq.rejected]: (state, action) => {
      state.singleFaqPageLoader = false;
      state.message = action.payload?.message;
    },
  },
});

export const {
  reset,
  checkMessage,
  updateFaqPageLoader,
  updateOrderFaqCount,
  updataesingleFaqPageLoader,
  updateFaqTab,
  updataesingleFaqPageDetails,
} = faqSlice.actions;

export default faqSlice.reducer;
