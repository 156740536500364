import { styled } from 'styled-components';

export const LoginStyleDiv = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 60px 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  .login {
    display: flex;
    align-items: center;
    width: 50%;
    border-radius: 8px;
    height: 70vh;
    box-shadow: 0px 0px 8px 4px #00000014;

    .login_content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 40px;

      .login_content_header_container {
        width: 70%;
        display: flex;
        flex-direction: column;
        gap: 15px;
        align-items: center;
        justify-content: center;

        .login_content_icon {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 4px solid black;
          width: 80px;
          height: 80px;
          border-radius: 50%;

          .login_content_icon_details {
            font-size: 60px;
          }
        }

        .login_content_title_things {
          display: flex;
          flex-direction: column;
          gap: 10px;
          text-align: center;

          .login_text_header {
            font-size: 2rem;
            font-weight: 600;
            margin: 0px;
          }

          .login_text_paragraph {
            font-size: 1rem;
            margin: 0px;
            font-weight: 400;
          }
        }

        @media screen and (max-width: 500px) {
          width: 95%;
        }
      }

      .login_content_header_container_state {
        width: 70%;

        display: flex;
        flex-direction: column;
        gap: 30px;

        .login_content_header_container_input {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 10px;

          .login_content_header_container_email {
            width: 100%;

            .is-danger {
              margin: 0;
              color: 'red';
              font-size: 12px;
              /* font-family: Roboto; */
              font-weight: 300;
              line-height: 30px;
            }

            .login_content_header_container_email_input_field {
              display: flex;
              align-items: center;
              justify-content: space-between;
              border: 1px solid #c5c5c5;
              border-radius: 8px;
              padding: 8px 14px;

              &.error {
                border: 1px solid red;
              }

              label {
                font-size: 15px;
                font-weight: 500;
                color: #000;
              }

              .login_content_header_container_email_input {
                flex-grow: 1;
                outline: none;
                border: none;
                font-size: 14px;
                font-weight: 400;
                color: #000;

                box-sizing: border-box;

                /* &:focus {
                  border: 1px solid #007bff;
                } */

                &::placeholder {
                  color: #0c0c0c31;
                  font-size: 14px;
                }
              }

              .login_content_header_container_email_input_icon_body {
                display: flex;
                align-items: center;
                justify-content: center;

                .login_content_header_container_email_input_icon {
                  font-size: 24px;
                  color: #c5c5c5;
                }
              }
            }
          }

          .login_content_header_container_password {
            width: 100%;

            label {
              font-size: 15px;
              font-weight: 500;
              color: #000;
            }

            .is-danger {
              margin: 0;
              color: 'red';
              font-size: 12px;
              /* font-family: Roboto; */
              font-weight: 300;
              line-height: 30px;
            }

            .login_content_header_container_password_input_field {
              display: flex;
              align-items: center;
              justify-content: space-between;
              border: 1px solid #c5c5c5;
              border-radius: 8px;
              padding: 8px 14px;

              &.error {
                border: 1px solid red;
              }

              .login_content_header_container_password_input {
                flex-grow: 1;
                outline: none;
                border: none;
                font-size: 14px;
                font-weight: 400;
                color: #000;
                box-sizing: border-box;

                &::placeholder {
                  color: #0c0c0c31;
                  font-size: 14px;
                }
              }

              .login_content_header_container_password_input_icon_body {
                display: flex;
                align-items: center;
                justify-content: center;

                .login_content_header_container_password_input_icon {
                  font-size: 24px;
                  color: #c5c5c5;
                }
              }
            }
          }
        }

        .login_content_header_container_state_button {
          width: 100%;

          button {
            width: 100%;
            border: none;
            outline: none;
            background-color: #0716a6;
            padding: 6px 49px;
            color: white;
            font-size: 14px;
            /* font-family: Roboto; */
            font-weight: 400;
            line-height: 30px;
            border-radius: 4px;
            cursor: pointer;
          }
        }

        @media screen and (max-width: 500px) {
          width: 95%;
        }
      }

      @media screen and (max-width: 740px) {
        width: 100%;
      }
    }

    @media screen and (max-width: 1500px) {
      height: 85vh;
    }

    @media screen and (max-width: 740px) {
      width: 95%;
      flex-direction: column;
      height: unset;
      gap: 50px;
    }
  }
`;
