import React, { memo } from "react";
import { UserContactDiv } from "./styles/userContact.style";

const UserProfileContact = ({ email, phone, address }) => {
  return (
    <UserContactDiv>
      <div className="userAdminContactWrapper__SectionOne"></div>
      <div className="userAdminContactWrapper__BodyWrapper">
        <div className="userAdminContactWrapperHeader">
          <p>Contact Information</p>
        </div>
        <div className="userAdminContactWrapper__Body">
          <div className="userAdminContactWrapper__BodyContainer">
            <div className="userAdminContactWrapper__BodyContainerTop">
              <p className="userAdminContactWrapper__BodyContainerTopOne">
                Email Address
              </p>
              <p className="userAdminContactWrapper__BodyContainerTopTwo">
                {email}
              </p>
            </div>
            <div className="userAdminContactWrapper__BodyContainerBottom">
              <p className="userAdminContactWrapper__BodyContainerBottomOne">
                Phone Number
              </p>
              <p className="userAdminContactWrapper__BodyContainerBottomTwo">
                {phone}
              </p>
            </div>

            <div className="userAdminContactWrapper__BodyContainerBottom">
              <p className="userAdminContactWrapper__BodyContainerBottomOne">
                Address
              </p>
              <p className="userAdminContactWrapper__BodyContainerBottomTwo">
                {address}
              </p>
            </div>
          </div>
        </div>
      </div>
    </UserContactDiv>
  );
};

export default memo(UserProfileContact);
