import { createSlice } from '@reduxjs/toolkit';
import {
  EditGallery,
  GalleryUploadImage,
  GetGallery,
  GetGalleryAdv,
  GetMyCreatedGallery,
  GetMyCreatedGalleryAdv,
  PostGallery,
} from './galleryServicesSync';

const initialState = {
  isError: false,
  isSuccess: false,
  isPicLoading: false,
  isGalleryLoading: false,
  galleryImageLoader: false,
  contentLoader: false,
  message: '',
  contentDetails: {},
  galleryPageLoad: false,
  allGallery: {},
  myCreatedGallery: {},
  singleGalleryPageDetails: {},
  editGalleryDetails: {},
  paginationLoader: false,
  galleryTab: 'all',
  gallerylimit: 9,
  galleryPage: 1,
  galleryMylimit: 9,
  galleryMyPage: 1,
};

export const gallerySlice = createSlice({
  name: 'gallery',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isPicLoading = false;
      state.isSuccess = false;
      state.isGalleryLoading = false;
      state.message = '';
      state.contentDetails = {};
      state.galleryImageLoader = false;
      state.galleryPageLoad = false;
      state.contentLoader = false;
      state.allGallery = {};
      state.singleGalleryPageDetails = {};
      state.editGalleryDetails = {};
      state.galleryTab = 'all';
      state.myCreatedGallery = {};
      state.gallerylimit = 9;
      state.galleryPage = 1;
      state.galleryMylimit = 9;
      state.galleryMyPage = 1;
      state.paginationLoader = false;
    },

    checkMessage: (state, action) => {
      state.message = action.payload;
    },

    updateGalleryTab: (state, action) => {
      state.galleryTab = action.payload;
    },

    updateCoverPics: (state, action) => {
      state.contentDetails = action.payload;
    },

    updateEditGalleryDetails: (state, action) => {
      state.singleGalleryPageDetails = action.payload;
    },

    updateGalleryPage: (state, action) => {
      state.galleryPage = action.payload;
    },

    updateMyGalleryPage: (state, action) => {
      state.galleryMyPage = action.payload;
    },
  },
  extraReducers: {
    [GalleryUploadImage.pending]: (state, action) => {
      state.isPicLoading = true;
    },

    [GalleryUploadImage.fulfilled]: (state, action) => {
      state.isPicLoading = false;
      state.message = action.payload?.message;
      state.contentDetails = action.payload?.data;
    },

    [GalleryUploadImage.rejected]: (state, action) => {
      state.isPicLoading = false;
      state.message = action.payload?.message;
    },

    [PostGallery.pending]: (state, action) => {
      state.isGalleryLoading = true;
    },

    [PostGallery.fulfilled]: (state, action) => {
      state?.allGallery?.docs?.unshift(action?.payload?.data);

      state.isGalleryLoading = false;
      state.message = action.payload?.message;
    },

    [PostGallery.rejected]: (state, action) => {
      state.isGalleryLoading = false;
      state.message = action.payload?.message;
    },

    [EditGallery.pending]: (state, action) => {
      state.isGalleryLoading = true;
    },

    [EditGallery.fulfilled]: (state, action) => {
      const findIndex = state?.allGallery?.findIndex(
        (item) => item?._id === action.payload?.data?._id
      );

      state?.allGallery?.splice(findIndex, 1, action.payload?.data);

      state.isGalleryLoading = false;
      state.message = action.payload?.message;
    },

    [EditGallery.rejected]: (state, action) => {
      state.isGalleryLoading = false;
      state.message = action.payload?.message;
    },

    [GetGallery.pending]: (state, action) => {
      state.contentLoader = true;
    },

    [GetGallery.fulfilled]: (state, action) => {
      state.contentLoader = false;
      state.message = action.payload?.message;
      state.allGallery = action?.payload?.data;
    },

    [GetGallery.rejected]: (state, action) => {
      state.contentLoader = false;
      state.message = action.payload?.message;
    },

    [GetMyCreatedGallery.pending]: (state, action) => {
      state.contentLoader = true;
    },

    [GetMyCreatedGallery.fulfilled]: (state, action) => {
      state.contentLoader = false;
      state.message = action.payload?.message;
      state.myCreatedGallery = action.payload?.data;
    },

    [GetMyCreatedGallery.rejected]: (state, action) => {
      state.contentLoader = false;
      state.message = action.payload?.message;
    },

    [GetGalleryAdv.pending]: (state, action) => {
      state.paginationLoader = true;
    },

    [GetGalleryAdv.fulfilled]: (state, action) => {
      state.paginationLoader = false;
      state.message = action.payload?.message;
      state.allGallery = action?.payload?.data;
    },

    [GetGalleryAdv.rejected]: (state, action) => {
      state.paginationLoader = false;
      state.message = action.payload?.message;
    },

    [GetMyCreatedGalleryAdv.pending]: (state, action) => {
      state.paginationLoader = true;
    },

    [GetMyCreatedGalleryAdv.fulfilled]: (state, action) => {
      state.paginationLoader = false;
      state.message = action.payload?.message;
      state.myCreatedGallery = action.payload?.data;
    },

    [GetMyCreatedGalleryAdv.rejected]: (state, action) => {
      state.paginationLoader = false;
      state.message = action.payload?.message;
    },
  },
});

export const {
  reset,
  checkMessage,
  updateCoverPics,
  updateEditGalleryDetails,
  updategalleryTab,
  updateGalleryPage,
  updateMyGalleryPage,
} = gallerySlice.actions;

export default gallerySlice.reducer;
