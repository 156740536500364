import styled from 'styled-components';

export const ShowViewDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .showView_header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .showView_profile_details {
      display: flex;
      align-items: center;
      gap: 20px;

      img {
        width: 80px;
        height: 80px;
      }

      .showView_profile_details_name {
        display: flex;
        flex-direction: column;
        gap: 12px;

        h4 {
          margin: 0;
          font-size: 16px;
          color: #292929;
          text-transform: capitalize;
          font-weight: 600;
        }

        p {
          margin: 0;
          font-size: 14px;
          color: #292929;
          font-weight: 400;
        }
      }
    }

    .cards_content_bottom_share {
      position: relative;

      .cards_content_bottom_share_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        cursor: pointer;

        .cards_content_bottom_share_icon_body {
          color: #101828;
          font-size: 24px;
        }
      }

      .social_media {
        position: absolute;
        right: 0;
        display: flex;
        z-index: 4;
        background-color: #ffffff;
        flex-direction: column;
        box-shadow: 0px 0px 8px 2px #00000025;
        padding: 8px 0px;
        border-radius: 8px;

        .social_media_container {
          display: flex;
          align-items: center;
          gap: 10px;
          padding: 10px 12px;
          cursor: pointer;
          text-decoration: none;

          .social_media_container_icon_body {
            display: flex;
            align-items: center;
            justify-content: center;

            .social_media_container_icon {
              color: #101828;
              font-size: 18px;
            }
          }

          .social_media_container_paragraph {
            margin: 0;
            font-size: 15px;
            font-weight: 400;
            color: #101828;
          }
        }
      }
    }
  }

  .showView_Wrapper_title {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 18px;

    .showView_Wrapper__titleImageWrapper {
      max-width: 100%;
      position: relative;

      /* max-height: 80vh; */

      .showView_articleWrapper__titleImageWrapperItem {
        border-radius: 8px;
        object-fit: cover;
        object-position: center;
        max-width: 100%;
        min-height: 30rem;
        max-height: 50rem;
      }
    }

    .showView_articleWrapper__titleDetails {
      width: 100%;
      /* margin-bottom: 20px; */

      .showView_articleWrapper__titleDetailsItem {
        /* font-family: "Inter", sans-serif; */
        /* font-family: "Public Sans", sans-serif; */
        /* font-family: "Nunito", sans-serif; */
        font-size: 32px;
        font-weight: 700;
        font-style: normal;
        line-height: 40px;
        color: #101828;
        margin: 0;
        text-transform: capitalize;
        transition: all 1.5s;
      }
    }
  }

  .showView_articleWrapper__articleDetails {
    width: 100%;

    .showView_articleWrapper__articleDetailsItem {
      /* font-family: "Public Sans", sans-serif; */
      /* font-family: "Nunito", sans-serif; */
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      margin: 0;
      line-height: 30px;
      max-width: 100%;
      overflow: auto;
      transition: all 1.5s;

      .image {
        width: 100%;

        img {
          max-width: 100%;
          max-height: 30rem;
        }
      }

      img {
        max-width: 100%;
        max-height: 30rem;
      }
    }
  }

  .articleWrapper__SocialMedai {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0px;

    .articleWrapper__SocialMedaiDetails {
      display: flex;
      align-items: center;

      .articleWrapper__SocialMedaiDetailsLike {
        display: flex;
        align-items: center;
        margin-right: 20px;
        cursor: pointer;

        .articleWrapper__SocialMedaiDetailsLikeIconBody {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 5px;

          .articleWrapper__SocialMedaiDetailsLikeIcon {
            font-size: 15px;
            color: ${({ theme }) => theme.textColor};
            transition: all 1.5s;
          }
        }

        .articleWrapper__SocialMedaiDetailsLikeContent {
          p {
            /* font-family: "Public Sans", sans-serif; */
            font-family: 'Nunito', sans-serif;
            font-size: 15px;
            font-weight: 400;
            font-style: normal;
            color: ${({ theme }) => theme.textColor};
            line-height: 30px;
            transition: all 1.5s;
          }
        }
      }
    }

    .articleWrapper__SocialMedaiStatus {
      display: flex;
      align-items: center;
      gap: 20px;

      .sharing_wrapper {
        position: relative;

        .sharingbody {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          .sharingicon {
            font-size: 20px;
            color: ${({ theme }) => theme.textColor};
            transition: all 1s;
          }
        }

        .SocialWrapper {
          z-index: 10;
          margin-top: 8px;
          position: absolute;
          display: flex;
          align-items: center;
          gap: 10px;
          flex-direction: column;
          box-shadow: 0px 2px 8px 0px ${({ theme }) => theme.boxShaw};
          background-color: ${({ theme }) => theme.primaryColor};
          transition: all 1.5s;
          padding: 10px;
          border-radius: 4px;

          .SocialBody {
            .SocialContent {
              display: flex;
              align-items: center;
              justify-content: center;

              .SocialIcon {
                color: ${({ theme }) => theme.secondaryColor};
                font-size: 14px;
                cursor: pointer;
                transition: all 1.5s;
              }
            }
          }
        }
      }

      .articleWrapper__SocialMedaiStatusSaveIconBody {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .articleWrapper__SocialMedaiStatusSaveIcon {
          font-size: 15px;
          color: ${({ theme }) => theme.textColor};
          transition: all 1.5s;
        }
      }

      .articleWrapper__SocialMedaiStatusFollowIconBodyStae {
        position: relative;

        .articleWrapper__SocialMedaiStatusFollowIconBody {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          .articleWrapper__SocialMedaiStatusFollowIcon {
            font-size: 15px;
            color: ${({ theme }) => theme.textColor};
            transition: all 1.5s;
          }
        }
      }
    }
  }

  .articleWrapper__comment_container {
    .articleWrapper__comment {
      width: 100%;
      border-top: 0.5px solid #bf0a314b;
      padding: 20px 0px;
      transition: all 1.5s;

      .articleWrapper__commentSection {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 30px;

        .articleWrapper__commentSectionImageWrapper {
          width: 70px;
          height: 70px;

          .articleWrapper__commentSectionImage {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }

        .articleWrapper__commentSectionStage {
          width: 90%;
          position: relative;

          .articleWrapper__commentSectionStageTitle {
            width: 100%;

            p {
              font-family: 'Public Sans', sans-serif;
              /* font-family: "Nunito", sans-serif; */
              font-size: 14px;
              font-weight: 400;
              font-style: normal;
              color: ${({ theme }) => theme.textColor};
              line-height: 30px;
              text-transform: capitalize;
              transition: all 1.5s;
            }
          }

          .articleWrapper__commentSectionStageTextarea {
            resize: none;
            width: 100%;
            height: 100px;
            border-radius: 8px;
            padding: 10px;
            color: ${({ theme }) => theme.textColor};
            background-color: transparent;
            border: 1px solid #d0d5dd;
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 1rem;
            line-height: 24px;
            outline: none;
          }

          .articleWrapper__commentSectionStageButton {
            position: absolute;
            right: 0;

            button {
              border: none;
              outline: none;
              padding: 6px 12px;
              cursor: pointer;
              background-color: #0716a6;
              color: #ffffff;
              /* font-family: "Public Sans", sans-serif; */
              /* font-family: "Nunito", sans-serif; */
              font-size: 14px;
              font-weight: 400;
              font-style: normal;
              text-transform: capitalize;
              transition: all 1.5s;
              border-radius: 8px;
            }
          }

          @media screen and (max-width: 800px) {
            width: 80%;
          }
        }
      }

      .articleWrapper__commentTextSectionWrapper {
        width: 100%;
        margin-top: 45px;
        max-height: 600px;
        overflow-y: auto;
        border-top: 0.5px solid #bf0a314b;

        &::-webkit-scrollbar-track {
          border-radius: 5px;
        }

        &::-webkit-scrollbar {
          width: 5px;
          height: 3px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #0716a6;
        }

        .articleWrapper__commentTextSection {
          width: 100%;
          padding: 20px 0px;
          transition: all 1.5s;
          display: flex;
          /* align-items: center; */
          justify-content: space-between;
          gap: 30px;

          .articleWrapper__commentTextSectionWImageWrapper {
            width: 50px;
            height: 50px;

            .articleWrapper__commentTextSectionWImage {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 50%;
            }
          }

          .articleWrapper__commentTextSectionText {
            width: 90%;

            .articleWrapper__commentTextSectionTextTitle {
              p {
                font-family: 'Public Sans', sans-serif;
                /* font-family: "Nunito", sans-serif; */
                font-size: 14px;
                font-weight: 400;
                font-style: normal;
                color: ${({ theme }) => theme.textColor};
                line-height: 30px;
                text-transform: capitalize;
                transition: all 1.5s;

                .articleWrapper__commentTextSectionTextTitleSpan {
                  opacity: 0.5;
                }
              }
            }

            .articleWrapper__commentTextSectionTextBody {
              margin: 10px 0px;
              p {
                /* font-family: "Public Sans", sans-serif; */
                font-family: 'Nunito', sans-serif;
                font-size: 14px;
                font-weight: 400;
                font-style: normal;
                color: ${({ theme }) => theme.textColor};
                line-height: 25px;
                text-transform: capitalize;
                transition: all 1.5s;
              }
            }

            @media screen and (max-width: 400px) {
              width: 80%;
            }
          }
        }
      }
    }
  }

  /* @media screen and (max-width: 500px) {
    width: 100%;
  } */
`;
