import React, { useRef, useState } from "react";
import { UsersSearchDiv } from "./style/user.style";
import { InputText } from "primereact/inputtext";
import { useDispatch } from "react-redux";
import { SearchForUserDetail } from "../../../features/user/userServiceSync";
import { updateSearchCompleteUserDetails } from "../../../features/user/userSlices";

const UsersSearch = () => {
  const dispatch = useDispatch();

  const timeRef = useRef();

  const [search, setSearch] = useState();

  const handleSearch = (e) => {
    const { value } = e.target;

    setSearch(value);

    clearTimeout(timeRef.current);
    if (value) {
      timeRef.current = setTimeout(() => {
        dispatch(SearchForUserDetail({ username: value, limit: 10 }));
      }, 300);
    } else {
      dispatch(updateSearchCompleteUserDetails([]));

      setSearch("");
    }
  };
  return (
    <UsersSearchDiv>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={search || ""}
          placeholder="Search"
          onChange={handleSearch}
        />
      </span>
    </UsersSearchDiv>
  );
};

export default UsersSearch;
