import { styled } from "styled-components";

export const DashboardCardDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .cardComponent {
    box-shadow: 0px 0px 8px 2px #0000000f;
    width: 16%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    padding: 12px 14px;
    background-color: #ffffff;

    .cardComponent_content {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .cardComponent_content_title {
        margin: 0;
        font-size: 14px;
        font-weight: 500;
        color: #101828;
      }

      .cardComponent_content_score {
        margin: 0;
        font-size: 16px;
        font-weight: 400;
        color: #101828;
      }
    }

    .cardComponent_icon_body {
      display: flex;
      align-items: center;
      justify-content: center;

      .cardComponent_icon {
        font-size: 18px;
        color: #101828;
      }
    }

    @media screen and (max-width: 1200px) {
      width: 25%;
      margin-bottom: 30px;
    }

    @media screen and (max-width: 502px) {
      width: 40%;
    }

    @media screen and (max-width: 400px) {
      width: 100%;
    }
  }
`;
