import React, { useEffect, useRef, useState } from 'react';
import { CreatePostComponentDiv } from './styles/createpost.style';
import MyEditor from '../../common/myEditor';
import { MdInsertPhoto } from 'react-icons/md';
import FormData from 'form-data';
import { useDispatch, useSelector } from 'react-redux';
import { Hearts, LineWave } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import HTMLReactParser from 'html-react-parser';
import {
  updateCoverPics,
  updatesingleCharityDetails,
} from '../../../features/charity/charitySlices';
import {
  EditCharity,
  PostCharity,
  uploadImageCharity,
} from '../../../features/charity/charityServicesSync';

const CreateCharityComponent = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [images, setImages] = useState();
  const [title, setTitle] = useState();
  const [location, setLocation] = useState();
  const [data, setData] = useState();
  const [hash_tag, setHashTag] = useState();
  const [dataCount, setDataCount] = useState();

  const {
    contentDetails,
    isPicLoading,
    isBlogLoading,
    articleImageLoader,
    singleCharityDetails,
  } = useSelector((state) => state.charity);

  useEffect(() => {
    if (Object.keys(singleCharityDetails).length > 0) {
      setTitle(singleCharityDetails?.title);
      setData(HTMLReactParser(singleCharityDetails?.article));
      setImages(singleCharityDetails?.cover_pic);
      setDataCount(singleCharityDetails?.wordCount);
      setLocation(singleCharityDetails?.location);
      setHashTag(singleCharityDetails?.hash_tag);

      dispatch(
        updateCoverPics({
          secure_url: singleCharityDetails?.cover_pic,
          public_id: singleCharityDetails?.cover_pic_id,
        })
      );
    }
  }, [singleCharityDetails]);

  function getbase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  const handleCoverImage = (e) => {
    const image = e.target.files[0];

    if (image) {
      let promise = getbase64(image);
      promise
        .then((data) => {
          dispatch(uploadImageCharity({ file: data }));
          return data;
        })
        .then((res) => {
          setImages(image?.name);
        });
    }

    // const reader = new FileReader();
    // reader.readAsDataURL(image);

    // reader.onloadend = () => {
    //   console.log(reader.result);
    //   dispatch(uploadImage({ file: reader.result }));
    // };
  };

  // const handleSelectPdf = async (e) => {
  //   e.preventDefault();

  //   setLoadFile(true);
  //   const files = e.target.files[0];

  //   let formData = new FormData();
  //   formData.append("file", files);

  //   console.log(files, "seasons");

  //   dispatch(uploadImage({ formData }));
  //   setLoadFile(false);
  // };

  const handleEditor = (data, count) => {
    setData(data);
    setDataCount(count);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isPicLoading && !articleImageLoader) {
      if (!title || !data || Object.keys(contentDetails).length === 0) {
        toast('all field must be filled');
      } else {
        const payload = {
          article: data,
          title: title,
          cover_pic: contentDetails?.secure_url,
          cover_pic_id: contentDetails?.public_id,
          wordCount: dataCount,
          location: location,
          hash_tag: hash_tag,
        };
        await dispatch(PostCharity(payload));

        setImages('');
        setData('');
        setTitle('');
        setLocation('');
        setHashTag('');
        dispatch(updateCoverPics({}));

        navigate('/dashboard/charity');
      }
    } else {
      toast('cover picture is on the way');
    }
  };

  const handleEditBlog = async (e) => {
    e.preventDefault();

    if (!isPicLoading && !articleImageLoader) {
      if (!title || !data || Object.keys(contentDetails).length > 0) {
        toast('all field must be filled');
      } else {
        const payload = {
          ...singleCharityDetails,
          article: data,
          title: title,
          cover_pic: contentDetails?.secure_url,
          cover_pic_id: contentDetails?.public_id,
          wordCount: dataCount,
          location: location,
          hash_tag: hash_tag,
        };

        await dispatch(EditCharity(payload));

        setImages('');
        setData('');
        setTitle('');
        setHashTag('');
        setLocation('');
        dispatch(updateCoverPics({}));

        navigate('/dashboard/charity');
      }
    } else {
      toast('cover picture is on the way');
    }
  };

  return (
    <CreatePostComponentDiv>
      {Object.keys(singleCharityDetails).length > 0 && (
        <div className="create__Post__container">
          <button
            className="create__Post"
            onClick={() => {
              dispatch(updatesingleCharityDetails({}));

              setTitle('');
              setData('');
              setLocation('');
              setHashTag('');
              setImages('');
            }}
          >
            Switch To Create a Donation
          </button>
        </div>
      )}

      <div className="createpost_component">
        <div className="createpost_component_header">
          <div className="secondLayout">
            <label>Title of Donation</label>
            <textarea
              name="title"
              rows={5}
              value={title || ''}
              onChange={(e) => setTitle(e?.target?.value)}
            ></textarea>
          </div>

          <div className="picture_clone">
            <label>Cover Picture of Donation</label>

            <div className="picture_clone_picker">
              <div className="picture_clone_picker_container">
                <div className="picture_clone_picker_container_icon_body">
                  <MdInsertPhoto className="picture_clone_picker_container_icon" />
                </div>

                <input
                  type="file"
                  onChange={(e) => handleCoverImage(e)}
                  className="picture_clone_picker_container_file"
                />
              </div>

              {isPicLoading ? (
                <LineWave
                  height="100"
                  width="100"
                  color="#0716A6"
                  ariaLabel="line-wave"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  firstLineColor=""
                  middleLineColor=""
                  lastLineColor=""
                />
              ) : (
                <p className="picture_clone_picker_content">
                  {images ? images : <>click the icon to select a picture</>}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="createpost_component_header">
          <div className="secondLayout">
            <label>Hash Tag(e.g. #news)</label>

            <textarea
              name="hash_tag"
              rows={5}
              value={hash_tag || ''}
              onChange={(e) => {
                const value = e?.target?.value;

                // Determine if the user pressed the backspace or delete key
                const isBackspaceOrDelete =
                  e.nativeEvent.inputType === 'deleteContentBackward' ||
                  e.nativeEvent.inputType === 'deleteContentForward';

                if (!isBackspaceOrDelete) {
                  // Add '#' to the beginning of each word (once)
                  const modifiedText = value
                    .split(' ')
                    .map((word) => {
                      // Check if the word already starts with '#' and remove any additional '#' characters
                      return word.startsWith('#')
                        ? word.replace(/#+/, '#')
                        : '#' + word;
                    })
                    .join(' ');

                  setHashTag(modifiedText);
                } else {
                  setHashTag(value);
                }
              }}
            ></textarea>
          </div>

          <div className="secondLayout">
            <label>Location:</label>

            <textarea
              name="location"
              rows={5}
              value={location || ''}
              onChange={(e) => setLocation(e?.target?.value)}
            ></textarea>
          </div>
        </div>

        <div className="createpost_component_post">
          <label>Content of The Donation</label>
          <MyEditor data={data} handleEditor={handleEditor} />
        </div>
      </div>

      <div className="create__Post__container create__Post__con">
        {isBlogLoading ? (
          <Hearts
            height="80"
            width="80"
            color="#0716A5"
            ariaLabel="hearts-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        ) : (
          <>
            {Object.keys(singleCharityDetails).length > 0 ? (
              <button className="create__Post" onClick={handleEditBlog}>
                Edit Donation
              </button>
            ) : (
              <button className="create__Post" onClick={handleSubmit}>
                Create Donation
              </button>
            )}
          </>
        )}

        {Object.keys(singleCharityDetails).length > 0 && (
          <button
            className="create__Post"
            onClick={() => {
              dispatch(updatesingleCharityDetails({}));

              setTitle('');
              setHashTag('');
              setData('');
              setLocation('');
              setImages('');
            }}
          >
            Switch To Create a Donation
          </button>
        )}
      </div>
    </CreatePostComponentDiv>
  );
};

export default CreateCharityComponent;
