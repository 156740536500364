import { toast } from "react-toastify";
import APIs from "../apiUrl";

const MinisterImageUpload = async (payload) => {
  try {
    const response = await APIs.post("/uploadfiles", payload);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const postMinister = async (payload) => {
  try {
    const response = await APIs.post("/create_minister", payload);

    if (response?.data?.message === "success") {
      toast("Posted successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const editAMinister = async (payload) => {
  try {
    const response = await APIs.patch("/edit_minister", payload);

    if (response?.data?.message === "success") {
      toast("Posted successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getMinister = async () => {
  try {
    const response = await APIs.get(`/all_minister`);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getSingleMinister = async (payload) => {
  try {
    const response = await APIs.get(`/get_single_minister/${payload}`);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const MinisterServices = {
  MinisterImageUpload,
  postMinister,
  editAMinister,
  getMinister,
  getSingleMinister,
};

export default MinisterServices;
