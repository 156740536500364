import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import ResourcesServices from "./resourcesServices";

export const uploadImageResources = createAsyncThunk(
  "resources/uploadimage",
  async (payload) => {
    try {
      let response = await ResourcesServices.ResourcesImageUpload(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const PostResources = createAsyncThunk(
  "resources/postResources",
  async (payload) => {
    try {
      let response = await ResourcesServices.postResources(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const EditResources = createAsyncThunk(
  "resources/editResources",
  async (payload) => {
    try {
      let response = await ResourcesServices.editAResources(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetResources = createAsyncThunk(
  "resources/getResources",
  async (payload) => {
    try {
      let response = await ResourcesServices.getResouces(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetResourcesAdvanced = createAsyncThunk(
  "resources/getAdvResources",
  async (payload) => {
    try {
      let response = await ResourcesServices.getResouces(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetMyResources = createAsyncThunk(
  "resources/getmyResources",
  async (payload) => {
    try {
      let response = await ResourcesServices.getMyResources(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetMyResourcesAdvanced = createAsyncThunk(
  "resources/getmyAdvResources",
  async (payload) => {
    try {
      let response = await ResourcesServices.getMyResources(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetSingleResources = createAsyncThunk(
  "resources/getSingleResources",
  async (payload) => {
    try {
      let response = await ResourcesServices.getSingleResources(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);
