import React from 'react';
import { TaskTableDiv } from './styles.js/taskTable.style';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import EmptyState from '../../common/emptyState';
import moment from 'moment';
import { DomPaginationState } from '../../common/DOM_Pagination_State';
import {
  AllTransaction,
  AllTransactionStatus,
} from '../../../features/payment/paymentServicesSync';
import { NumericFormat } from 'react-number-format';

const TaskTable = () => {
  const dispatch = useDispatch();

  const { totalTransaction, transactionLoader, page, mylimit, selectedStatus } =
    useSelector((state) => state.payment);

  const nextPage = () => {
    if (!transactionLoader) {
      if (totalTransaction?.meta?.page < totalTransaction?.meta?.pageCount) {
        if (selectedStatus?.toLowerCase() === 'all' || selectedStatus === '') {
          dispatch(
            AllTransaction({
              page: page + 1,
              limit: mylimit,
            })
          );
        } else {
          dispatch(
            AllTransactionStatus({
              page: page + 1,
              limit: mylimit,
              status: selectedStatus,
            })
          );
        }
      }
    }
  };

  const prevPage = () => {
    if (!transactionLoader) {
      if (totalTransaction?.meta?.page > 1) {
        if (selectedStatus?.toLowerCase() === 'all' || selectedStatus === '') {
          dispatch(
            AllTransaction({
              page: page - 1,
              limit: mylimit,
            })
          );
        } else {
          dispatch(
            AllTransactionStatus({
              page: page - 1,
              limit: mylimit,
              status: selectedStatus,
            })
          );
        }
      }
    }
  };

  return (
    <TaskTableDiv>
      <div className="track">
        <div className="track_header">
          <h3>Tasks</h3>
        </div>

        <div className="track_table">
          <div className="track_table_headers">
            <div className="track_table_headers_State SN">
              <p>S/N</p>
            </div>

            <div className="track_table_headers_State course">
              <p>AMOUNT</p>
            </div>

            <div className="track_table_headers_State Name">
              <p>CHANNEL</p>
            </div>

            <div className="track_table_headers_State Description">
              <p>CURRENCY</p>
            </div>

            <div className="track_table_headers_State Prerequisite">
              <p>EMAIL</p>
            </div>

            <div className="track_table_headers_State Time_status">
              <p>PAYMENT TIME</p>
            </div>

            <div className="track_table_headers_State status">
              <p>STATUS</p>
            </div>

            {/* <div className="track_table_headers_State Level">
              <p>ACTION</p>
            </div> */}
          </div>

          {!transactionLoader ? (
            <>
              {totalTransaction?.data?.length > 0 ? (
                totalTransaction?.data?.map((item, key) => (
                  <div className="track_table_body" key={key}>
                    <div className="track_table_body_State S_SN">
                      <p>{key + 1}</p>
                    </div>

                    <div className="track_table_body_State S_Course">
                      <p>
                        <NumericFormat
                          displayType="text"
                          thousandSeparator={true}
                          value={Number(Number(item?.amount) / 100).toString()}
                        />
                      </p>
                    </div>

                    <div className="track_table_body_State S_Name">
                      <p>{item?.channel}</p>
                    </div>

                    <div className="track_table_body_State S_Description">
                      <p>{item?.currency}</p>
                    </div>

                    <div className="track_table_body_State S_Prerequisite">
                      <p>{item?.customer?.email}</p>
                    </div>

                    <div className="track_table_body_State S_Time_status">
                      <p>
                        {moment(item?.paidAt).format('MM-DD-YYYY hh:mm:ss')}
                      </p>
                    </div>

                    <div className="track_table_body_State S_Status">
                      <p>{item?.status}</p>
                    </div>

                    {/* <div className="track_table_body_State S_Level">
                      <button
                        onClick={() => {
                          navigate(`/dashboard/create_assessment`);
                        }}
                        className="edit"
                      >
                        Edit
                      </button>

                      <button
                        onClick={() => navigate(`/dashboard/task/${item?._id}`)}
                      >
                        View
                      </button>
                    </div> */}
                  </div>
                ))
              ) : (
                <EmptyState />
              )}
            </>
          ) : (
            <Skeleton height={80} width={'100%'} count={5} />
          )}
        </div>

        <DomPaginationState
          onNext={nextPage}
          onPrev={prevPage}
          skip={totalTransaction?.meta?.page}
          loading={transactionLoader}
        />
      </div>
    </TaskTableDiv>
  );
};

export default TaskTable;
