import { createSlice } from "@reduxjs/toolkit";
import {
  EditSermon,
  GetMySermons,
  GetMySermonsAdvanced,
  GetSermons,
  GetSermonsAdvanced,
  PostSermon,
  UploadImageSermon,
  UploadPdfFile,
} from "./sermonServicesSync";

const initialState = {
  isError: false,
  isSuccess: false,
  isPicLoading: false,
  isBlogLoading: false,
  pdfUploadLoader: false,
  articleImageLoader: false,
  sermonPageLoad: false,
  message: "",
  contentDetails: {},
  singleSermonDetails: {},
  pdfUpLoadContent: {},
  sermonArticleDetails: [],
  orderSermonCount: 0,
  orderPagination: 0,
  ordersLimit: 10,
  averageLimit: 10,
  sermonMyArticleDetails: [],
  mySermonCount: 0,
  myPagination: 0,
  mylimit: 10,
  sermonTab: "all",
};

export const sermonSlice = createSlice({
  name: "sermon",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isPicLoading = false;
      state.isSuccess = false;
      state.isBlogLoading = false;
      state.pdfUploadLoader = false;
      state.sermonPageLoad = false;
      state.message = "";
      state.contentDetails = {};
      state.singleSermonDetails = {};
      state.pdfUpLoadContent = {};
      state.sermonArticleDetails = [];
      state.orderSermonCount = 0;
      state.orderPagination = 0;
      state.ordersLimit = 10;
      state.averageLimit = 10;
      state.sermonMyArticleDetails = [];
      state.myMinistriesCount = 0;
      state.myPagination = 0;
      state.mylimit = 10;
      state.sermonTab = "all";
    },

    checkMessage: (state, action) => {
      state.message = action.payload;
    },

    updateCoverPics: (state, action) => {
      state.contentDetails = action.payload;
    },

    updatePdfUpLoadContent: (state, action) => {
      state.pdfUpLoadContent = action.payload;
    },

    updateSingleSermonDetails: (state, action) => {
      state.singleSermonDetails = action.payload;
    },
    updateSermonTab: (state, action) => {
      state.sermonTab = action.payload;
    },
  },
  extraReducers: {
    [UploadImageSermon.pending]: (state, action) => {
      state.isPicLoading = true;
    },

    [UploadImageSermon.fulfilled]: (state, action) => {
      state.isPicLoading = false;
      state.message = action.payload?.message;
      state.contentDetails = action.payload?.data;
    },

    [UploadImageSermon.rejected]: (state, action) => {
      state.isPicLoading = false;
      state.message = action.payload?.message;
    },

    [PostSermon.pending]: (state, action) => {
      state.isBlogLoading = true;
    },

    [PostSermon.fulfilled]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
    },

    [PostSermon.rejected]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
    },

    [UploadPdfFile.pending]: (state, action) => {
      state.pdfUploadLoader = true;
    },

    [UploadPdfFile.fulfilled]: (state, action) => {
      state.pdfUploadLoader = false;
      state.message = action.payload?.message;
      state.pdfUpLoadContent = action.payload?.data;
    },

    [UploadPdfFile.rejected]: (state, action) => {
      state.pdfUploadLoader = false;
      state.message = action.payload?.message;
    },

    [EditSermon.pending]: (state, action) => {
      state.isBlogLoading = true;
    },

    [EditSermon.fulfilled]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
    },

    [EditSermon.rejected]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
    },

    [GetSermons.pending]: (state, action) => {
      state.sermonPageLoad = true;
    },

    [GetSermons.fulfilled]: (state, action) => {
      state.sermonPageLoad = false;
      state.message = action.payload?.message;
      state.sermonArticleDetails = action?.payload?.data;
      state.orderSermonCount = action?.payload?.count;
    },

    [GetSermons.rejected]: (state, action) => {
      state.sermonPageLoad = false;
      state.message = action.payload?.message;
    },

    [GetSermonsAdvanced.pending]: (state, action) => {
      state.sermonPageLoad = false;
    },

    [GetSermonsAdvanced.fulfilled]: (state, action) => {
      state.sermonPageLoad = false;
      state.message = action.payload?.message;
      state.orderPagination = state.orderPagination + 1;
      state.ordersLimit = state.ordersLimit + state.averageLimit;
      state.sermonArticleDetails = [...action?.payload?.data];
      state.orderSermonCount = action?.payload?.count;
    },

    [GetSermonsAdvanced.rejected]: (state, action) => {
      state.sermonPageLoad = false;
      state.message = action.payload?.message;
    },

    [GetMySermons.pending]: (state, action) => {
      state.sermonPageLoad = true;
    },

    [GetMySermons.fulfilled]: (state, action) => {
      state.sermonPageLoad = false;
      state.message = action.payload?.message;
      state.sermonMyArticleDetails = action?.payload?.data;
      state.myMinistriesCount = action?.payload?.count;
    },

    [GetMySermons.rejected]: (state, action) => {
      state.sermonPageLoad = false;
      state.message = action.payload?.message;
    },

    [GetMySermonsAdvanced.pending]: (state, action) => {
      state.sermonPageLoad = false;
    },

    [GetMySermonsAdvanced.fulfilled]: (state, action) => {
      state.sermonPageLoad = false;
      state.message = action.payload?.message;
      state.myPagination = state.myPagination + 1;
      state.mylimit = state.mylimit + state.averageLimit;
      state.sermonMyArticleDetails = [...action?.payload?.data];
      state.myMinistriesCount = action?.payload?.count;
    },

    [GetMySermonsAdvanced.rejected]: (state, action) => {
      state.sermonPageLoad = false;
      state.message = action.payload?.message;
    },
  },
});

export const {
  reset,
  checkMessage,
  updateCoverPics,
  updateSingleSermonDetails,
  updatePdfUpLoadContent,
  updateSermonTab,
} = sermonSlice.actions;

export default sermonSlice.reducer;
