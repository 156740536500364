import React, { useState } from "react";
import { BlockUserDiv } from "./styles/blockUser.style";
import Warning from "../../../Assets/images/warning.svg";
import ThreeLoader from "../../common/threeLoader";
import { useDispatch, useSelector } from "react-redux";
import { EditTestimonyForApproval } from "../../../features/testimony/testimonyServicesSync";

const ApproveUser = ({ setOpen, payload }) => {
  const dispatch = useDispatch();

  const [change, setChange] = useState(true);

  const { isBlogLoading } = useSelector((state) => state.testimony);

  const handleEditUser = () => {
    const datas = { ...payload, Approve: !payload?.Approve ? true : false };

    dispatch(EditTestimonyForApproval(datas));
  };

  return (
    <BlockUserDiv className="_createTradebuymodal_wrapper">
      {/* {false ? (
        <Loader style={{ height: "150px" }} />
      ) : ( */}
      <>
        <div className="_warning_holder">
          <img src={Warning} alt="warning" width="45px" />
        </div>

        <div className="check__wrapper" style={{ textAlign: "center" }}></div>
        <div className="check__wrapper __dis" style={{ alignItems: "center" }}>
          <p className="_areyou_sure">
            Are you sure you want to {!payload.Approve ? "Decline" : "Approve"}{" "}
            this Testimony?
          </p>
        </div>

        <div className="_modalbutton_wrapper">
          <button
            className="__cancel"
            onClick={() => setOpen(false)}
            disabled={isBlogLoading ? true : false}
          >
            Cancel
          </button>
          <button
            className="__confirm accept"
            onClick={handleEditUser}
            disabled={isBlogLoading ? true : false}
          >
            {!payload.Approve ? (
              <>{!isBlogLoading ? <p>Approve</p> : <ThreeLoader />}</>
            ) : (
              <>{!isBlogLoading ? <p>Decline</p> : <ThreeLoader />}</>
            )}
          </button>
        </div>
      </>
      {/* )} */}
    </BlockUserDiv>
  );
};

export default ApproveUser;
