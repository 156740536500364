import { createSlice } from "@reduxjs/toolkit";
import {
  EditVolunteers,
  EditVolunteersAdvanced,
  EditVolunteersForApproval,
  GetSingleVolunteers,
  GetVolunteers,
  Postvolunteers,
  uploadImageVolunteer,
} from "./volunteerServiceSync";

const initialState = {
  isError: false,
  isSuccess: false,
  isPicLoading: false,
  isBlogLoading: false,
  articleImageLoader: false,
  message: "",
  contentDetails: {},
  volunteerPageLoad: false,
  allVolunteers: [],
  singleVolunteerPageDetails: {},
  editVolunteerDetails: {},
};

export const volunteerSlice = createSlice({
  name: "volunteer",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isPicLoading = false;
      state.isSuccess = false;
      state.isBlogLoading = false;
      state.message = "";
      state.contentDetails = {};
      state.volunteerPageLoad = false;
      state.allVolunteers = [];
      state.singleVolunteerPageDetails = {};
      state.editVolunteerDetails = {};
    },

    checkMessage: (state, action) => {
      state.message = action.payload;
    },

    updateCoverPics: (state, action) => {
      state.contentDetails = action.payload;
    },

    updateEditVolunteerDetails: (state, action) => {
      state.editVolunteerDetails = action.payload;
    },
  },
  extraReducers: {
    [uploadImageVolunteer.pending]: (state, action) => {
      state.isPicLoading = true;
    },

    [uploadImageVolunteer.fulfilled]: (state, action) => {
      state.isPicLoading = false;
      state.message = action.payload?.message;
      state.contentDetails = action.payload?.data;
    },

    [uploadImageVolunteer.rejected]: (state, action) => {
      state.isPicLoading = false;
      state.message = action.payload?.message;
    },

    [Postvolunteers.pending]: (state, action) => {
      state.isBlogLoading = true;
    },

    [Postvolunteers.fulfilled]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
    },

    [Postvolunteers.rejected]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
    },

    [EditVolunteers.pending]: (state, action) => {
      state.isBlogLoading = true;
    },

    [EditVolunteers.fulfilled]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
      state.singleVolunteerPageDetails = action.payload?.data;
    },

    [EditVolunteers.rejected]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
    },

    [EditVolunteersForApproval.pending]: (state, action) => {
      state.isBlogLoading = true;
    },

    [EditVolunteersForApproval.fulfilled]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
      state.singleVolunteerPageDetails = action.payload?.data;
    },

    [EditVolunteersForApproval.rejected]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
    },

    [EditVolunteersAdvanced.pending]: (state, action) => {
      state.isBlogLoading = true;
    },

    [EditVolunteersAdvanced.fulfilled]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
    },

    [EditVolunteersAdvanced.rejected]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
    },

    [GetVolunteers.pending]: (state, action) => {
      state.volunteerPageLoad = true;
    },

    [GetVolunteers.fulfilled]: (state, action) => {
      state.volunteerPageLoad = false;
      state.message = action.payload?.message;
      state.allVolunteers = action?.payload?.data;
    },

    [GetVolunteers.rejected]: (state, action) => {
      state.volunteerPageLoad = false;
      state.message = action.payload?.message;
    },

    [GetSingleVolunteers.pending]: (state, action) => {
      state.volunteerPageLoad = true;
    },

    [GetSingleVolunteers.fulfilled]: (state, action) => {
      state.volunteerPageLoad = false;
      state.message = action.payload?.message;
      state.singleVolunteerPageDetails = action.payload?.data;
    },

    [GetSingleVolunteers.rejected]: (state, action) => {
      state.volunteerPageLoad = false;
      state.message = action.payload?.message;
    },
  },
});

export const {
  reset,
  checkMessage,
  updateCoverPics,
  updateEditVolunteerDetails,
} = volunteerSlice.actions;

export default volunteerSlice.reducer;
