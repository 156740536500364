import React from 'react';
import { MinistriesTabDiv, StoriesTabDiv } from './styles/ministriesTab.styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  GetMyServices,
  GetServices,
} from '../../../features/services/servicesServicesSync';
import { updateServicesTab } from '../../../features/services/servicesSlices';

const MinistriesTab = () => {
  const dispatch = useDispatch();

  const {
    servicesTab,
    ordersLimit,
    mylimit,
    servicesArticleDetails,
    servicesMyArticleDetails,
  } = useSelector((state) => state.services);

  const handleChangeTab = (tab) => {
    dispatch(updateServicesTab(tab));

    if (tab === 'all') {
      if (servicesArticleDetails?.length === 0) {
        dispatch(GetServices(ordersLimit));
      }
    } else {
      if (servicesMyArticleDetails?.length === 0) {
        dispatch(GetMyServices(mylimit));
      }
    }
  };

  return (
    <MinistriesTabDiv>
      <div className="storiesTab">
        <div className="storiesTab_container">
          <button
            className={`${servicesTab === 'all' ? 'selected' : ''}`}
            onClick={() => handleChangeTab('all')}
          >
            All Services
          </button>
        </div>
        <div className="storiesTab_container">
          <button
            className={`${servicesTab === 'my' ? 'selected' : ''}`}
            onClick={() => handleChangeTab('my')}
          >
            My Services
          </button>
        </div>
      </div>
    </MinistriesTabDiv>
  );
};

export default MinistriesTab;
