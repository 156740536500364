import React, { useEffect, useState } from 'react';
import { ProfileInformationDiv } from './styles/profileInformation.styled';
import { useDispatch, useSelector } from 'react-redux';
import { InfinitySpin } from 'react-loader-spinner';
import { Dropdown } from 'primereact/dropdown';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { registerUser } from '../../../features/auth/authServiceSync';
import { updateUserPrefillDetails } from '../../../features/user/userSlices';
import { editUser } from '../../../features/user/userServiceSync';

const AdminOption = [
  { name: 'Top Level', value: 'top_level' },
  { name: 'Mid Level', value: 'mid_level' },
  { name: 'Normal Level', value: 'normal_level' },
];

const toLowerCaseValidator = (value) => {
  return value.toLowerCase();
};

const baseSchema = Yup.object({
  fullname: Yup.string().required(),
  email: Yup.string().email().required(),
  phone: Yup.string().required(),
  admintype: Yup.string().required(),
  password: Yup.string().min(6).required(),
  comfirm_password: Yup.string()
    .min(6)
    .required()
    .oneOf([Yup.ref('password'), null]),
});

const AdminProfileContent = () => {
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.auth);

  const { userPrefillingDetails, editLoader } = useSelector(
    (state) => state.user
  );

  const [formValue, setFormValue] = useState({});

  const [showPassword, setShowPassword] = useState(false);

  const handleSubmited = async (value, { resetForm }) => {
    console.log(value, 'settings');
    // e.preventDefault();

    if (Object.keys(userPrefillingDetails).length > 0) {
      await dispatch(editUser({ ...userPrefillingDetails, ...value }));
    } else {
      await dispatch(registerUser(value));
    }

    resetForm();
  };

  const {
    values,
    field,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    touched,
    errors,
  } = useFormik({
    initialValues: {
      fullname: '',
      email: '',
      phone: '',
      admintype: '',
      password: '',
      comfirm_password: '',
    },
    validationSchema: baseSchema,
    onSubmit: handleSubmited,
  });

  console.log(values, errors, 'fine');

  useEffect(() => {
    setFieldValue('fullname', userPrefillingDetails?.fullname);

    setFieldValue('email', userPrefillingDetails?.email);

    setFieldValue('phone', userPrefillingDetails?.phone);

    setFieldValue('admintype', userPrefillingDetails?.admintype);
  }, [userPrefillingDetails]);

  const isFormFieldInvalid = (name) => !!(touched[name] && errors[name]);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmitee = () => {
    console.log('joshua ejike');
  };

  return (
    <ProfileInformationDiv>
      <div className="profile_header">
        <p>Create Admin</p>
      </div>

      {/* <form className="form_wrapper" onSubmit={handleSubmit}> */}
      <div className="profile_input_field">
        <div className="profile_input_field_input">
          <div className="profile_input_field_input_fields">
            <div className="firstLayout">
              <div className="firstLayout_input_wrapper">
                <label>Fullname</label>
                <input
                  name="fullname"
                  value={values?.fullname || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`${errors.fullname ? 'error' : ''}`}
                />

                {errors.fullname && (
                  <p className="is-danger" style={{ color: 'red' }}>
                    {errors.fullname}
                  </p>
                )}
              </div>

              <div className="firstLayout_input_wrapper">
                <label>Email</label>
                <input
                  name="email"
                  value={values?.email || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`${errors.lastname ? 'error' : ''}`}
                />

                {errors.email && (
                  <p className="is-danger" style={{ color: 'red' }}>
                    {errors.email}
                  </p>
                )}
              </div>
            </div>

            <div className="firstLayout">
              <div className="firstLayout_input_wrapper">
                <label>Phone number</label>
                <input
                  name="phone"
                  value={values?.phone || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`${errors.phone ? 'error' : ''}`}
                />

                {errors.phone && (
                  <p className="is-danger" style={{ color: 'red' }}>
                    {errors.phone}
                  </p>
                )}
              </div>

              <div className="firstLayout_input_wrapper">
                <label>Admin type</label>
                <Dropdown
                  value={values?.admintype}
                  onChange={(e) => {
                    setFieldValue('admintype', e.value);
                  }}
                  options={AdminOption}
                  optionLabel="name"
                  placeholder="Select a Admin Option"
                  className={
                    ('dropdown',
                    classNames({
                      'p-invalid': isFormFieldInvalid('admintype'),
                    }))
                  }
                />
                {errors.admintype && (
                  <p className="is-danger" style={{ color: 'red' }}>
                    {errors.admintype}
                  </p>
                )}
              </div>
            </div>

            <div className="firstLayout">
              <div className="firstLayout_input_wrapper">
                <label>Password*</label>

                <div
                  className={`login_content_header_container_password_input_field`}
                >
                  <input
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    onChange={handleChange}
                    value={values?.password || ''}
                    onBlur={handleBlur}
                    className={`login_content_header_container_password_input ${
                      errors.lastname ? 'error' : ''
                    }`}
                    placeholder="Enter Password"
                  />

                  {!showPassword ? (
                    <div
                      className="login_content_header_container_password_input_icon_body"
                      onClick={handleShowPassword}
                    >
                      <BsFillEyeFill className="login_content_header_container_password_input_icon" />
                    </div>
                  ) : (
                    <div
                      className="login_content_header_container_password_input_icon_body"
                      onClick={handleShowPassword}
                    >
                      <BsFillEyeSlashFill className="login_content_header_container_password_input_icon" />
                    </div>
                  )}
                </div>
                {errors.password && (
                  <p className="is-danger" style={{ color: 'red' }}>
                    {errors.password}
                  </p>
                )}
              </div>
              <div className="firstLayout_input_wrapper">
                <label>Comfirm Password*</label>

                <div
                  className={`login_content_header_container_password_input_field`}
                >
                  <input
                    name="comfirm_password"
                    type={showPassword ? 'text' : 'password'}
                    onChange={handleChange}
                    value={values?.comfirm_password || ''}
                    placeholder="Enter Password"
                    className={`login_content_header_container_password_input ${
                      errors.lastname ? 'error' : ''
                    }`}
                  />
                  {!showPassword ? (
                    <div
                      className="login_content_header_container_password_input_icon_body"
                      onClick={handleShowPassword}
                    >
                      <BsFillEyeFill className="login_content_header_container_password_input_icon" />
                    </div>
                  ) : (
                    <div
                      className="login_content_header_container_password_input_icon_body"
                      onClick={handleShowPassword}
                    >
                      <BsFillEyeSlashFill className="login_content_header_container_password_input_icon" />
                    </div>
                  )}
                </div>
                {errors.comfirm_password && (
                  <p className="is-danger" style={{ color: 'red' }}>
                    {errors.comfirm_password}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="profile_input_field_button">
          {Object.keys(userPrefillingDetails).length > 0 && (
            <button
              onClick={() => {
                dispatch(updateUserPrefillDetails({}));
              }}
            >
              Switch to Create Admin
            </button>
          )}

          {isLoading || editLoader ? (
            <InfinitySpin width="150" color="#0716A6" />
          ) : (
            <button onClick={handleSubmit}>
              {Object.keys(userPrefillingDetails).length > 0 ? (
                <>Edit Admin Details</>
              ) : (
                <>Create Admin Details</>
              )}
            </button>
          )}
        </div>
      </div>
      {/* </form> */}
    </ProfileInformationDiv>
  );
};

export default AdminProfileContent;
