import React, { useEffect } from "react";
import Layout from "../../utils/layout";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LoaderScreen from "../../utils/loaderScreen";
import { GetSingleMinistry } from "../../features/ministries/ministriesServicesSync";
import SingleMinistryComponent from "../../components/DashboardComponent/singleMinistryComponent";

const ViewMinistry = () => {
  const dispatch = useDispatch();

  const { singleMinistryPageLoader } = useSelector((state) => state.ministries);

  const { singleMinistryId } = useParams();

  useEffect(() => {
    dispatch(GetSingleMinistry(singleMinistryId));
  }, []);

  return (
    <Layout>
      {singleMinistryPageLoader ? (
        <LoaderScreen />
      ) : (
        <SingleMinistryComponent />
      )}
    </Layout>
  );
};

export default ViewMinistry;
