import React from "react";
import Layout from "../../utils/layout";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import LoaderScreen from "../../utils/loaderScreen";
import { GetSingleVolunteers } from "../../features/volunteer/volunteerServiceSync";
import VolunteerProfilePage from "../../components/DashboardComponent/volunteerProfile";

function SingleVolunteer() {
  const dispatch = useDispatch();

  const { singleVolunteerId } = useParams();

  const { volunteerPageLoad } = useSelector((state) => state.volunteer);

  useEffect(() => {
    dispatch(GetSingleVolunteers(singleVolunteerId));
  }, [singleVolunteerId]);

  return (
    <Layout>
      {volunteerPageLoad ? <LoaderScreen /> : <VolunteerProfilePage />}
    </Layout>
  );
}

export default SingleVolunteer;
