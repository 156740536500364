import './App.css';
import Login from './Screens/login';
import { Route, Routes, useLocation } from 'react-router-dom';
import Dashboard from './Screens/dashboard';
import Task from './Screens/dashboard/task';
import SideBar from './components/DashboardComponent/sideBar';
import TopNavBar from './components/DashboardComponent/TopBar';
import CreateAssessment from './Screens/dashboard/createAssessment';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import ForgotPassword from './Screens/forgotPassword';
import SingleAssessment from './Screens/dashboard/singleAssessment';
import UserCollection from './Screens/dashboard/userCollection';
import SingleUser from './Screens/dashboard/singleUser';
import 'react-loading-skeleton/dist/skeleton.css';
import Createpost from './Screens/dashboard/createpost';
import ViewCreatedPost from './Screens/dashboard/viewCreatedPost';
import Stories from './Screens/dashboard/stories';
import SocialMedia from './Screens/dashboard/socialMedia';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Ministries from './Screens/dashboard/ministries';
import ViewMinistry from './Screens/dashboard/viewMinistry';
import AllMinister from './Screens/dashboard/allMinister';
import SingleMinister from './Screens/dashboard/singleMinister';
import CreateSermon from './Screens/dashboard/createSermon';
import SermonDisplayPage from './Screens/dashboard/sermonDisplayPage';
import CreateProject from './Screens/dashboard/createProject';
import CreateResources from './Screens/dashboard/createResourcse';
import Resources from './Screens/dashboard/Resources';
import ViewResources from './Screens/dashboard/viewResources';
import CreateServices from './Screens/dashboard/createServices';
import Services from './Screens/dashboard/services';
import CreateTeams from './Screens/dashboard/createTeams';
import CreateVolunteer from './Screens/dashboard/createVolunteers';
import AllVolunteer from './Screens/dashboard/allVolunteers';
import SingleVolunteer from './Screens/dashboard/singleVolunteer';
import CreateCharity from './Screens/dashboard/createCharity';
import Charity from './Screens/dashboard/charity';
import ViewCharity from './Screens/dashboard/viewCharity';
import ViewServices from './Screens/dashboard/viewServices';
import AllTestimony from './Screens/dashboard/allTestimony';
import SingleTestimony from './Screens/dashboard/singleTestimony';
import CreateSponsor from './Screens/dashboard/creatingSponsors';
import CreateGallery from './Screens/dashboard/createGallery';
import CreateAdmin from './Screens/dashboard/createAdmins';
import Faq from './Screens/dashboard/faq';
import ViewFaq from './Screens/dashboard/viewFaq';
import ShowViewFaq from './Screens/dashboard/showViewFaq';

// i go use task page for paystack

function App() {
  const location = useLocation();

  return (
    <div>
      {location.pathname.includes('dashboard') && <SideBar />}

      {location.pathname.includes('dashboard') && <TopNavBar />}

      <Routes>
        <Route path="/" element={<Login />} />

        <Route path="/forgotPassword" element={<ForgotPassword />} />

        <Route path="/dashboard" element={<Dashboard />} />

        {/* // i go use task page for paystack */}
        <Route path="/dashboard/task" element={<Task />} />

        <Route
          path="/dashboard/create_assessment"
          element={<CreateAssessment />}
        />

        <Route path="/dashboard/create_post" element={<Createpost />} />

        <Route path="/dashboard/create_project" element={<CreateProject />} />

        <Route
          path="/dashboard/create_resources"
          element={<CreateResources />}
        />

        <Route path="/dashboard/create_services" element={<CreateServices />} />

        <Route path="/dashboard/create_charity" element={<CreateCharity />} />

        <Route path="/dashboard/create_sponsor" element={<CreateSponsor />} />

        <Route path="/dashboard/create_gallery" element={<CreateGallery />} />

        <Route path="/dashboard/user_collection" element={<UserCollection />} />

        <Route path="/dashboard/task/:taskId" element={<SingleAssessment />} />

        <Route path="/dashboard/user/:userId" element={<SingleUser />} />

        <Route path="/dashboard/stories" element={<Stories />} />

        <Route path="/dashboard/project" element={<Ministries />} />

        <Route path="/dashboard/resources" element={<Resources />} />

        <Route path="/dashboard/services" element={<Services />} />

        <Route path="/dashboard/create-admin" element={<CreateAdmin />} />

        <Route path="/dashboard/charity" element={<Charity />} />

        <Route path="/dashboard/:singlePostId" element={<ViewCreatedPost />} />

        <Route path="/dashboard/faq" element={<Faq />} />

        <Route path="/dashboard/view_faqs" element={<ViewFaq />} />

        <Route path="/dashboard/faq/:faqId" element={<ShowViewFaq />} />

        <Route
          path="/dashboard/ministry/:singleMinistryId"
          element={<ViewMinistry />}
        />

        <Route
          path="/dashboard/resources/:singleResourceId"
          element={<ViewResources />}
        />

        <Route
          path="/dashboard/services/:services"
          element={<ViewServices />}
        />

        <Route
          path="/dashboard/charity/:singleCharityId"
          element={<ViewCharity />}
        />

        <Route path="/dashboard/socialmedia" element={<SocialMedia />} />

        <Route path="/dashboard/create_team" element={<CreateTeams />} />

        <Route
          path="/dashboard/create_volunteers"
          element={<CreateVolunteer />}
        />

        <Route path="/dashboard/all_mininster" element={<AllMinister />} />

        <Route path="/dashboard/all_volunteers" element={<AllVolunteer />} />

        <Route path="/dashboard/all_testimony" element={<AllTestimony />} />

        <Route
          path="/dashboard/mininster/:singleMinisterId"
          element={<SingleMinister />}
        />

        <Route
          path="/dashboard/volunteer/:singleVolunteerId"
          element={<SingleVolunteer />}
        />

        <Route
          path="/dashboard/testimony/:singleTestimonyId"
          element={<SingleTestimony />}
        />

        <Route path="/dashboard/create_sermon" element={<CreateSermon />} />

        <Route path="/dashboard/sermon" element={<SermonDisplayPage />} />
      </Routes>

      <ToastContainer
        position="top-right"
        autoClose={3500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
      />
    </div>
  );
}

export default App;
