import React, { useState } from "react";
import { TrackDiv } from "./styles/track.style";
import { useNavigate } from "react-router-dom";
import EmptyState from "../../common/emptyState";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCoverPics,
  updateEditMinisterDetails,
} from "../../../features/minister/ministerSlices";

const AllMinisterComponent = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { ministerPageLoad, allMinister } = useSelector(
    (state) => state.minister
  );

  return (
    <TrackDiv>
      <div className="track">
        <div className="track_table">
          <div className="track_table_headers">
            <div className="track_table_headers_State SN">
              <p>Name</p>
            </div>

            <div className="track_table_headers_State Name">
              <p>Email</p>
            </div>

            <div className="track_table_headers_State Description">
              <p>Phone Number</p>
            </div>

            <div className="track_table_headers_State Description">
              <p>Status</p>
            </div>

            <div className="track_table_headers_State Level">
              <p>Action</p>
            </div>
          </div>

          {!ministerPageLoad ? (
            <>
              {allMinister?.length > 0 ? (
                allMinister?.map((item, key) => (
                  <div className="track_table_body" key={key}>
                    <div className="track_table_body_State S_SN">
                      <p>{`${item?.firstname} ${item?.lastname}`}</p>
                    </div>

                    <div className="track_table_body_State S_Name">
                      <p>{item?.email}</p>
                    </div>

                    <div className="track_table_body_State S_Description">
                      <p>{item?.phone_number}</p>
                    </div>

                    <div className="track_table_body_State S_Description">
                      <p className={`${item?.block ? "block" : "unblock"}`}>
                        {item?.block ? "Blocked" : "Not Blocked"}
                      </p>
                    </div>

                    <div className="track_table_body_State S_Level">
                      <button
                        onClick={() => {
                          dispatch(
                            updateCoverPics({
                              secure_url: item?.cover_pic,
                              public_id: item?.cover_pic_id,
                            })
                          );

                          dispatch(updateEditMinisterDetails(item));

                          navigate(`/dashboard/create_mininster`);
                        }}
                        className="edit"
                      >
                        Edit
                      </button>

                      <button
                        onClick={() =>
                          navigate(`/dashboard/mininster/${item?._id}`)
                        }
                      >
                        View
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <EmptyState />
              )}
            </>
          ) : (
            <Skeleton height={80} width={"100%"} count={5} />
          )}
        </div>
      </div>
    </TrackDiv>
  );
};

export default AllMinisterComponent;
