import React from 'react';
import StoriesTab from './ministriesTab';
import StoriesCard from './ministriesCard';
import { useDispatch, useSelector } from 'react-redux';
import MiniLoader from '../../../utils/miniLoader';
import InfiniteScroll from 'react-infinite-scroller';
import { InfinitySpin } from 'react-loader-spinner';
import {
  GetMyServicesAdvanced,
  GetServicesAdvanced,
} from '../../../features/services/servicesServicesSync';
import { MinistriesComponentDiv } from './styles/ministriescomponent.styles';

const ServicesComponent = () => {
  const dispatch = useDispatch();

  const {
    servicesArticleDetails,
    servicesMyArticleDetails,
    servicesPageLoad,
    orderPagination,
    myPagination,
    ordersLimit,
    mylimit,
    myServicesCount,
    ordersServicesCount,
    servicesTab,
    averageLimit,
  } = useSelector((state) => state.services);

  console.log(servicesArticleDetails, servicesTab, 'season of work');

  return (
    <MinistriesComponentDiv>
      <div>
        <StoriesTab />
      </div>

      {!servicesPageLoad ? (
        <>
          {servicesTab === 'all' ? (
            <InfiniteScroll
              pageStart={orderPagination}
              loadMore={async () => {
                if (ordersServicesCount > servicesArticleDetails.length) {
                  dispatch(GetServicesAdvanced(ordersLimit + averageLimit));
                }
              }}
              hasMore={true || false}
              loader={
                ordersServicesCount > servicesArticleDetails.length && (
                  <InfinitySpin width="200" color="#0716A6" />
                )
              }
            >
              <div className="stories_card">
                <StoriesCard cardArry={servicesArticleDetails} />
              </div>
            </InfiniteScroll>
          ) : (
            <InfiniteScroll
              pageStart={myPagination}
              loadMore={async () => {
                if (myServicesCount > servicesMyArticleDetails.length) {
                  dispatch(GetMyServicesAdvanced(mylimit + averageLimit));
                }
              }}
              hasMore={true || false}
              loader={
                myServicesCount > servicesMyArticleDetails.length && (
                  <InfinitySpin width="200" color="#0716A6" />
                )
              }
            >
              <div className="stories_card">
                <StoriesCard cardArry={servicesMyArticleDetails} />
              </div>
            </InfiniteScroll>
          )}
        </>
      ) : (
        <MiniLoader />
      )}
    </MinistriesComponentDiv>
  );
};

export default ServicesComponent;
