import styled from 'styled-components';

export const UserProfileHeaderDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;

  .userProfileHeaderWrapper__Image {
    // width: 15%;

    .userProfileHeaderWrapper__ImageWrapper {
      width: 160px;
      height: 160px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fbcb5c;

      p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 96px;
        line-height: 38px;
        color: #101828;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }

  .userProfileHeaderWrapper__Details_container {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 30px;

    .userProfileHeaderWrapper__Details {
      h3 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;
        color: #101828;
      }

      p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #667085;
      }

      .userProfileHeaderWrapper__DetailsContainer {
        display: flex;
        align-items: center;

        .userProfileHeaderWrapper__DetailsContainerIconBody {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;

          .userProfileHeaderWrapper__DetailsContainerIcon {
            font-size: 12px;
            color: #00d709;
          }

          .userProfileHeaderWrapper__DetailsContainerIconBlock {
            font-size: 12px;
            color: #d70000;
          }
        }

        p {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #101828;
        }
      }
    }

    button {
      outline: 2px solid #0716a6;
      border: 3px solid #f7f7f7;
      border-radius: 30px;
      background-color: #0716a6;
      font-size: 16px;
      font-weight: 600;
      color: #ffffff;
      width: 100px;
      padding: 5px 0px;
      cursor: pointer;
    }

    .buttonblock {
      outline: 2px solid #025c06;
      background-color: #025c06;
    }
  }
`;
