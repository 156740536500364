import React, { useEffect } from "react";
import { LoginStyleDiv } from "./styles/login.style";
import ThinOffImage from "../Assets/images/thinkof.jpg";
import { BsFillEyeFill, BsFillPersonFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { LoginUser } from "../features/auth/authServiceSync";
import { useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";

const baseSchema = Yup.object({
  email: Yup.string().email().required(),
  password: Yup.string().min(6).required(),
});

const ForgotPassword = () => {
  const dispatch = useDispatch();

  const navigation = useNavigate();

  const getToken = localStorage.getItem("token");

  const { isLoading, loginDetails } = useSelector((state) => state.auth);

  const handleSubmited = async (value, { resetForm }) => {
    // e.preventDefault();

    const payload = {
      ...value,
    };

    await dispatch(LoginUser(payload));

    resetForm();
  };

  const { value, handleBlur, handleChange, handleSubmit, errors } = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema: baseSchema,
    onSubmit: handleSubmited,
  });

  useEffect(() => {
    if (loginDetails?.token) {
      navigation("/dashboard");
    }
  }, [loginDetails?.token]);

  useEffect(() => {
    if (getToken) {
      navigation("/dashboard");
    }
  }, [getToken]);

  return (
    <LoginStyleDiv>
      <div className="login">
        <div className="login_card">
          <img src={ThinOffImage} alt="details" className="login_card_image" />
        </div>
        <div className="login_content">
          <div className="login_content_header_container">
            <div className="login_content_icon">
              <BsFillPersonFill className="login_content_icon_details" />
            </div>
            <div className="login_content_title_things">
              <h3 className="login_text_header">Hello Again</h3>
              <p className="login_text_paragraph">
                thanks for coming back again we apperciate your coming
              </p>
            </div>
          </div>
          <div className="login_content_header_container_state">
            <div className="login_content_header_container_input">
              <div className="login_content_header_container_email">
                <label>Email:</label>

                <div
                  className={`login_content_header_container_email_input_field ${
                    errors.email ? "error" : ""
                  }`}
                >
                  <input
                    name="email"
                    value={value?.email}
                    onChange={handleChange}
                    placeholder="Enter Email"
                    onBlur={handleBlur}
                    className={`login_content_header_container_email_input `}
                  />

                  <div className="login_content_header_container_email_input_icon_body">
                    <MdEmail className="login_content_header_container_email_input_icon" />
                  </div>
                </div>

                {errors.email && (
                  <p className="is-danger" style={{ color: "red" }}>
                    {errors.email}
                  </p>
                )}
              </div>

              <div className="login_content_header_container_password">
                <label>Password:</label>

                <div
                  className={`login_content_header_container_password_input_field ${
                    errors.password ? "error" : ""
                  }`}
                >
                  <input
                    name="password"
                    type="password"
                    onChange={handleChange}
                    value={value?.password}
                    placeholder="Enter Password"
                    onBlur={handleBlur}
                    className={`login_content_header_container_password_input `}
                  />

                  <div className="login_content_header_container_password_input_icon_body">
                    <BsFillEyeFill className="login_content_header_container_password_input_icon" />
                  </div>
                </div>

                {errors.password && (
                  <p className="is-danger" style={{ color: "red" }}>
                    {errors.password}
                  </p>
                )}
              </div>
            </div>

            {isLoading ? (
              <Bars
                height="80"
                width="80"
                color="#007bff"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            ) : (
              <div className="login_content_header_container_state_button">
                <button onClick={handleSubmit}>Login</button>
              </div>
            )}
          </div>
          <div></div>
        </div>
      </div>
    </LoginStyleDiv>
  );
};

export default ForgotPassword;
