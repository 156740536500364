import React from "react";
import Layout from "../../utils/layout";
import { MessageDiv } from "./styles/message.style";
import CreateAssessmentComponent from "../../components/DashboardComponent/createAssessmentComponent";

const CreateAssessment = () => {
  return (
    <Layout>
      <MessageDiv>
        <div className="message_header">
          <h2>Create Assessment</h2>
          <p>We are Glad to see you again!</p>
        </div>
        <div className="message_chat">
          <CreateAssessmentComponent />
        </div>
      </MessageDiv>
    </Layout>
  );
};

export default CreateAssessment;
