import { createSlice } from '@reduxjs/toolkit';

import {
  LoginUser,
  SubscribeUser,
  contactSupport,
  getAllAdmins,
  registerUser,
} from './authServiceSync';

const initialState = {
  loginDetails: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  isRegister: false,
  message: '',
  isRegister: false,
  tokenDetail: localStorage.getItem('token') || '',
  allAdminsData: [],
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.message = '';
      state.isRegister = false;
      state.loginDetails = null;
      state.allAdminsData = [];
    },
  },
  extraReducers: {
    [registerUser.pending]: (state, action) => {
      state.isLoading = true;
      state.isRegister = false;
    },

    [registerUser.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isRegister = true;
      state.message = action.payload?.message;
    },

    [registerUser.rejected]: (state, action) => {
      state.isLoading = false;
      state.isRegister = false;
      state.message = action.payload?.message;
    },

    [LoginUser.pending]: (state, action) => {
      state.isLoading = true;
    },

    [LoginUser.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.message = action.payload?.message;
      state.loginDetails = action.payload?.data;
    },

    [LoginUser.rejected]: (state, action) => {
      state.isLoading = false;
      state.isRegister = false;
      state.message = action.payload?.message;
    },

    [SubscribeUser.pending]: (state, action) => {
      state.isLoading = true;
    },

    [SubscribeUser.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.message = action.payload?.message;
    },

    [SubscribeUser.rejected]: (state, action) => {
      state.isLoading = false;
      state.isRegister = false;
      state.message = action.payload?.message;
    },

    [contactSupport.pending]: (state, action) => {
      state.isLoading = true;
    },

    [contactSupport.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.message = action.payload?.message;
    },

    [contactSupport.rejected]: (state, action) => {
      state.isLoading = false;
      state.isRegister = false;
      state.message = action.payload?.message;
    },

    [getAllAdmins.pending]: (state, action) => {
      state.isLoading = true;
    },

    [getAllAdmins.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.allAdminsData = action.payload?.data;
      state.message = action.payload?.message;
    },

    [getAllAdmins.rejected]: (state, action) => {
      state.isLoading = false;
      state.isRegister = false;
      state.message = action.payload?.message;
    },
  },
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;
