import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import BloggersServices from "./bloggersServices";

export const uploadImage = createAsyncThunk(
  "blogger/uploadimage",
  async (payload) => {
    try {
      let response = await BloggersServices.BloggerImageUpload(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const PostBlogger = createAsyncThunk(
  "blogger/postBlog",
  async (payload) => {
    try {
      let response = await BloggersServices.postBlogger(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const EditBlogger = createAsyncThunk(
  "blogger/editBlog",
  async (payload) => {
    try {
      let response = await BloggersServices.editABlogger(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetBlogger = createAsyncThunk(
  "blogger/getBlog",
  async (payload) => {
    try {
      let response = await BloggersServices.getBlogger(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetBloggerAdvanced = createAsyncThunk(
  "blogger/getAdvBlog",
  async (payload) => {
    try {
      let response = await BloggersServices.getBlogger(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetMyBlogger = createAsyncThunk(
  "blogger/getmyBlog",
  async (payload) => {
    try {
      let response = await BloggersServices.getMyBlogger(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetMyBloggerAdvanced = createAsyncThunk(
  "blogger/getmyAdvBlog",
  async (payload) => {
    try {
      let response = await BloggersServices.getMyBlogger(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetSingleBlogger = createAsyncThunk(
  "blogger/getSingleBlog",
  async (payload) => {
    try {
      let response = await Promise.all([
        BloggersServices.getSingleBlogger(payload),
        BloggersServices.getBloggerComment(payload),
      ])
        .then((data) => {
          return data;
        })
        .catch((err) => {
          throw err;
        });

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetAllBlogger = createAsyncThunk(
  "blogger/getAllBlog",
  async (payload) => {
    try {
      let response = await BloggersServices.getAllBlogger(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetAllBloggerAdvanced = createAsyncThunk(
  "blogger/getAllAdvBlog",
  async (payload) => {
    try {
      let response = await BloggersServices.getAllBlogger(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const PostComment = createAsyncThunk(
  "blogger/postComment",
  async (payload) => {
    try {
      let response = await BloggersServices.createComment(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);
