import { toast } from "react-toastify";
import APIs from "../apiUrl";

const ResourcesImageUpload = async (payload) => {
  try {
    const response = await APIs.post("/uploadfiles", payload);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const postResources = async (payload) => {
  try {
    const response = await APIs.post("/create_resources", payload);

    if (response?.data?.message === "success") {
      toast("Posted successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const editAResources = async (payload) => {
  try {
    const response = await APIs.patch("/edit_resources", payload);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getResouces = async (payload) => {
  try {
    const response = await APIs.get(`/get_resources/${payload}`);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getMyResources = async (payload) => {
  try {
    const response = await APIs.get(`/get_my_resources/${payload}`);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getSingleResources = async (payload) => {
  try {
    const response = await APIs.get(`/get_single_resources/${payload}`);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const ResourcesServices = {
  ResourcesImageUpload,
  postResources,
  getResouces,
  getMyResources,
  editAResources,
  getSingleResources,
};

export default ResourcesServices;
