import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SermonTabDiv } from "./styles/ministriesTab.styles";
import { updateSermonTab } from "../../../features/sermon/sermonSlices";
import {
  GetMySermons,
  GetSermons,
} from "../../../features/sermon/sermonServicesSync";

const SermonTabs = () => {
  const dispatch = useDispatch();

  const {
    sermonTab,
    ordersLimit,
    mylimit,
    sermonArticleDetails,
    sermonMyArticleDetails,
  } = useSelector((state) => state.sermon);

  const handleChangeTab = (tab) => {
    dispatch(updateSermonTab(tab));

    if (tab === "all") {
      if (sermonArticleDetails?.length === 0) {
        dispatch(GetSermons(ordersLimit));
      }
    } else {
      if (sermonMyArticleDetails?.length === 0) {
        dispatch(GetMySermons(mylimit));
      }
    }
  };

  return (
    <SermonTabDiv>
      <div className="storiesTab">
        <div className="storiesTab_container">
          <button
            className={`${sermonTab === "all" ? "selected" : ""}`}
            onClick={() => handleChangeTab("all")}
          >
            All Sermons
          </button>
        </div>
        <div className="storiesTab_container">
          <button
            className={`${sermonTab === "my" ? "selected" : ""}`}
            onClick={() => handleChangeTab("my")}
          >
            My Sermons
          </button>
        </div>
      </div>
    </SermonTabDiv>
  );
};

export default SermonTabs;
