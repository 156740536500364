import React, { useEffect } from 'react';
import { ProfileDiv } from './styles/message.style';
import Layout from '../../utils/layout';
import { useDispatch, useSelector } from 'react-redux';
import { GetSocial } from '../../features/social/socialServicesSync';
import MiniLoader from '../../utils/miniLoader';
import AdminProfileComponent from '../../components/DashboardComponent/AdminProfileComponent';
import './styles/dropdown.css';
import { updateUserPrefillDetails } from '../../features/user/userSlices';

const CreateAdmin = () => {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   return () => {
  //     console.log('notifiy');
  //     dispatch(updateUserPrefillDetails({}));
  //   };
  // }, []);

  return (
    <Layout>
      <ProfileDiv>
        <div className="profile_header">
          <h2>Create Admins</h2>
        </div>

        <div className="profile_content">
          <AdminProfileComponent />
        </div>
      </ProfileDiv>
    </Layout>
  );
};

export default CreateAdmin;
