import React, { useEffect } from "react";
import Layout from "../../utils/layout";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LoaderScreen from "../../utils/loaderScreen";
import SingleResourcesComponent from "../../components/DashboardComponent/singleResourcesComponent";
import { GetSingleResources } from "../../features/resources/resourcesServicesSync";

const ViewResources = () => {
  const dispatch = useDispatch();

  const { singleResourcesPageLoader } = useSelector((state) => state.resources);

  const { singleResourceId } = useParams();

  useEffect(() => {
    dispatch(GetSingleResources(singleResourceId));
  }, [singleResourceId]);

  return (
    <Layout>
      {singleResourcesPageLoader ? (
        <LoaderScreen />
      ) : (
        <SingleResourcesComponent />
      )}
    </Layout>
  );
};

export default ViewResources;
