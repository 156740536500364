import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import ServicesServices from "./servicesServices";

export const uploadImageServices = createAsyncThunk(
  "services/uploadimage",
  async (payload) => {
    try {
      let response = await ServicesServices.ServicesImageUpload(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const PostServices = createAsyncThunk(
  "services/postServices",
  async (payload) => {
    try {
      let response = await ServicesServices.postServices(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const EditServices = createAsyncThunk(
  "services/editServices",
  async (payload) => {
    try {
      let response = await ServicesServices.editAServices(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetServices = createAsyncThunk(
  "services/getServices",
  async (payload) => {
    try {
      let response = await ServicesServices.getServices(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetServicesAdvanced = createAsyncThunk(
  "services/getAdvServices",
  async (payload) => {
    try {
      let response = await ServicesServices.getServices(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetMyServices = createAsyncThunk(
  "services/getmyServices",
  async (payload) => {
    try {
      let response = await ServicesServices.getMyServices(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetMyServicesAdvanced = createAsyncThunk(
  "services/getmyAdvServices",
  async (payload) => {
    try {
      let response = await ServicesServices.getMyServices(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetSingleServices = createAsyncThunk(
  "services/getSingleServices",
  async (payload) => {
    try {
      let response = await ServicesServices.getSingleServices(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);
