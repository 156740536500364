import React, { useEffect } from "react";
import Layout from "../../utils/layout";
import { MessageDiv } from "./styles/message.style";
import { useDispatch, useSelector } from "react-redux";
import MiniLoader from "../../utils/miniLoader";
import { GetTestimony } from "../../features/testimony/testimonyServicesSync";
import AllTestimonyComponent from "../../components/DashboardComponent/allTestimonyComponent";

function AllTestimony() {
  const dispatch = useDispatch();

  const { testimonyPageLoad } = useSelector((state) => state.testimony);

  useEffect(() => {
    dispatch(GetTestimony());
  }, []);

  return (
    <Layout>
      <MessageDiv>
        <div className="message_header">
          <h2>All Testimony</h2>
        </div>
        {testimonyPageLoad ? (
          <MiniLoader />
        ) : (
          <div className="message_chat">
            <AllTestimonyComponent />
          </div>
        )}
      </MessageDiv>
    </Layout>
  );
}

export default AllTestimony;
