import { styled } from 'styled-components';

export const BlockUserDiv = styled.div`
  width: 410px;
  padding: 20px 24px;
  @media screen and(max-width: 730px) {
    width: 90vw;
  }

  ._warning_holder {
    width: 100%;
    display: flex;
    justify-content: center;
    ._warning_image {
      width: 40px;
      height: 40px;
    }
    ._confirm_payment {
      font-size: 17px;
      font-weight: 500;
    }
  }

  ._areyou_sure {
    font-size: 15px;
    font-weight: 500;
    margin: 15px 0;
    text-align: center;
  }

  ._thetraders_details {
    background-color: rgba(147, 129, 255, 0.05);
    padding: 19px 0;
    width: 100%;
    margin-bottom: 5px;
    text-align: center;
    p {
      font-size: 13px;
      span {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  ._click_to_accept {
    font-size: 13px;
    margin: 15px 0;
  }
  .check__wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 20px;
    &.__dis {
      flex-direction: column;
    }
    .__textareawrapper {
      padding: 13px 13px 5px 13px;
      width: 100%;
      outline: none;
      border: 1px solid #d0d5dd;
      border-radius: 7px;
      textarea {
        border: none;
        outline: none;
        width: 100%;
      }
      span {
        text-align: right;
        font-size: 11px;
        display: block;
      }
    }
    ._warning_text {
      color: rgb(230, 36, 36);
      font-size: 11px;
      display: block;
      text-align: center;
      text-transform: capitalize;
      margin-top: 4px;
    }

    .__terms {
      font-size: 13px;
      width: 94%;
      color: #344054;
      line-height: 21px;
    }
  }
  ._modalbutton_wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    @media screen and(max-width: 510px) {
      flex-direction: column-reverse;
    }

    .__cancel {
      width: 45%;
      padding: 8px 20px;
      text-align: center;
      border-radius: 5px;
      background-color: white;
      border: 1px solid #d0d5dd;
      outline: none;
      @media screen and(max-width: 510px) {
        width: 100%;
        margin-bottom: 15px;
        padding: 12px 20px;
      }
    }
    .__confirm {
      width: 45%;
      padding: 8px 20px;
      text-align: center;
      border-radius: 5px;
      background-color: white;
      border: 1px solid #d0d5dd;
      outline: none;
      color: grey;
      cursor: not-allowed;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and(max-width: 510px) {
        width: 100%;
        margin-bottom: 15px;
        padding: 12px 20px;
      }

      &:disabled {
      }

      &.accept {
        color: white;
        cursor: pointer;
        background-color: #0716a6;
      }
    }
  }
`;
