import React, { useState } from "react";
import ProfilePhoto from "../../../Assets/images/avatar-profile-photo.png";
import { SingleUserDiv } from "./style/singleUser.style";
import { Dialog } from "primereact/dialog";
import BlockUser from "./blockUser";
import { BsFacebook, BsGithub, BsLinkedin, BsTwitter } from "react-icons/bs";
import { TfiWorld } from "react-icons/tfi";
import SingleUserTable from "./singleUserTable";
import { useSelector } from "react-redux";
import moment from "moment";
import { NavLink } from "react-router-dom";

const SingleUserComponent = () => {
  const [visible, setVisible] = useState(false);

  const { otherUserDetails } = useSelector((state) => state.user);

  return (
    <>
      <Dialog
        // header="Header"
        visible={visible}
        style={{ borderRadius: "20px" }}
        onHide={() => setVisible(false)}
        modal={true}
        closeIcon={() => {
          return;
        }}
      >
        <BlockUser payload={otherUserDetails} setOpen={setVisible} />
      </Dialog>
      <SingleUserDiv>
        <div className="singleUserHeader">
          <div className="singleUserHeader_details">
            <div className="singleUserHeader_details_image">
              <img src={ProfilePhoto} />
            </div>

            <div className="singleUserHeader_details_wrapper">
              <div className="singleUserHeader_details_wrapper_content">
                <p className="singleUserHeader_title">Full Name:</p>
                <p className="singleUserHeader_text">{`${otherUserDetails?.firstname} ${otherUserDetails?.lastname}`}</p>
              </div>

              <div className="singleUserHeader_details_wrapper_content">
                <p className="singleUserHeader_title">Address:</p>
                <p className="singleUserHeader_text">
                  {otherUserDetails?.address}
                </p>
              </div>

              <div className="singleUserHeader_details_wrapper_content">
                <p className="singleUserHeader_title">Phone number:</p>
                <p className="singleUserHeader_text">
                  {otherUserDetails?.phone_number}
                </p>
              </div>

              <div className="singleUserHeader_details_wrapper_content">
                <p className="singleUserHeader_title">Email:</p>
                <p className="singleUserHeader_text">
                  {otherUserDetails?.email}
                </p>
              </div>

              <div className="singleUserHeader_details_wrapper_content">
                <p className="singleUserHeader_title">Username:</p>
                <p className="singleUserHeader_text">
                  {otherUserDetails?.username}
                </p>
              </div>

              <div className="singleUserHeader_details_wrapper_content">
                <p className="singleUserHeader_title">Joined:</p>
                <p className="singleUserHeader_text">
                  {moment(otherUserDetails?.createdAt).format("MMM DD, YYYY")}
                </p>
              </div>

              <div className="singleUserHeader_details_wrapper_content">
                <p className="singleUserHeader_title">Country:</p>
                <p className="singleUserHeader_text">
                  {otherUserDetails?.country}
                </p>
              </div>

              <div className="singleUserHeader_details_wrapper_content">
                <p className="singleUserHeader_title">Gender:</p>
                <p className="singleUserHeader_text">
                  {otherUserDetails?.gender}
                </p>
              </div>
            </div>
          </div>
          <div className="singleUserHeader_button">
            {!otherUserDetails?.block ? (
              <button onClick={() => setVisible(true)}>Block this User</button>
            ) : (
              <button onClick={() => setVisible(true)}>
                unBlock this User
              </button>
            )}
          </div>
        </div>
        <div className="singleUserOther">
          <h3>Other User Properties</h3>

          <div className="singleUserOther_header">
            <div className="singleUserOther_content">
              <div className="singleUserOther_content_label">
                <p className="singleUserHeader_title">Age:</p>
                <p className="singleUserHeader_text">{otherUserDetails?.age}</p>
              </div>

              <div className="singleUserOther_content_label">
                <p className="singleUserHeader_title">Employment:</p>
                <p className="singleUserHeader_text">
                  {otherUserDetails?.employment}
                </p>
              </div>

              <div className="singleUserOther_content_label">
                <p className="singleUserHeader_title">Experience Level:</p>
                <p className="singleUserHeader_text">
                  {otherUserDetails?.experience_level}
                </p>
              </div>

              <div className="singleUserOther_content_label">
                <p className="singleUserHeader_title">Educational Level:</p>
                <p className="singleUserHeader_text">
                  {otherUserDetails?.educational_level}
                </p>
              </div>
            </div>
            <div className="singleUserOther_social">
              {otherUserDetails?.facebook && (
                <NavLink
                  to={`${otherUserDetails?.facebook}`}
                  target="blank"
                  className="singleUserOther_social_wrapper"
                >
                  <div className="singleUserOther_social_icon_body">
                    <BsFacebook className="singleUserOther_social_icon" />
                  </div>
                  <p>Facebook</p>
                </NavLink>
              )}

              {otherUserDetails?.linkedln && (
                <NavLink
                  to={`${otherUserDetails?.linkedln}`}
                  target="blank"
                  className="singleUserOther_social_wrapper"
                >
                  <div className="singleUserOther_social_icon_body">
                    <BsLinkedin className="singleUserOther_social_icon" />
                  </div>
                  <p>LinkedIn</p>
                </NavLink>
              )}

              {otherUserDetails?.twitter && (
                <NavLink
                  to={`${otherUserDetails?.twitter}`}
                  target="blank"
                  className="singleUserOther_social_wrapper"
                >
                  <div className="singleUserOther_social_icon_body">
                    <BsTwitter className="singleUserOther_social_icon" />
                  </div>
                  <p>Twitter</p>
                </NavLink>
              )}

              {otherUserDetails?.github && (
                <NavLink
                  to={`${otherUserDetails?.github}`}
                  target="blank"
                  className="singleUserOther_social_wrapper"
                >
                  <div className="singleUserOther_social_icon_body">
                    <BsGithub className="singleUserOther_social_icon" />
                  </div>
                  <p>Github</p>
                </NavLink>
              )}

              {otherUserDetails?.my_website && (
                <NavLink
                  to={`${otherUserDetails?.my_website}`}
                  target="blank"
                  className="singleUserOther_social_wrapper"
                >
                  <div className="singleUserOther_social_icon_body">
                    <TfiWorld className="singleUserOther_social_icon" />
                  </div>
                  <p>My Websites</p>
                </NavLink>
              )}
            </div>
          </div>
        </div>
        <div>
          <SingleUserTable />
        </div>
      </SingleUserDiv>
    </>
  );
};

export default SingleUserComponent;
