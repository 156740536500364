import React, { useRef, useState } from "react";
import ProfilePic from "../../../Assets/images/avatar-profile-photo.svg";
import CardImage from "../../../Assets/program.svg";
import { FiShare2 } from "react-icons/fi";
import { ShowViewDiv } from "./styles/showView.style";
import { useSelector } from "react-redux";
import HTMLReactParser from "html-react-parser";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { BsLinkedin, BsFacebook, BsTwitter, BsWhatsapp } from "react-icons/bs";
import { BiSolidCopy } from "react-icons/bi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { baseUrl } from "../../../utils/baseUrl";
import useOnClickOutside from "../../../hooks/useOnClickOutside";

const SingleServicesComponent = () => {
  const dropRef = useRef();

  const [selectedId, setSelectedId] = useState();

  const { singleServicesDetails } = useSelector((state) => state.services);

  useOnClickOutside(dropRef, () => setSelectedId());

  return (
    <ShowViewDiv>
      <div className="showView_header">
        <div className="showView_profile_details">
          <img src={ProfilePic} />

          <div className="showView_profile_details_name">
            <h4>{singleServicesDetails?.username}</h4>
            <p>
              {moment(singleServicesDetails?.date).format("MMM DD, YYYY hh:mm")}
            </p>
          </div>
        </div>

        <div className="cards_content_bottom_share">
          <div
            className="cards_content_bottom_share_icon"
            onClick={() => setSelectedId(singleServicesDetails?._id)}
          >
            <FiShare2 className="cards_content_bottom_share_icon_body" />
          </div>

          {selectedId && (
            <div className="social_media" ref={dropRef}>
              <CopyToClipboard
                text={`${baseUrl}/${singleServicesDetails?._id}`}
                onCopy={() => toast("successfully copied")}
              >
                <div className="social_media_container">
                  <div className="social_media_container_icon_body">
                    <BiSolidCopy className="social_media_container_icon" />
                  </div>
                  <p className="social_media_container_paragraph">CopyLink</p>
                </div>
              </CopyToClipboard>

              <NavLink
                to={`https://www.linkedin.com/shareArticle?url=${baseUrl}/${singleServicesDetails._id}&title=${singleServicesDetails?.title}`}
                target="blank"
                className="social_media_container"
              >
                <div className="social_media_container_icon_body">
                  <BsLinkedin className="social_media_container_icon" />
                </div>
                <p className="social_media_container_paragraph">Linkedin</p>
              </NavLink>

              <NavLink
                to={`https://www.facebook.com/sharer.php?u=${baseUrl}/${singleServicesDetails._id}`}
                target="blank"
                className="social_media_container"
              >
                <div className="social_media_container_icon_body">
                  <BsFacebook className="social_media_container_icon" />
                </div>
                <p className="social_media_container_paragraph">FaceBook</p>
              </NavLink>

              <NavLink
                to={`https://twitter.com/share?url=${baseUrl}/${singleServicesDetails._id}&text=${singleServicesDetails?.title}`}
                target="blank"
                className="social_media_container"
              >
                <div className="social_media_container_icon_body">
                  <BsTwitter className="social_media_container_icon" />
                </div>
                <p className="social_media_container_paragraph">Twitter</p>
              </NavLink>

              <NavLink
                to={`https://api.whatsapp.com/send?text=${singleServicesDetails?.title} ${baseUrl}/${singleServicesDetails._id}`}
                target="blank"
                className="social_media_container"
              >
                <div className="social_media_container_icon_body">
                  <BsWhatsapp className="social_media_container_icon" />
                </div>
                <p className="social_media_container_paragraph">Whatsapp</p>
              </NavLink>
            </div>
          )}
        </div>
      </div>
      <div className="showView_Wrapper_title">
        <div className="showView_articleWrapper__titleDetails">
          <h2 className="showView_articleWrapper__titleDetailsItem">
            {singleServicesDetails?.title}
          </h2>
        </div>
        <div className="showView_Wrapper__titleImageWrapper">
          <img
            src={
              singleServicesDetails?.cover_pic
                ? `${singleServicesDetails?.cover_pic}`
                : CardImage
            }
            className="showView_articleWrapper__titleImageWrapperItem"
          />
        </div>
      </div>
      <div className="showView_articleWrapper__articleDetails">
        <p className="showView_articleWrapper__articleDetailsItem">
          {singleServicesDetails?.article &&
            HTMLReactParser(HTMLReactParser(singleServicesDetails?.article))}
        </p>
      </div>
    </ShowViewDiv>
  );
};

export default SingleServicesComponent;
