import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import UserServices from './userService';

export const getUser = createAsyncThunk('user/user', async () => {
  try {
    let response = await UserServices.getUserDetails();

    console.log('why this use, response', response);

    return response;
  } catch (error) {
    toast(error.message);
    throw error;
  }
});

export const editUser = createAsyncThunk('user/edit_user', async (payload) => {
  try {
    let response = await UserServices.editUserDetails(payload);

    return response;
  } catch (error) {
    toast(error.message);
    throw error;
  }
});

export const editUserPassword = createAsyncThunk(
  'user/edit_user_password',
  async (payload) => {
    try {
      let response = await UserServices.editUserPassword(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GeneralTotalCount = createAsyncThunk(
  'user/general_count',
  async (payload) => {
    try {
      let response = await UserServices.generalTotalCount(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetAllUserDetail = createAsyncThunk(
  'user/user_details',
  async (payload) => {
    try {
      let response = await UserServices.getAllUserDetails(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetAllUserDetailAdvance = createAsyncThunk(
  'user/user_details_advanced',
  async (payload) => {
    try {
      let response = await UserServices.getAllUserDetails(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetOtherUserDetail = createAsyncThunk(
  'user/other_user_details',
  async (payload) => {
    try {
      let response = await UserServices.getOtherUserDetails(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const EditUserFromAdmin = createAsyncThunk(
  'user/edit_user_from_admin',
  async (payload) => {
    try {
      let response = await UserServices.editUserFromAdmin(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const DeleteAdmin = createAsyncThunk(
  'user/deleteUser',
  async (payload) => {
    try {
      let response = await UserServices.DeleteAdmin(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const SearchForUserDetail = createAsyncThunk(
  'user/search_for_user_details',
  async (payload) => {
    try {
      let response = await UserServices.searchUserDetails(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);
