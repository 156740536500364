import { toast } from "react-toastify";
import APIs from "../apiUrl";

const CharityImageUpload = async (payload) => {
  try {
    const response = await APIs.post("/uploadfiles", payload);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const postCharity = async (payload) => {
  try {
    const response = await APIs.post("/create_charity", payload);

    if (response?.data?.message === "success") {
      toast("Posted successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const editACharity = async (payload) => {
  try {
    const response = await APIs.patch("/edit_charity", payload);

    if (response?.data?.message === "success") {
      toast("Posted successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getCharity = async (payload) => {
  try {
    const response = await APIs.get(`/get_charity/${payload}`);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getMyCharity = async (payload) => {
  try {
    const response = await APIs.get(`/get_my_charity/${payload}`);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getSingleCharity = async (payload) => {
  try {
    const response = await APIs.get(`/get_single_charity/${payload}`);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const CharityServices = {
  CharityImageUpload,
  postCharity,
  getCharity,
  getMyCharity,
  editACharity,
  getSingleCharity,
};

export default CharityServices;
