import { styled } from "styled-components";

export const TaskTabDiv = styled.div`
  position: relative;
  width: 300px;

  @media screen and (max-width: 400px) {
    width: 100%;
  }

  .taskTabs {
    border: 1px solid #101828;
    border-radius: 20px;
    padding: 10px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 10px;

    p {
      margin: 0px;
      color: #101828;
      font-size: 18px;
      /* font-family: Roboto; */
      font-weight: 400;
    }

    .taskTabs_space {
      display: flex;
      align-items: center;
      justify-content: center;

      .taskTabs_space_coin {
        color: #101828;
        font-size: 20px;
        /* font-family: Roboto; */
        font-weight: 700;
        /* line-height: 30px; */
      }
    }
  }

  .task_tab_dropdown {
    width: 100%;
    box-shadow: 0px 0px 8px 2px #0000000d;
    height: 250px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: absolute;
    z-index: 20;
    background-color: #fff;

    .task_tab_dropdown_items {
      padding: 10px 14px;
      cursor: pointer;

      p {
        margin: 0;
        color: #101828;
        font-size: 18px;
        /* font-family: Roboto; */
        font-weight: 400;
      }
    }

    .Selectedtabs {
      background-color: #0000000d;
    }
  }
`;
