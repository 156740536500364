import React, { memo } from "react";
import UserProfileContact from "./userProfileContact";
import UserProfileHeader from "./userProfileHeader";
import UserProfileMoreInformation from "./userProfileMoreInformation";
import { UserProfileMainDiv } from "./styles/userProfileMain.style";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Dialog } from "primereact/dialog";
import ApproveUser from "./approveUser";

const CserProfileCompontent = () => {
  const { singleTestimonyPageDetails } = useSelector(
    (state) => state.testimony
  );

  const [openApprove, setpenApprove] = useState(false);

  return (
    <>
      <Dialog
        // header="Header"
        visible={openApprove}
        style={{ borderRadius: "20px" }}
        onHide={() => setpenApprove(false)}
        modal={true}
        closeIcon={() => {
          return;
        }}
      >
        <ApproveUser
          setOpen={setpenApprove}
          payload={singleTestimonyPageDetails}
        />
      </Dialog>
      <UserProfileMainDiv>
        <div className="userAdminWrapperHeader">
          <UserProfileHeader
            firstName={singleTestimonyPageDetails?.firstname}
            lastName={singleTestimonyPageDetails?.lastname}
            jobTitle={singleTestimonyPageDetails?.office}
            cover_pic={singleTestimonyPageDetails?.cover_pic}
            statusApprovered={singleTestimonyPageDetails?.Approve}
            setpenApprove={setpenApprove}
          />
        </div>

        <div className="userAdminWrapperBody">
          <div className="userAdminWrapperBodyTwo">
            <UserProfileContact
              email={singleTestimonyPageDetails?.email}
              phone={singleTestimonyPageDetails?.phone_number}
              address={singleTestimonyPageDetails?.address}
            />
          </div>

          <div className="userAdminWrapperBodyOne">
            <UserProfileMoreInformation
              country={singleTestimonyPageDetails?.country}
              state={singleTestimonyPageDetails?.state}
              gender={singleTestimonyPageDetails?.gender}
              about={singleTestimonyPageDetails?.about}
            />
          </div>
        </div>
      </UserProfileMainDiv>
    </>
  );
};

export default memo(CserProfileCompontent);
