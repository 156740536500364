import React, { useEffect } from "react";
import Layout from "../../utils/layout";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LoaderScreen from "../../utils/loaderScreen";
import SingleCharityComponent from "../../components/DashboardComponent/singleCharityComponent";
import { GetSingleCharity } from "../../features/charity/charityServicesSync";

const ViewCharity = () => {
  const dispatch = useDispatch();

  const { singleCharityPageLoader } = useSelector((state) => state.charity);

  const { singleCharityId } = useParams();

  useEffect(() => {
    dispatch(GetSingleCharity(singleCharityId));
  }, [singleCharityId]);

  return (
    <Layout>
      {singleCharityPageLoader ? <LoaderScreen /> : <SingleCharityComponent />}
    </Layout>
  );
};

export default ViewCharity;
