import { toast } from "react-toastify";
import APIs from "../apiUrl";

const ServicesImageUpload = async (payload) => {
  try {
    const response = await APIs.post("/uploadfiles", payload);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const postServices = async (payload) => {
  try {
    const response = await APIs.post("/create_services", payload);

    if (response?.data?.message === "success") {
      toast("Posted successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const editAServices = async (payload) => {
  try {
    const response = await APIs.patch("/edit_services", payload);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getServices = async (payload) => {
  try {
    const response = await APIs.get(`/get_services/${payload}`);

    console.log(response?.data, "fesical work bro");

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getMyServices = async (payload) => {
  try {
    const response = await APIs.get(`/get_my_services/${payload}`);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getSingleServices = async (payload) => {
  try {
    const response = await APIs.get(`/get_single_services/${payload}`);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const ServicesServices = {
  ServicesImageUpload,
  postServices,
  getServices,
  getMyServices,
  editAServices,
  getSingleServices,
};

export default ServicesServices;
