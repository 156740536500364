import React from "react";
import { SponsorDiv } from "./styles/sponsor.Style";
import ImageState from "../../../Assets/blogimagetwo.svg";
import { FiEdit } from "react-icons/fi";

const SponserCard = ({ image, title, onclick }) => {
  return (
    <SponsorDiv>
      <div className="sponsorCardWrapper">
        <img
          src={image ? image : ImageState}
          className="sponsorCardWrapperImage"
        />
      </div>

      <div className="editIcon" onClick={onclick}>
        <FiEdit className="editIconIcon" />
      </div>

      <div className="sponsor_Title">
        <h3 className="sponsor_Title_state">{title}</h3>
      </div>
    </SponsorDiv>
  );
};

export default SponserCard;
