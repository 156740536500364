import { createSlice } from "@reduxjs/toolkit";
import {
  EditMinister,
  EditMinisterAdvanced,
  GetMinister,
  GetSingleMinister,
  PostMinister,
  uploadImageMinister,
} from "./ministerServiceSync";

const initialState = {
  isError: false,
  isSuccess: false,
  isPicLoading: false,
  isBlogLoading: false,
  articleImageLoader: false,
  message: "",
  contentDetails: {},
  ministerPageLoad: false,
  allMinister: [],
  singleMinisterPageDetails: {},
  editMinistersDetails: {},
};

export const ministerSlice = createSlice({
  name: "minister",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isPicLoading = false;
      state.isSuccess = false;
      state.isBlogLoading = false;
      state.message = "";
      state.contentDetails = {};
      state.ministerPageLoad = false;
      state.allMinister = [];
      state.singleMinisterPageDetails = {};
      state.editMinistersDetails = {};
    },

    checkMessage: (state, action) => {
      state.message = action.payload;
    },

    updateCoverPics: (state, action) => {
      state.contentDetails = action.payload;
    },

    updateEditMinisterDetails: (state, action) => {
      state.editMinistersDetails = action.payload;
    },
  },
  extraReducers: {
    [uploadImageMinister.pending]: (state, action) => {
      state.isPicLoading = true;
    },

    [uploadImageMinister.fulfilled]: (state, action) => {
      state.isPicLoading = false;
      state.message = action.payload?.message;
      state.contentDetails = action.payload?.data;
    },

    [uploadImageMinister.rejected]: (state, action) => {
      state.isPicLoading = false;
      state.message = action.payload?.message;
    },

    [PostMinister.pending]: (state, action) => {
      state.isBlogLoading = true;
    },

    [PostMinister.fulfilled]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
    },

    [PostMinister.rejected]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
    },

    [EditMinister.pending]: (state, action) => {
      state.isBlogLoading = true;
    },

    [EditMinister.fulfilled]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
      state.singleMinisterPageDetails = action.payload?.data;
    },

    [EditMinister.rejected]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
    },

    [EditMinisterAdvanced.pending]: (state, action) => {
      state.isBlogLoading = true;
    },

    [EditMinisterAdvanced.fulfilled]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
    },

    [EditMinisterAdvanced.rejected]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
    },

    [GetMinister.pending]: (state, action) => {
      state.ministerPageLoad = true;
    },

    [GetMinister.fulfilled]: (state, action) => {
      state.ministerPageLoad = false;
      state.message = action.payload?.message;
      state.allMinister = action?.payload?.data;
    },

    [GetMinister.rejected]: (state, action) => {
      state.ministerPageLoad = false;
      state.message = action.payload?.message;
    },

    [GetSingleMinister.pending]: (state, action) => {
      state.ministerPageLoad = true;
    },

    [GetSingleMinister.fulfilled]: (state, action) => {
      state.ministerPageLoad = false;
      state.message = action.payload?.message;
      state.singleMinisterPageDetails = action.payload?.data;
    },

    [GetSingleMinister.rejected]: (state, action) => {
      state.ministerPageLoad = false;
      state.message = action.payload?.message;
    },
  },
});

export const {
  reset,
  checkMessage,
  updateCoverPics,
  updateEditMinisterDetails,
} = ministerSlice.actions;

export default ministerSlice.reducer;
