import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import GalleryServices from "./galleryServices";

export const GalleryUploadImage = createAsyncThunk(
  "gallery/uploadimage",
  async (payload) => {
    try {
      let response = await GalleryServices.GalleryImageUpload(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const PostGallery = createAsyncThunk(
  "gallery/PostSponsor",
  async (payload) => {
    try {
      let response = await GalleryServices.postGallery(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const EditGallery = createAsyncThunk(
  "gallery/editSponsor",
  async (payload) => {
    try {
      let response = await GalleryServices.editAGallery(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetGallery = createAsyncThunk(
  "gallery/getSponsors",
  async (payload) => {
    try {
      let response = await GalleryServices.getGallery(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetMyCreatedGallery = createAsyncThunk(
  "gallery/getCreatedSponsors",
  async (payload) => {
    try {
      let response = await GalleryServices.getMyCreatedGallery(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetGalleryAdv = createAsyncThunk(
  "gallery/getSponsorsadv",
  async (payload) => {
    try {
      let response = await GalleryServices.getGallery(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetMyCreatedGalleryAdv = createAsyncThunk(
  "gallery/getCreatedSponsorsadv",
  async (payload) => {
    try {
      let response = await GalleryServices.getMyCreatedGallery(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);
