import { createSlice } from "@reduxjs/toolkit";
import {
  EditResources,
  GetMyResources,
  GetMyResourcesAdvanced,
  GetResources,
  GetResourcesAdvanced,
  GetSingleResources,
  PostResources,
  uploadImageResources,
} from "./resourcesServicesSync";

const initialState = {
  isError: false,
  isSuccess: false,
  isPicLoading: false,
  isBlogLoading: false,
  articleImageLoader: false,
  message: "",
  contentDetails: {},
  singleResourcesDetails: {},
  resourcesArticleDetails: [],
  resourcesMyArticleDetails: [],
  mylimit: 10,
  myPagination: 0,
  ordersLimit: 10,
  orderPagination: 0,
  myResourcesCount: 0,
  ordersResourcesCount: 0,
  averageLimit: 10,
  resourcesPageLoad: false,
  resourcesTab: "all",
  singleResourcesPageLoader: false,
  singleResourcesPageDetails: {},
};

export const resourcesSlice = createSlice({
  name: "resources",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isPicLoading = false;
      state.isSuccess = false;
      state.isBlogLoading = false;
      state.message = "";
      state.contentDetails = {};
      state.resourcesArticleDetails = [];
      state.resourcesMyArticleDetails = [];
      state.resourcesPageLoad = false;
      state.mylimit = 10;
      state.myPagination = 0;
      state.ordersLimit = 10;
      state.orderPagination = 0;
      state.myResourcesCount = 0;
      state.ordersResourcesCount = 0;
      state.singleResourcesPageLoader = false;
      state.singleResourcesDetails = {};
      state.resourcesTab = "all";
    },

    checkMessage: (state, action) => {
      state.message = action.payload;
    },

    updateCoverPics: (state, action) => {
      state.contentDetails = action.payload;
    },

    updateResourcesPageLoader: (state, action) => {
      state.resourcesPageLoad = action.payload;
    },

    updateOrderResourcesCount: (state, action) => {
      state.ordersResourcesCount = action.payload;
    },

    updateSingleResourcesDetails: (state, action) => {
      state.singleResourcesDetails = action.payload;
    },

    updataeSingleResourcesPageLoader: (state, action) => {
      state.singleResourcesPageLoader = action.payload;
    },

    updateResourcesTab: (state, action) => {
      state.resourcesTab = action.payload;
    },
  },
  extraReducers: {
    [uploadImageResources.pending]: (state, action) => {
      state.isPicLoading = true;
    },

    [uploadImageResources.fulfilled]: (state, action) => {
      state.isPicLoading = false;
      state.message = action.payload?.message;
      state.contentDetails = action.payload?.data;
    },

    [uploadImageResources.rejected]: (state, action) => {
      state.isPicLoading = false;
      state.message = action.payload?.message;
    },

    [PostResources.pending]: (state, action) => {
      state.isBlogLoading = true;
    },

    [PostResources.fulfilled]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
    },

    [PostResources.rejected]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
    },

    [EditResources.pending]: (state, action) => {
      state.isBlogLoading = true;
    },

    [EditResources.fulfilled]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
    },

    [EditResources.rejected]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
    },

    [GetResources.pending]: (state, action) => {
      state.resourcesPageLoad = true;
    },

    [GetResources.fulfilled]: (state, action) => {
      state.resourcesPageLoad = false;
      state.message = action.payload?.message;
      state.resourcesArticleDetails = action?.payload?.data;
      state.ordersResourcesCount = action?.payload?.count;
    },

    [GetResources.rejected]: (state, action) => {
      state.resourcesPageLoad = false;
      state.message = action.payload?.message;
    },

    [GetResourcesAdvanced.pending]: (state, action) => {
      state.resourcesPageLoad = false;
    },

    [GetResourcesAdvanced.fulfilled]: (state, action) => {
      state.resourcesPageLoad = false;
      state.message = action.payload?.message;
      state.orderPagination = state.orderPagination + 1;
      state.ordersLimit = state.ordersLimit + state.averageLimit;
      state.resourcesArticleDetails = [...action?.payload?.data];
      state.ordersResourcesCount = action?.payload?.count;
    },

    [GetResourcesAdvanced.rejected]: (state, action) => {
      state.resourcesPageLoad = false;
      state.message = action.payload?.message;
    },

    [GetMyResources.pending]: (state, action) => {
      state.resourcesPageLoad = true;
    },

    [GetMyResources.fulfilled]: (state, action) => {
      state.resourcesPageLoad = false;
      state.message = action.payload?.message;
      state.resourcesMyArticleDetails = action?.payload?.data;
      state.myResourcesCount = action?.payload?.count;
    },

    [GetMyResources.rejected]: (state, action) => {
      state.resourcesPageLoad = false;
      state.message = action.payload?.message;
    },

    [GetMyResourcesAdvanced.pending]: (state, action) => {
      state.resourcesPageLoad = false;
    },

    [GetMyResourcesAdvanced.fulfilled]: (state, action) => {
      state.resourcesPageLoad = false;
      state.message = action.payload?.message;
      state.myPagination = state.myPagination + 1;
      state.mylimit = state.mylimit + state.averageLimit;
      state.resourcesMyArticleDetails = [...action?.payload?.data];
      state.myResourcesCount = action?.payload?.count;
    },

    [GetMyResourcesAdvanced.rejected]: (state, action) => {
      state.resourcesPageLoad = false;
      state.message = action.payload?.message;
    },

    [GetSingleResources.pending]: (state, action) => {
      state.singleResourcesPageLoader = true;
    },

    [GetSingleResources.fulfilled]: (state, action) => {
      state.singleResourcesPageLoader = false;
      state.message = action.payload?.message;
      state.singleResourcesPageDetails = action.payload?.data;
    },

    [GetSingleResources.rejected]: (state, action) => {
      state.singleResourcesPageLoader = false;
      state.message = action.payload?.message;
    },
  },
});

export const {
  reset,
  checkMessage,
  updateCoverPics,
  updateResourcesPageLoader,
  updateOrderResourcesCount,
  updateSingleResourcesDetails,
  updataeSingleResourcesPageLoader,
  updateResourcesTab,
} = resourcesSlice.actions;

export default resourcesSlice.reducer;
