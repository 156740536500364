import { styled } from "styled-components";

export const MinistriesComponentDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-bottom: 30px;

  .stories_card {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
`;
