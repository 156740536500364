import React from 'react';
import { MinistriesTabDiv, StoriesTabDiv } from './styles/ministriesTab.styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  GetMinistries,
  GetMyMinistries,
} from '../../../features/ministries/ministriesServicesSync';
import { updateMinistriesTab } from '../../../features/ministries/ministriesSlices';

const MinistriesTab = () => {
  const dispatch = useDispatch();

  const {
    ministriesTab,
    ordersLimit,
    mylimit,
    ministriesArticleDetails,
    ministriesMyArticleDetails,
  } = useSelector((state) => state.ministries);

  const handleChangeTab = (tab) => {
    dispatch(updateMinistriesTab(tab));

    if (tab === 'all') {
      if (ministriesArticleDetails?.length === 0) {
        dispatch(GetMinistries(ordersLimit));
      }
    } else {
      if (ministriesMyArticleDetails?.length === 0) {
        dispatch(GetMyMinistries(mylimit));
      }
    }
  };

  return (
    <MinistriesTabDiv>
      <div className="storiesTab">
        <div className="storiesTab_container">
          <button
            className={`${ministriesTab === 'all' ? 'selected' : ''}`}
            onClick={() => handleChangeTab('all')}
          >
            All Projects
          </button>
        </div>
        <div className="storiesTab_container">
          <button
            className={`${ministriesTab === 'my' ? 'selected' : ''}`}
            onClick={() => handleChangeTab('my')}
          >
            My Projects
          </button>
        </div>
      </div>
    </MinistriesTabDiv>
  );
};

export default MinistriesTab;
