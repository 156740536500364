import { toast } from 'react-toastify';
import APIs from '../apiUrl';

const postFaq = async (payload) => {
  try {
    console.log(payload, 'filessed');
    const response = await APIs.post('/create_faq', payload);

    if (response?.data?.message === 'success') {
      toast('Posted successfully');
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const editAFaq = async (payload) => {
  try {
    const response = await APIs.patch('/edit_faq', payload);

    if (response?.data?.message === 'success') {
      toast('Posted successfully');
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getFaq = async (payload) => {
  try {
    const response = await APIs.get(`/get_faq/${payload}`);

    if (response?.data?.message === 'success') {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getMyFaq = async (payload) => {
  try {
    const response = await APIs.get(`/get_my_faq/${payload}`);

    if (response?.data?.message === 'success') {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getSingleFaq = async (payload) => {
  try {
    const response = await APIs.get(`/get_single_faq/${payload}`);

    if (response?.data?.message === 'success') {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const FaqServices = {
  postFaq,
  getFaq,
  getMyFaq,
  editAFaq,
  getSingleFaq,
};

export default FaqServices;
