import { createAsyncThunk } from '@reduxjs/toolkit';
import AuthServices from './authService';
import { toast } from 'react-toastify';

export const registerUser = createAsyncThunk(
  'auth/register',
  async (payload) => {
    try {
      let response = await AuthServices.registerUser(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const LoginUser = createAsyncThunk('auth/login', async (payload) => {
  try {
    let response = await AuthServices.loginUser(payload);

    return response;
  } catch (error) {
    toast(error.message);
    throw error;
  }
});

export const SubscribeUser = createAsyncThunk(
  'auth/subscription',
  async (payload) => {
    try {
      let response = await AuthServices.subscribeUser(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const contactSupport = createAsyncThunk(
  'auth/support',
  async (payload) => {
    try {
      let response = await AuthServices.contactSupport(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const getAllAdmins = createAsyncThunk(
  'auth/getAllAdmins',
  async (payload) => {
    try {
      let response = await AuthServices.getAdminUsers(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);
