import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import MinistriesServices from "./ministriesServices";

export const uploadImageMinistries = createAsyncThunk(
  "ministries/uploadimage",
  async (payload) => {
    try {
      let response = await MinistriesServices.MinistriesImageUpload(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const PostMinistries = createAsyncThunk(
  "ministries/postMinistries",
  async (payload) => {
    try {
      let response = await MinistriesServices.postMinistries(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const EditMinistry = createAsyncThunk(
  "ministries/editMinistry",
  async (payload) => {
    try {
      let response = await MinistriesServices.editAMinistry(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetMinistries = createAsyncThunk(
  "ministries/getMinistries",
  async (payload) => {
    try {
      let response = await MinistriesServices.getMinistries(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetMinistriesAdvanced = createAsyncThunk(
  "ministries/getAdvMinistries",
  async (payload) => {
    try {
      let response = await MinistriesServices.getMinistries(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetMyMinistries = createAsyncThunk(
  "ministries/getmyMinistries",
  async (payload) => {
    try {
      let response = await MinistriesServices.getMyMinistries(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetMyMinistriesAdvanced = createAsyncThunk(
  "ministries/getmyAdvMinistries",
  async (payload) => {
    try {
      let response = await MinistriesServices.getMyMinistries(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetSingleMinistry = createAsyncThunk(
  "ministries/getSingleMinistry",
  async (payload) => {
    try {
      let response = await MinistriesServices.getSingleMinistry(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);
