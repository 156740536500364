import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { CreatedAssignment } from "./styles/createAssessment.style";
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import MyEditor from "../../common/myEditor";
import { Button } from "primereact/button";
import { MdDelete } from "react-icons/md";
import { BiSolidEdit } from "react-icons/bi";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Bars } from "react-loader-spinner";
import DeleteAssessment from "./blockUser";
import { Dialog } from "primereact/dialog";

const stages = [
  { name: "1" },
  { name: "2" },
  { name: "3" },
  { name: "4" },
  { name: "5" },
];

const baseSchema = Yup.object({
  course: Yup.string().required(),
  title: Yup.string().required(),
  description: Yup.string().required(),
  time_status: Yup.string().required(),
  total_mark: Yup.number().required(),
  assessment_questions: Yup.array().required(),
});

const CreateAssessmentComponent = () => {
  const [visible, setVisible] = useState(false);

  const dispatch = useDispatch();

  const [Assessment, setAssessment] = useState([]);

  const [editAssessment, seteditAssessment] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [editLoader, setEditLoader] = useState();

  const [data, setData] = useState("");
  const [dataCount, setDataCount] = useState();
  const [title, setTitle] = useState();
  const [questionMark, setQuestionMark] = useState(0);

  const handleSubmited = async (value, { resetForm }) => {
    // e.preventDefault();

    const payload = {
      ...value,
    };

    resetForm();

    setAssessment([]);
  };

  const {
    values,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    errors,
  } = useFormik({
    initialValues: {
      course: "",
      title: "",
      description: "",
      time_status: "",
      total_mark: 0,
      stage: "",
      assessment_questions: [],
    },
    validationSchema: baseSchema,
    onSubmit: handleSubmited,
  });

  const handleEditor = (data, count) => {
    setData(data);
    setDataCount(count);
  };

  const AddAssessment = () => {
    if (data && title) {
      setAssessment((Assessment) => [
        ...Assessment,
        {
          title: title,
          content: data,
          question_mark: questionMark,
        },
      ]);

      setTitle("");
      setData("");
      setQuestionMark(0);
    }
  };

  const handleEditAssessment = (data) => {
    setData(data.content);
    setTitle(data?.title);
    setQuestionMark(Number(data?.questionMark));

    const filterForState = Assessment.filter(
      (item, key) => item?.title !== data?.title
    );

    setAssessment(filterForState);
  };

  const handleDeleteAssessment = (data) => {
    const filterForState = Assessment.filter(
      (item, key) => item?.title !== data?.title
    );

    setAssessment(filterForState);
  };

  const handleSubmissionOfAssessment = async (e) => {
    e.preventDefault();

    await setFieldValue("assessment_questions", Assessment);

    handleSubmit();
  };

  const ClearAllEditDetails = () => {
    resetForm();
  };

  const DeleteClearAllEditDetails = () => {
    setVisible(true);
    resetForm();
  };

  const EditClearAllEditDetails = async (e) => {
    e.preventDefault();

    await setFieldValue("assessment_questions", Assessment);

    handleSubmit();
  };

  return (
    <>
      <Dialog
        // header="Header"
        visible={visible}
        style={{ borderRadius: "20px" }}
        onHide={() => setVisible(false)}
        modal={true}
        closeIcon={() => {
          return;
        }}
      >
        <DeleteAssessment payload={{}} setOpen={setVisible} />
      </Dialog>
      <CreatedAssignment>
        <div className="assignmentWrapper">
          <div className="assessmentAlign">
            <label htmlFor="username">Course</label>
            <InputText
              value={values.course || ""}
              onChange={(e) => {
                setFieldValue("course", e.target.value);
              }}
            />
          </div>
          <div className="assessmentAlign">
            <label htmlFor="username">Title</label>
            <InputText
              value={values.title || ""}
              onChange={(e) => {
                setFieldValue("title", e.target.value);
              }}
            />
          </div>
        </div>
        <div className="assignmentWrapper">
          <div className="assessmentAlign">
            <label htmlFor="username">Status Time</label>
            <Calendar
              value={values.time_status || ""}
              onChange={(e) => {
                setFieldValue("time_status", e.value);
              }}
              showTime
              hourFormat="24"
            />
          </div>

          <div className="assessmentAlign">
            <label htmlFor="username">Total Mark</label>
            <InputNumber
              value={values.total_mark || ""}
              onChange={(e) => {
                setFieldValue("total_mark", e.value);
              }}
              useGrouping={false}
            />
          </div>
        </div>

        <div className="assignmentWrapperWrap">
          <div className="assessmentAlign">
            <label htmlFor="username">Stage</label>
            <InputText
              value={values.stage || ""}
              onChange={(e) => {
                if (
                  e.target.value === "1" ||
                  e.target.value === "2" ||
                  e.target.value === "3" ||
                  e.target.value === "4" ||
                  e.target.value === "5" ||
                  e.target.value === ""
                ) {
                  setFieldValue("stage", e.target.value);
                }
              }}
            />
          </div>

          <div className="assessmentAlign">
            <label htmlFor="username">Description</label>
            <InputTextarea
              autoResize
              value={values.description || ""}
              onChange={(e) => {
                setFieldValue("description", e.target.value);
              }}
              rows={5}
              cols={30}
            />
          </div>
        </div>

        <div className="assessment_editor">
          <h2>Assessment Questions</h2>

          <div className="assessment_editor_div">
            <div className="assessmentAlign">
              <label htmlFor="username">Task Title</label>
              <InputText
                value={title || ""}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>

            <div className="assessmentAlign">
              <label htmlFor="username">Question Mark</label>
              <InputNumber
                value={questionMark || ""}
                onChange={(e) => setQuestionMark(Number(e.value))}
                useGrouping={false}
              />
            </div>

            <MyEditor data={data} handleEditor={handleEditor} />

            <div className="assessment_editor_button">
              <div className="assessment_editor_editor">
                <Button
                  className="paddings"
                  label="Add"
                  onClick={AddAssessment}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="assessment_editor_assessment">
          {Assessment?.map((item, key) => (
            <div key={key} className="assessment_edit_state">
              <div className="assessment_edit_state_counter_wrapper">
                <p>{`${key + 1}.`}</p>
              </div>
              <div className="assessment_edit_state_wrapper">
                <div className="assessment_edit_state_style">
                  <h2>{`${item?.title?.slice(0, 50)}...`}</h2>
                </div>

                <div className="assessment_edit_icons">
                  <div
                    className="assessment_edit_icons_body"
                    onClick={() => handleDeleteAssessment(item)}
                  >
                    <MdDelete className="assessment_edit_icons_delete_icon" />
                  </div>

                  <div
                    className="assessment_edit_icons_body"
                    onClick={() => handleEditAssessment(item)}
                  >
                    <BiSolidEdit className="assessment_edit_icons_edit_icon" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {!editAssessment ? (
          <div className="assessment_editor_editor_button">
            {isLoading ? (
              <Bars
                height="80"
                width="80"
                color="#007bff"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            ) : (
              <Button
                type="button"
                onClick={handleSubmissionOfAssessment}
                className="paddings"
                label="Create"
                rounded
              />
            )}
          </div>
        ) : (
          <div className="assessment_editor_editor_button_edit_wrapper">
            <Button
              type="button"
              onClick={ClearAllEditDetails}
              className="paddings"
              label="Clear Edit"
              rounded
            />

            <div className="assessment_editor_editor_button_edit_sub">
              {editLoader ? (
                <Bars
                  height="80"
                  width="80"
                  color="#007bff"
                  ariaLabel="bars-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              ) : (
                <Button
                  type="button"
                  onClick={EditClearAllEditDetails}
                  className="paddings"
                  label="Edit"
                  rounded
                />
              )}

              <Button
                type="button"
                onClick={DeleteClearAllEditDetails}
                className="paddings"
                label="Delete"
                severity="danger"
                rounded
              />
            </div>
          </div>
        )}
      </CreatedAssignment>
    </>
  );
};

export default CreateAssessmentComponent;
