import { createSlice } from "@reduxjs/toolkit";
import {
  EditTestimony,
  EditTestimonyAdvanced,
  EditTestimonyForApproval,
  GetSingleTestimony,
  GetTestimony,
  uploadImageTestimony,
} from "./testimonyServicesSync";

const initialState = {
  isError: false,
  isSuccess: false,
  isPicLoading: false,
  isBlogLoading: false,
  articleImageLoader: false,
  message: "",
  contentDetails: {},
  testimonyPageLoad: false,
  allTestimony: [],
  singleTestimonyPageDetails: {},
  editTestimonyDetails: {},
};

export const testimonySlice = createSlice({
  name: "testimony",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isPicLoading = false;
      state.isSuccess = false;
      state.isBlogLoading = false;
      state.message = "";
      state.contentDetails = {};
      state.testimonyPageLoad = false;
      state.allTestimony = [];
      state.singleTestimonyPageDetails = {};
      state.editTestimonyDetails = {};
    },

    checkMessage: (state, action) => {
      state.message = action.payload;
    },

    updateCoverPics: (state, action) => {
      state.contentDetails = action.payload;
    },

    updateeditTestimonyDetails: (state, action) => {
      state.editTestimonyDetails = action.payload;
    },
  },
  extraReducers: {
    [uploadImageTestimony.pending]: (state, action) => {
      state.isPicLoading = true;
    },

    [uploadImageTestimony.fulfilled]: (state, action) => {
      state.isPicLoading = false;
      state.message = action.payload?.message;
      state.contentDetails = action.payload?.data;
    },

    [uploadImageTestimony.rejected]: (state, action) => {
      state.isPicLoading = false;
      state.message = action.payload?.message;
    },

    [EditTestimony.pending]: (state, action) => {
      state.isBlogLoading = true;
    },

    [EditTestimony.fulfilled]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
      state.singleTestimonyPageDetails = action.payload?.data;
    },

    [EditTestimony.rejected]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
    },

    [EditTestimonyForApproval.pending]: (state, action) => {
      state.isBlogLoading = true;
    },

    [EditTestimonyForApproval.fulfilled]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
      state.singleTestimonyPageDetails = action.payload?.data;
    },

    [EditTestimonyForApproval.rejected]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
    },

    [EditTestimonyAdvanced.pending]: (state, action) => {
      state.isBlogLoading = true;
    },

    [EditTestimonyAdvanced.fulfilled]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
    },

    [EditTestimonyAdvanced.rejected]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
    },

    [GetTestimony.pending]: (state, action) => {
      state.testimonyPageLoad = true;
    },

    [GetTestimony.fulfilled]: (state, action) => {
      state.testimonyPageLoad = false;
      state.message = action.payload?.message;
      state.allTestimony = action?.payload?.data;
    },

    [GetTestimony.rejected]: (state, action) => {
      state.testimonyPageLoad = false;
      state.message = action.payload?.message;
    },

    [GetSingleTestimony.pending]: (state, action) => {
      state.testimonyPageLoad = true;
    },

    [GetSingleTestimony.fulfilled]: (state, action) => {
      state.testimonyPageLoad = false;
      state.message = action.payload?.message;
      state.singleTestimonyPageDetails = action.payload?.data;
    },

    [GetSingleTestimony.rejected]: (state, action) => {
      state.testimonyPageLoad = false;
      state.message = action.payload?.message;
    },
  },
});

export const {
  reset,
  checkMessage,
  updateCoverPics,
  updateeditTestimonyDetails,
} = testimonySlice.actions;

export default testimonySlice.reducer;
