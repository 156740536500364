import React, { useEffect } from "react";
import Layout from "../../utils/layout";
import { StoriesDiv } from "./styles/message.style";
import { useDispatch, useSelector } from "react-redux";
import { reset } from "../../features/ministries/ministriesSlices";
import { GetMinistries } from "../../features/ministries/ministriesServicesSync";
import MinistriesComponent from "../../components/DashboardComponent/ministriesComponent";

const Ministries = () => {
  const dispatch = useDispatch();

  const { ordersLimit } = useSelector((state) => state.ministries);

  useEffect(() => {
    dispatch(GetMinistries(ordersLimit));

    return () => {
      dispatch(reset());
    };
  }, []);

  return (
    <Layout>
      <StoriesDiv>
        <div className="stories_header">
          <h2>Project Page</h2>
          <p>We are Glad to see you again!</p>
        </div>

        <div className="stories_content">
          <MinistriesComponent />
        </div>
      </StoriesDiv>
    </Layout>
  );
};

export default Ministries;
