import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UsersComponentDiv } from './style/user.style';
import { DomPaginationState } from '../../common/DOM_Pagination_State';
import { GetAllUserDetail } from '../../../features/user/userServiceSync';
import {
  upDateCompleteCounter,
  upDateCompleteSkip,
  updateUserPrefillDetails,
} from '../../../features/user/userSlices';
import EmptyState from '../../common/emptyState';
import Skeleton from 'react-loading-skeleton';
import { Dialog } from 'primereact/dialog';
import BlockUser from './blockUser';

const UsersTable = () => {
  const dispatch = useDispatch();

  const [openBlock, setOpenBlock] = useState(false);

  const [adminDetails, setAdminDetails] = useState({});

  const {
    completeUserDetails,
    userCollectionPage,
    userCollectionLimit,
    completeUserLoader,
    searchCompleteUserDetails,
    userCollectionCounter,
  } = useSelector((state) => state.user);

  const navigate = useNavigate();

  const nextPage = () => {
    if (!completeUserLoader) {
      if (completeUserDetails?.hasNextPage) {
        dispatch(upDateCompleteSkip(userCollectionPage + 1));

        dispatch(upDateCompleteCounter(userCollectionCounter + 1));

        dispatch(
          GetAllUserDetail({
            page: userCollectionPage + 1,
            limit: userCollectionLimit,
          })
        );
      }
    }
  };

  const prevPage = () => {
    if (!completeUserLoader) {
      if (completeUserDetails?.hasPrevPage) {
        dispatch(upDateCompleteSkip(userCollectionPage - 1));

        dispatch(upDateCompleteCounter(userCollectionCounter - 1));

        dispatch(
          GetAllUserDetail({
            page: userCollectionPage - 1,
            limit: userCollectionLimit,
          })
        );
      }
    }
  };

  return (
    <>
      <Dialog
        // header="Header"
        visible={openBlock}
        style={{ borderRadius: '20px' }}
        onHide={() => setOpenBlock(false)}
        modal={true}
        closeIcon={() => {
          return;
        }}
      >
        <BlockUser setOpen={setOpenBlock} payload={adminDetails} />
      </Dialog>
      <UsersComponentDiv>
        <div className="track">
          <div className="track_header">
            <h3>Users</h3>
          </div>

          <div className="track_table">
            <div className="track_table_headers">
              <div className="track_table_headers_State SN">
                <p>S/N</p>
              </div>

              <div className="track_table_headers_State course">
                <p>Full Name</p>
              </div>

              <div className="track_table_headers_State Name">
                <p>Admin Type</p>
              </div>

              <div className="track_table_headers_State Prerequisite">
                <p>Email</p>
              </div>

              <div className="track_table_headers_State Time_status">
                <p>Phone Number</p>
              </div>

              <div className="track_table_headers_State Level">
                <p>ACTION</p>
              </div>
            </div>

            {!completeUserLoader ? (
              <>
                {searchCompleteUserDetails?.length > 0 ? (
                  searchCompleteUserDetails?.map((item, key) => (
                    <div className="track_table_body" key={key}>
                      <div className="track_table_body_State S_SN">
                        <p>{key + 1}</p>
                      </div>

                      <div className="track_table_body_State S_Course">
                        <p>{item?.fullname}</p>
                      </div>

                      <div className="track_table_body_State S_Name">
                        <p>{item?.admintype}</p>
                      </div>

                      <div className="track_table_body_State S_Prerequisite">
                        <p>{item?.email}</p>
                      </div>

                      <div className="track_table_body_State S_Time_status">
                        <p>{item?.phone}</p>
                      </div>

                      <div className="track_table_body_State S_Level">
                        <button
                          className="S_Level_Delete"
                          onClick={() => {
                            setAdminDetails(item);

                            setOpenBlock(true);
                          }}
                        >
                          Delete
                        </button>

                        <button
                          onClick={() => {
                            dispatch(updateUserPrefillDetails(item));
                            navigate(`/dashboard/create-admin`);
                          }}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  ))
                ) : completeUserDetails?.docs?.length > 0 ? (
                  completeUserDetails?.docs?.map((item, key) => (
                    <div className="track_table_body" key={key}>
                      <div className="track_table_body_State S_SN">
                        <p>
                          {key +
                            1 +
                            userCollectionCounter * userCollectionLimit}
                        </p>
                      </div>

                      <div className="track_table_body_State S_Course">
                        <p>{item?.fullname}</p>
                      </div>

                      <div className="track_table_body_State S_Name">
                        <p>{item?.admintype}</p>
                      </div>

                      <div className="track_table_body_State S_Prerequisite">
                        <p>{item?.email}</p>
                      </div>

                      <div className="track_table_body_State S_Time_status">
                        <p>{item?.phone}</p>
                      </div>

                      <div className="track_table_body_State S_Level">
                        <button
                          className="S_Level_Delete"
                          onClick={() => {
                            setAdminDetails(item);

                            setOpenBlock(true);
                          }}
                        >
                          Delete
                        </button>

                        <button
                          onClick={() => {
                            dispatch(updateUserPrefillDetails(item));
                            navigate(`/dashboard/create-admin`);
                          }}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <EmptyState />
                )}
              </>
            ) : (
              <Skeleton height={80} width={'100%'} count={10} />
            )}
          </div>

          <DomPaginationState
            onNext={nextPage}
            onPrev={prevPage}
            skip={completeUserDetails?.page}
            loading={completeUserLoader}
          />
        </div>
      </UsersComponentDiv>
    </>
  );
};

export default UsersTable;
