import { toast } from "react-toastify";
import APIs from "../apiUrl";

const TestimonyImageUpload = async (payload) => {
  try {
    const response = await APIs.post("/uploadfiles", payload);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const editATestimony = async (payload) => {
  try {
    const response = await APIs.patch("/edit_testimony", payload);

    if (response?.data?.message === "success") {
      toast("Posted successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const editATestimonyForApproval = async (payload) => {
  try {
    const response = await APIs.patch("/edit_testimony_for_Approval", payload);

    if (response?.data?.message === "success") {
      toast("Posted successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getTestimony = async () => {
  try {
    const response = await APIs.get(`/all_testimony_for_admin`);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getSingleTestimony = async (payload) => {
  try {
    const response = await APIs.get(`/get_single_testimony/${payload}`);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const TestimonyServices = {
  TestimonyImageUpload,
  editATestimony,
  editATestimonyForApproval,
  getTestimony,
  getSingleTestimony,
};

export default TestimonyServices;
