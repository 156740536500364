import React, { useEffect, useState } from 'react';
import { CreatePostComponentDiv } from './styles/createpost.style';
import { MdInsertPhoto } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Hearts, LineWave } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import MinistriesTab from './ministriesTab';
import SponserCard from './SponserCard';
import LoaderScreen from '../../../utils/loaderScreen';
import {
  EditGallery,
  GalleryUploadImage,
  GetGallery,
  GetGalleryAdv,
  GetMyCreatedGalleryAdv,
  PostGallery,
} from '../../../features/gallery/galleryServicesSync';
import {
  updateCoverPics,
  updateEditGalleryDetails,
  updateGalleryPage,
  updateMyGalleryPage,
  updateeditGalleryDetails,
} from '../../../features/gallery/gallerySlice';
import { DomPaginationState } from '../../common/DOM_Pagination_State';

const CreateGallerComponent = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [images, setImages] = useState();
  const [title, setTitle] = useState();

  const {
    contentDetails,
    isPicLoading,
    isGalleryLoading,
    galleryImageLoader,
    singleGalleryPageDetails,
    contentLoader,
    allGallery,
    myCreatedGallery,
    galleryTab,
    gallerylimit,
    galleryPage,
    paginationLoader,
    galleryMylimit,
    galleryMyPage,
  } = useSelector((state) => state.gallery);

  useEffect(() => {
    if (Object.keys(singleGalleryPageDetails).length > 0) {
      setTitle(singleGalleryPageDetails?.title);
      setImages(singleGalleryPageDetails?.cover_pic);

      dispatch(
        updateCoverPics({
          secure_url: singleGalleryPageDetails?.cover_pic,
          public_id: singleGalleryPageDetails?.cover_pic_id,
        })
      );
    }
  }, [singleGalleryPageDetails]);

  function getbase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  const handleCoverImage = (e) => {
    const image = e.target.files[0];

    if (image) {
      let promise = getbase64(image);
      promise
        .then((data) => {
          dispatch(GalleryUploadImage({ file: data }));
          return data;
        })
        .then((res) => {
          setImages(image?.name);
        });
    }

    // const reader = new FileReader();
    // reader.readAsDataURL(image);

    // reader.onloadend = () => {
    //   console.log(reader.result);
    //   dispatch(uploadImage({ file: reader.result }));
    // };
  };

  // const handleSelectPdf = async (e) => {
  //   e.preventDefault();

  //   setLoadFile(true);
  //   const files = e.target.files[0];

  //   let formData = new FormData();
  //   formData.append("file", files);

  //   console.log(files, "seasons");

  //   dispatch(uploadImage({ formData }));
  //   setLoadFile(false);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isPicLoading && !galleryImageLoader) {
      if (!title || Object.keys(contentDetails).length === 0) {
        toast('all field must be filled');
      } else {
        const payload = {
          title: title,
          cover_pic: contentDetails?.secure_url,
          cover_pic_id: contentDetails?.public_id,
        };
        await dispatch(PostGallery(payload));

        setImages('');
        setTitle('');
        dispatch(updateCoverPics({}));
      }
    } else {
      toast('cover picture is on the way');
    }
  };

  const handleEditBlog = async (e) => {
    e.preventDefault();

    console.log(
      title,
      contentDetails,
      isPicLoading,
      galleryImageLoader,
      'viewing my mwok'
    );

    if (!isPicLoading && !galleryImageLoader) {
      if (!title || Object.keys(contentDetails).length <= 0) {
        toast('all field must be filled');
      } else {
        const payload = {
          ...singleGalleryPageDetails,
          title: title,
          cover_pic: contentDetails?.secure_url,
          cover_pic_id: contentDetails?.public_id,
        };

        await dispatch(EditGallery(payload));

        setImages('');
        setTitle('');
        dispatch(updateCoverPics({}));
      }
    } else {
      toast('cover picture is on the way');
    }
  };

  const nextPage = () => {
    if (!paginationLoader) {
      if (allGallery?.hasNextPage) {
        dispatch(updateGalleryPage(galleryPage + 1));

        dispatch(
          GetGalleryAdv({
            page: galleryPage + 1,
            limit: gallerylimit,
          })
        );
      }
    }
  };

  const prevPage = () => {
    if (!paginationLoader) {
      if (allGallery?.hasPrevPage) {
        dispatch(updateGalleryPage(galleryPage - 1));

        dispatch(
          GetGalleryAdv({
            page: galleryPage - 1,
            limit: gallerylimit,
          })
        );
      }
    }
  };

  const nextPageMy = () => {
    if (!paginationLoader) {
      if (allGallery?.hasNextPage) {
        dispatch(updateMyGalleryPage(galleryMyPage + 1));

        dispatch(
          GetMyCreatedGalleryAdv({
            page: galleryMyPage + 1,
            limit: galleryMylimit,
          })
        );
      }
    }
  };

  const prevPageMy = () => {
    if (!paginationLoader) {
      if (allGallery?.hasPrevPage) {
        dispatch(updateMyGalleryPage(galleryMyPage - 1));

        dispatch(
          GetMyCreatedGalleryAdv({
            page: galleryMyPage - 1,
            limit: galleryMylimit,
          })
        );
      }
    }
  };

  return (
    <CreatePostComponentDiv>
      {Object.keys(singleGalleryPageDetails).length > 0 && (
        <div className="create__Post__container">
          <button
            className="create__Post"
            onClick={() => {
              dispatch(updateEditGalleryDetails({}));

              setTitle('');
              setImages('');
            }}
          >
            Switch To Create a Gallery
          </button>
        </div>
      )}

      <div className="createpost_component">
        <div className="createpost_component_header">
          <div className="secondLayout">
            <label>Title of Gallery</label>
            <textarea
              name="title"
              rows={5}
              value={title || ''}
              onChange={(e) => setTitle(e?.target?.value)}
            ></textarea>
          </div>

          <div className="picture_clone">
            <label>Cover Picture of Gallery</label>

            <div className="picture_clone_picker">
              <div className="picture_clone_picker_container">
                <div className="picture_clone_picker_container_icon_body">
                  <MdInsertPhoto className="picture_clone_picker_container_icon" />
                </div>

                <input
                  type="file"
                  onChange={(e) => handleCoverImage(e)}
                  className="picture_clone_picker_container_file"
                />
              </div>

              {isPicLoading ? (
                <LineWave
                  height="100"
                  width="100"
                  color="#0716A5"
                  ariaLabel="line-wave"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  firstLineColor=""
                  middleLineColor=""
                  lastLineColor=""
                />
              ) : (
                <p className="picture_clone_picker_content">
                  {images ? images : <>click the icon to select a picture</>}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="create__Post__container create__Post__con">
        {isGalleryLoading ? (
          <Hearts
            height="80"
            width="80"
            color="#0716A5"
            ariaLabel="hearts-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        ) : (
          <>
            {Object.keys(singleGalleryPageDetails).length > 0 ? (
              <button className="create__Post" onClick={handleEditBlog}>
                Edit Gallery
              </button>
            ) : (
              <button className="create__Post" onClick={handleSubmit}>
                Create Gallery
              </button>
            )}
          </>
        )}

        {Object.keys(singleGalleryPageDetails).length > 0 && (
          <button
            className="create__Post"
            onClick={() => {
              dispatch(updateEditGalleryDetails({}));

              setTitle('');
              setImages('');
            }}
          >
            Switch To Create a Gallery
          </button>
        )}
      </div>

      <div className="tabState">
        <MinistriesTab />

        {contentLoader ? (
          <LoaderScreen />
        ) : galleryTab === 'all' ? (
          <div className="state_wrapper">
            {allGallery?.docs?.map((item, key) => (
              <SponserCard
                key={key}
                image={item?.cover_pic}
                title={item?.title}
                onclick={() => {
                  dispatch(updateEditGalleryDetails(item));

                  dispatch(
                    updateCoverPics({
                      secure_url: item?.cover_pic,
                      public_id: item?.cover_pic_id,
                    })
                  );
                }}
              />
            ))}
          </div>
        ) : (
          <div className="state_wrapper">
            {myCreatedGallery?.docs?.map((item, key) => (
              <SponserCard
                key={key}
                image={item?.cover_pic}
                title={item?.title}
                onclick={() => {
                  dispatch(updateEditGalleryDetails(item));

                  dispatch(
                    updateCoverPics({
                      secure_url: item?.cover_pic,
                      public_id: item?.cover_pic_id,
                    })
                  );
                }}
              />
            ))}
          </div>
        )}

        {galleryTab === 'all'
          ? allGallery?.docs?.length > 0 && (
              <DomPaginationState
                onNext={nextPage}
                onPrev={prevPage}
                skip={allGallery?.page}
                loading={paginationLoader}
                hasNext={allGallery?.hasNextPage}
                hasPrev={allGallery?.hasPrevPage}
              />
            )
          : myCreatedGallery?.docs?.length > 0 && (
              <DomPaginationState
                onNext={nextPageMy}
                onPrev={prevPageMy}
                skip={myCreatedGallery?.page}
                loading={paginationLoader}
                hasNext={myCreatedGallery?.hasNextPage}
                hasPrev={myCreatedGallery?.hasPrevPage}
              />
            )}
      </div>
    </CreatePostComponentDiv>
  );
};

export default CreateGallerComponent;
