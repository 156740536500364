import styled from "styled-components";

export const SponsorDiv = styled.div`
  width: 32%;
  height: 380px;
  position: relative;
  border-radius: 8px;

  .sponsorCardWrapper {
    max-width: 100%;
    min-height: 40%;
    max-height: 100%;
    border-radius: 10px;
    overflow: hidden;

    .sponsorCardWrapperImage {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
    }
  }

  .editIcon {
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    padding: 24px;
    background-color: #0716a5;
    border-top-right-radius: 8px;
    cursor: pointer;

    .editIconIcon {
      color: #fff;
      font-size: 32px;
    }
  }

  &:hover {
    .editIcon {
      display: flex;
    }
  }

  .sponsor_Title {
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 14px 20px;
    background-color: #0716a5;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .sponsor_Title_state {
      font-size: 14px;
      font-weight: 500;
      color: #fff;
    }
  }
`;
