import { styled } from "styled-components";

export const StoriesCardDiv = styled.div`
  width: 100%;

  .cards_component {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70%;

    .cards_image {
      width: 35%;
      height: 160px;
      border-radius: 8px;
      overflow: hidden;
      object-fit: cover;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .cards_content {
      width: 60%;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .cards_content_header {
        display: flex;
        flex-direction: column;
        gap: 5px;
        cursor: pointer;
        text-decoration: none;

        .cards_content_header_Profile {
          display: flex;
          align-items: center;
          gap: 15px;

          img {
            width: 35px;
            height: 35px;
            border-radius: 50%;
          }

          h4 {
            font-size: 16px;
            font-weight: 500;
            color: #101828;
            margin: 0;

            @media screen and (max-width: 600px) {
              font-size: 14px;
            }
          }
        }

        .cards_content_header_content {
          display: flex;
          flex-direction: column;
          gap: 3px;

          h3 {
            font-size: 18px;
            font-weight: 700;
            margin: 0;
            color: #101828;

            @media screen and (max-width: 600px) {
              font-size: 16px;
            }
          }

          p {
            font-size: 14px;
            font-weight: 300;
            margin: 0;
            color: #101828;
            transition: all 1.5s;

            /* display: block; */
            line-height: 20px;
            max-height: 2.6rem;
            overflow: hidden !important;
            text-overflow: ellipsis;
            white-space: wrap;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            line-clamp: 2;

            @media screen and (max-width: 600px) {
              font-size: 12px;
            }
          }
        }
      }

      .cards_content_bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .cards_content_bottom_time {
          p {
            font-size: 15px;
            font-weight: 400;
            margin: 0;
            color: #101828;
          }
        }

        .cards_content_container {
          display: flex;
          align-items: center;
          gap: 20px;

          .cards_content_container_edit {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px;
            cursor: pointer;

            .cards_content_container_edit_icon {
              font-size: 24px;
              color: #101828;
            }
          }

          .cards_content_bottom_share {
            position: relative;

            .cards_content_bottom_share_icon {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 5px;
              cursor: pointer;

              .cards_content_bottom_share_icon_body {
                color: #101828;
                font-size: 24px;
              }
            }

            .social_media {
              position: absolute;
              right: 0;
              display: flex;
              z-index: 4;
              background-color: #ffffff;
              flex-direction: column;
              box-shadow: 0px 0px 8px 2px #00000025;
              padding: 8px 0px;
              border-radius: 8px;

              .social_media_container {
                display: flex;
                align-items: center;
                gap: 10px;
                padding: 10px 12px;
                cursor: pointer;
                text-decoration: none;

                .social_media_container_icon_body {
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  .social_media_container_icon {
                    color: #101828;
                    font-size: 18px;
                  }
                }

                .social_media_container_paragraph {
                  margin: 0;
                  font-size: 15px;
                  font-weight: 400;
                  color: #101828;
                }
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: 1330px) {
      width: 80%;
    }

    @media screen and (max-width: 1024px) {
      width: 90%;
    }

    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
`;

export const NotFound = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .not_found_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;

    p {
      font-size: 15px;
      font-weight: 400;
      margin: 0;
      color: #101828;
    }

    button {
      border: none;
      outline: none;
      background-color: #007bff;
      padding: 4px 49px;
      color: white;
      font-size: 16px;
      /* font-family: Roboto; */
      font-weight: 500;
      line-height: 30px;
      border-radius: 20px;
      cursor: pointer;
    }
  }
`;
