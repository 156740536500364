import React, { useEffect, useState } from 'react';
import { CreatePostComponentDiv } from './styles/createpost.style';
import { useDispatch, useSelector } from 'react-redux';
import { Hearts } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { EditFaq, PostFaq } from '../../../features/faq/faqServicesSyn';
import { updataesingleFaqPageDetails } from '../../../features/faq/faqSlices';

const CreateFAQComponent = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [answer, setAnswer] = useState();
  const [question, setQuestion] = useState();

  const { isFaqLoading, singleFaqPageDetails } = useSelector(
    (state) => state.faq
  );

  useEffect(() => {
    if (Object.keys(singleFaqPageDetails).length > 0) {
      setQuestion(singleFaqPageDetails?.question);
      setAnswer(singleFaqPageDetails?.answer);
    }
  }, [singleFaqPageDetails]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!question || !answer) {
      toast('all field must be filled');
    } else {
      const payload = {
        question: question?.trim(),
        answer: answer?.trim(),
      };
      await dispatch(PostFaq(payload));

      setAnswer('');
      setQuestion('');

      navigate('/dashboard/view_faqs');
    }
  };

  const handleEditBlog = async (e) => {
    e.preventDefault();

    if (!question || !answer) {
      toast('all field must be filled');
    } else {
      const payload = {
        ...singleFaqPageDetails,
        question: question,
        answer: answer,
      };

      await dispatch(EditFaq(payload));

      dispatch(updataesingleFaqPageDetails({}));

      setQuestion('');
      setAnswer('');
      navigate('/dashboard/view_faqs');
    }
  };

  return (
    <CreatePostComponentDiv>
      {Object.keys(singleFaqPageDetails).length > 0 && (
        <div className="create__Post__container">
          <button
            className="create__Post"
            onClick={() => {
              dispatch(updataesingleFaqPageDetails({}));

              setQuestion('');
              setAnswer('');
            }}
          >
            Switch To Create Faq
          </button>
        </div>
      )}

      <div className="createpost_component">
        <div className="createpost_component_header">
          <div className="secondLayout">
            <label>Question on Faq</label>
            <textarea
              name="question"
              rows={5}
              value={question || ''}
              onChange={(e) => setQuestion(e?.target?.value)}
            ></textarea>
          </div>

          <div className="secondLayout">
            <label>Answer on Faq</label>
            <textarea
              name="answer"
              rows={5}
              value={answer || ''}
              onChange={(e) => setAnswer(e?.target?.value)}
            ></textarea>
          </div>
        </div>
      </div>

      <div className="create__Post__container create__Post__con">
        {isFaqLoading ? (
          <Hearts
            height="80"
            width="80"
            color="#0716A5"
            ariaLabel="hearts-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        ) : (
          <>
            {Object.keys(singleFaqPageDetails).length > 0 ? (
              <button
                disabled={isFaqLoading}
                className="create__Post"
                onClick={handleEditBlog}
              >
                Edit Faq
              </button>
            ) : (
              <button
                disabled={isFaqLoading}
                className="create__Post"
                onClick={handleSubmit}
              >
                Create Faq
              </button>
            )}
          </>
        )}

        {Object.keys(singleFaqPageDetails).length > 0 && (
          <button
            className="create__Post"
            onClick={() => {
              dispatch(updataesingleFaqPageDetails({}));

              setQuestion('');
              setAnswer('');
            }}
          >
            Switch To Create Faq
          </button>
        )}
      </div>
    </CreatePostComponentDiv>
  );
};

export default CreateFAQComponent;
