import { toast } from 'react-toastify';
import APIs from '../apiUrl';

const getUserDetails = async () => {
  try {
    const response = await APIs.get('/get_admin_user');

    if (response?.data?.message === 'success') {
      toast('fetch user successfully');
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const editUserDetails = async (payload) => {
  try {
    const response = await APIs.patch('/edit_user', payload);

    if (response?.data?.message === 'success') {
      toast('Edited successfully');
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const generalTotalCount = async () => {
  try {
    const response = await APIs.get('/get_total_count');

    if (response?.data?.message === 'success') {
      toast('successfully');
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const editUserPassword = async (payload) => {
  try {
    const response = await APIs.patch('/edit_user_password', payload);

    if (response?.data?.message === 'success') {
      toast('Edited successfully');
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getAllUserDetails = async (payload) => {
  try {
    const response = await APIs.get(
      `/get_all_registered_users/${payload?.page}/${payload?.limit}`
    );

    console.log(response, 'sees');

    if (response?.data?.message === 'success') {
      toast('successfully');
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getOtherUserDetails = async (payload) => {
  try {
    const response = await APIs.get(`/get_other_user/${payload}`);

    if (response?.data?.message === 'success') {
      toast('successfully');
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const editUserFromAdmin = async (payload) => {
  try {
    const response = await APIs.patch(
      `/edit_user_from_admin/${payload?._id}`,
      payload
    );

    if (response?.data?.message === 'success') {
      toast('successfully');
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const DeleteAdmin = async (payload) => {
  try {
    const response = await APIs.post(`/delete_user`, payload);

    if (response?.data?.message === 'success') {
      toast('successfully');
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const searchUserDetails = async (payload, limit) => {
  try {
    const response = await APIs.get(
      `/search_for_user/${payload?.username}/${payload?.limit}`
    );

    if (response?.data?.message === 'success') {
      toast('successfully');
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const UserServices = {
  getUserDetails,
  editUserDetails,
  editUserPassword,
  generalTotalCount,
  getAllUserDetails,
  getOtherUserDetails,
  editUserFromAdmin,
  searchUserDetails,
  DeleteAdmin,
};

export default UserServices;
