import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SermonComponentDiv } from './styles/ministriescomponent.styles';
import InfiniteScroll from 'react-infinite-scroller';
import {
  GetMySermonsAdvanced,
  GetSermonsAdvanced,
} from '../../../features/sermon/sermonServicesSync';
import SermonTabs from './sermonTabs';
import { InfinitySpin } from 'react-loader-spinner';
import SermonCard from './sermonCard';
import MiniLoader from '../../../utils/miniLoader';

const AllSermonsComponents = () => {
  const dispatch = useDispatch();

  const {
    sermonArticleDetails,
    sermonMyArticleDetails,
    sermonPageLoad,
    orderPagination,
    myPagination,
    ordersLimit,
    mylimit,
    mySermonCount,
    orderSermonCount,
    sermonTab,
    averageLimit,
  } = useSelector((state) => state.sermon);

  return (
    <SermonComponentDiv>
      <div>
        <SermonTabs />
      </div>

      {!sermonPageLoad ? (
        <>
          {sermonTab === 'all' ? (
            <InfiniteScroll
              pageStart={orderPagination}
              loadMore={async () => {
                if (orderSermonCount > sermonArticleDetails.length) {
                  dispatch(GetSermonsAdvanced(ordersLimit + averageLimit));
                }
              }}
              hasMore={true || false}
              loader={
                orderSermonCount > sermonArticleDetails.length && (
                  <InfinitySpin width="200" color="#0716A6" />
                )
              }
            >
              <div className="stories_card">
                <SermonCard cardArry={sermonArticleDetails} />
              </div>
            </InfiniteScroll>
          ) : (
            <InfiniteScroll
              pageStart={myPagination}
              loadMore={async () => {
                if (mySermonCount > sermonMyArticleDetails.length) {
                  dispatch(GetMySermonsAdvanced(mylimit + averageLimit));
                }
              }}
              hasMore={true || false}
              loader={
                mySermonCount > sermonMyArticleDetails.length && (
                  <InfinitySpin width="200" color="#0716A6" />
                )
              }
            >
              <div className="stories_card">
                <SermonCard cardArry={sermonMyArticleDetails} />
              </div>
            </InfiniteScroll>
          )}
        </>
      ) : (
        <MiniLoader />
      )}
    </SermonComponentDiv>
  );
};

export default AllSermonsComponents;
