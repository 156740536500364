import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import FaqServices from './faqServices';

export const PostFaq = createAsyncThunk('faq/postFaq', async (payload) => {
  try {
    let response = await FaqServices.postFaq(payload);

    return response;
  } catch (error) {
    toast(error.message);
    throw error;
  }
});

export const EditFaq = createAsyncThunk('faq/editFaq', async (payload) => {
  try {
    let response = await FaqServices.editAFaq(payload);

    return response;
  } catch (error) {
    toast(error.message);
    throw error;
  }
});

export const GetFaq = createAsyncThunk('faq/getFaq', async (payload) => {
  try {
    let response = await FaqServices.getFaq(payload);

    return response;
  } catch (error) {
    toast(error.message);
    throw error;
  }
});

export const GetFaqAdvanced = createAsyncThunk(
  'faq/getAdvFaq',
  async (payload) => {
    try {
      let response = await FaqServices.getFaq(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetMyFaq = createAsyncThunk('faq/getmyFaq', async (payload) => {
  try {
    let response = await FaqServices.getMyFaq(payload);

    return response;
  } catch (error) {
    toast(error.message);
    throw error;
  }
});

export const GetMyFaqAdvanced = createAsyncThunk(
  'faq/getmyAdvFaq',
  async (payload) => {
    try {
      let response = await FaqServices.getMyFaq(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetSingleFaq = createAsyncThunk(
  'faq/getSingleFaq',
  async (payload) => {
    try {
      let response = await FaqServices.getSingleFaq(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);
