import React from 'react';
import Layout from '../../utils/layout';
import { MessageDiv } from './styles/message.style';
import CreateCharityComponent from '../../components/DashboardComponent/createCharity';

const CreateCharity = () => {
  return (
    <Layout>
      <MessageDiv>
        <div className="message_header">
          <h2>Create Donation</h2>
        </div>
        <div className="message_chat">
          <CreateCharityComponent />
        </div>
      </MessageDiv>
    </Layout>
  );
};

export default CreateCharity;
