import React, { memo } from "react";
import UserProfileContact from "./userProfileContact";
import UserProfileHeader from "./userProfileHeader";
import UserProfileMoreInformation from "./userProfileMoreInformation";
import { UserProfileMainDiv } from "./styles/userProfileMain.style";
import { useSelector } from "react-redux";
import UserProfileSocialMedia from "./userProfileSocialMedia";
import BlockUser from "./blockUser";
import { useState } from "react";
import { Dialog } from "primereact/dialog";

const CserProfileCompontent = () => {
  const { singleMinisterPageDetails } = useSelector((state) => state.minister);

  const [openBlock, setOpenBlock] = useState(false);

  return (
    <>
      <Dialog
        // header="Header"
        visible={openBlock}
        style={{ borderRadius: "20px" }}
        onHide={() => setOpenBlock(false)}
        modal={true}
        closeIcon={() => {
          return;
        }}
      >
        <BlockUser setOpen={setOpenBlock} payload={singleMinisterPageDetails} />
      </Dialog>
      <UserProfileMainDiv>
        <div className="userAdminWrapperHeader">
          <UserProfileHeader
            firstName={singleMinisterPageDetails?.firstname}
            lastName={singleMinisterPageDetails?.lastname}
            jobTitle={singleMinisterPageDetails?.office}
            cover_pic={singleMinisterPageDetails?.cover_pic}
            status={singleMinisterPageDetails?.block}
            setOpen={setOpenBlock}
          />
        </div>

        <div className="userAdminWrapperBody">
          <div className="userAdminWrapperBodyTwo">
            <UserProfileContact
              email={singleMinisterPageDetails?.email}
              phone={singleMinisterPageDetails?.phone_number}
              address={singleMinisterPageDetails?.address}
            />
          </div>

          <div className="userAdminWrapperBodyOne">
            <UserProfileMoreInformation
              country={singleMinisterPageDetails?.country}
              state={singleMinisterPageDetails?.state}
              gender={singleMinisterPageDetails?.gender}
              about={singleMinisterPageDetails?.about}
            />
          </div>

          <div className="userAdminWrapperBodyOne">
            <UserProfileSocialMedia
              facebook={singleMinisterPageDetails?.facebook}
              instagram={singleMinisterPageDetails?.instagram}
              linkedin={singleMinisterPageDetails?.linkedln}
              twitter={singleMinisterPageDetails?.twitter}
            />
          </div>
        </div>
      </UserProfileMainDiv>
    </>
  );
};

export default memo(CserProfileCompontent);
