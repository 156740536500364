import React, { useRef, useState } from 'react';
import CardImage from '../../../Assets/program.svg';
import ProfilePic from '../../../Assets/images/avatar-profile-photo.svg';
import { FiEdit, FiShare2 } from 'react-icons/fi';
import { NotFound, StoriesCardDiv } from './styles/storiesCard.style';
import HTMLReactParser from 'html-react-parser';
import moment from 'moment';
import { BsLinkedin, BsFacebook, BsTwitter, BsWhatsapp } from 'react-icons/bs';
import { BiSolidCopy } from 'react-icons/bi';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { NavLink, useNavigate } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { baseUrl } from '../../../utils/baseUrl';
import { useDispatch } from 'react-redux';
import { updateSingleBlogDetails } from '../../../features/bloggers/bloggersSlices';
import LazyLoad from 'react-lazyload';
import { updataesingleFaqPageDetails } from '../../../features/faq/faqSlices';

const StoriesCard = ({ cardArry }) => {
  const dropRef = useRef();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [selectedId, setSelectedId] = useState();

  useOnClickOutside(dropRef, () => setSelectedId());
  return (
    <>
      {cardArry?.length > 0 ? (
        cardArry?.map((item, key) => (
          <StoriesCardDiv key={key}>
            <div className="cards_component">
              <div className="cards_content">
                <NavLink
                  to={`${baseUrl}/dashboard/faq/${item?._id}`}
                  target="blank"
                  className="cards_content_header"
                >
                  <div className="cards_content_header_Profile">
                    <LazyLoad>
                      <img
                        src={item?.profile_pic ? item?.profile_pic : ProfilePic}
                      />
                    </LazyLoad>
                    <h4>{item?.fullname}</h4>
                  </div>
                  <div className="cards_content_header_content">
                    <h3>{item?.question}</h3>

                    <p>{item?.answer}</p>
                  </div>
                </NavLink>
                <div className="cards_content_bottom">
                  <div className="cards_content_bottom_time">
                    <p>{`${moment(item?.date).format(
                      'MMM DD, YYYY hh:mm'
                    )}`}</p>
                  </div>

                  <div className="cards_content_container">
                    <div
                      className="cards_content_container_edit"
                      onClick={() => {
                        dispatch(updataesingleFaqPageDetails(item));

                        navigate('/dashboard/faq');
                      }}
                    >
                      <FiEdit className="cards_content_container_edit_icon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </StoriesCardDiv>
        ))
      ) : (
        <NotFound>
          <div className="not_found_wrapper">
            <p>No Article found</p>
            <button>Create one</button>
          </div>
        </NotFound>
      )}
    </>
  );
};

export default StoriesCard;
