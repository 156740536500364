import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import TestimonyServices from "./testimonyServices";

export const uploadImageTestimony = createAsyncThunk(
  "testimony/uploadimage",
  async (payload) => {
    try {
      let response = await TestimonyServices.TestimonyImageUpload(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const EditTestimony = createAsyncThunk(
  "testimony/editVolunteers",
  async (payload) => {
    try {
      let response = await TestimonyServices.editATestimony(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const EditTestimonyForApproval = createAsyncThunk(
  "testimony/editTestimonyForApproval",
  async (payload) => {
    try {
      let response = await TestimonyServices.editATestimonyForApproval(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const EditTestimonyAdvanced = createAsyncThunk(
  "testimony/editTestimonyAdv",
  async (payload) => {
    try {
      let response = await TestimonyServices.editATestimony(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetTestimony = createAsyncThunk(
  "testimony/getTestimony",
  async (payload) => {
    try {
      let response = await TestimonyServices.getTestimony(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetSingleTestimony = createAsyncThunk(
  "testimony/getSingleTestimony",
  async (payload) => {
    try {
      let response = await TestimonyServices.getSingleTestimony(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);
