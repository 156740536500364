import React, { useState } from 'react';
import { TaskTabDiv } from './styles.js/taskTab.styles';
import { FiChevronDown } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import {
  AllTransaction,
  AllTransactionStatus,
} from '../../../features/payment/paymentServicesSync';
import { updateStatus } from '../../../features/payment/paymentSlice';

const dropdownArray = [
  { title: 'All' },
  { title: 'success' },
  { title: 'failed' },
  { title: 'abandoned' },
];

const TaskTab = () => {
  const dispatch = useDispatch();
  const [selectTabs, setSelectTabs] = useState('');
  const [showTab, setShowTab] = useState(false);

  const { mylimit, transactionLoader } = useSelector((state) => state.payment);

  return (
    <TaskTabDiv>
      <div className="taskTabs" onClick={() => setShowTab(!showTab)}>
        <p>{selectTabs ? selectTabs : 'Select Payment Status'}</p>

        <div className="taskTabs_space">
          <FiChevronDown className="taskTabs_space_coin" />
        </div>
      </div>

      {showTab && (
        <div className="task_tab_dropdown">
          {dropdownArray?.map((item, key) => (
            <div
              key={key}
              className={`task_tab_dropdown_items ${
                selectTabs === item?.title ? 'Selectedtabs' : ''
              }`}
              onClick={() => {
                if (!transactionLoader) {
                  if (item?.title?.toLowerCase() === 'all') {
                    dispatch(AllTransaction({ page: 1, limit: mylimit }));
                  } else {
                    dispatch(
                      AllTransactionStatus({
                        page: 1,
                        limit: mylimit,
                        status: item?.title,
                      })
                    );
                  }

                  setShowTab(false);
                  dispatch(updateStatus(item?.title));
                  setSelectTabs(item?.title);
                }
              }}
            >
              <p>{item?.title}</p>
            </div>
          ))}
        </div>
      )}
    </TaskTabDiv>
  );
};

export default TaskTab;
