import React from "react";
import Layout from "../../utils/layout";
import { ProfileDiv } from "./styles/message.style";
import VolunteerComponents from "../../components/DashboardComponent/volunteersComponents";

const CreateVolunteer = () => {
  return (
    <Layout>
      <ProfileDiv>
        <div className="profile_header">
          <h2>Create Volunteers</h2>
        </div>

        <div className="profile_content">
          <VolunteerComponents />
        </div>
      </ProfileDiv>
    </Layout>
  );
};

export default CreateVolunteer;
