import { styled } from "styled-components";

export const TrackDiv = styled.div`
  margin: 30px 0px;
  padding: 15px 0px;

  .track {
    .track_header {
      margin-bottom: 10px;
      h3 {
        margin: 0;
        font-size: 18px;
        font-weight: 600;
        color: #101828;
      }
    }

    .track_table {
      .track_table_headers {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #d0d5ddf8;
        width: 100%;

        .track_table_headers_State {
          padding: 10px;
          width: 23%;
          text-align: left;

          p {
            margin: 0;
            font-size: 16px;
            font-weight: 500;
            color: #101828;
          }

          &.Level {
            text-align: center;
          }
        }

        @media screen and (max-width: 640px) {
          display: none;
        }
      }

      .track_table_body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #d0d5ddf8;
        margin: 10px 0px;

        .track_table_body_State {
          padding: 8px 8px;
          width: 23%;
          text-align: left;

          p {
            margin: 0;
            font-size: 14px;
            font-weight: 400;
            color: #101828;
          }

          &.S_Level {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;

            @media screen and (max-width: 640px) {
              text-align: unset;
              display: unset;
            }
          }

          .track_table_body_State_S_Level_Selection {
            font-size: 20px;
            color: #34d07f;
          }

          .track_table_body_State_S_Level {
            font-size: 20px;
            color: #d0d5dd;
          }

          button {
            outline: none;
            border: none;
            color: #2fbd83;
            font-size: 16px;
            font-weight: 500;
            cursor: pointer;
            background-color: #ecfdf3;
            padding: 8px 18px;
          }

          .edit {
            outline: none;
            border: none;
            color: #f4be37;
            font-size: 16px;
            font-weight: 500;
            cursor: pointer;
            background-color: #faf2cd;
            padding: 8px 18px;
          }

          @media screen and (max-width: 640px) {
            width: 40%;
          }
        }

        @media screen and (max-width: 640px) {
          flex-wrap: wrap;
          border: 1px solid #d0d5ddf8;
          border-radius: 8px;
        }
      }
    }
  }
`;
