import { toast } from "react-toastify";
import APIs from "../apiUrl";

const SermonImageUpload = async (payload) => {
  try {
    const response = await APIs.post("/uploadfiles", payload);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const postSermons = async (payload) => {
  try {
    const response = await APIs.post("/create_sermon", payload);

    if (response?.data?.message === "success") {
      toast("Posted successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const uploadPdfFile = async (content) => {
  try {
    console.log(content, "solving the working aspect");

    const response = await APIs.post(`/upload_pdf`, content);

    if (response?.data?.message === "success") {
      return response.data;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const editASermon = async (payload) => {
  try {
    const response = await APIs.patch("/edit_sermons", payload);

    if (response?.data?.message === "success") {
      toast("Posted successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getSermons = async (payload) => {
  try {
    const response = await APIs.get(`/get_sermons/${payload}`);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getMySermons = async (payload) => {
  try {
    const response = await APIs.get(`/get_my_sermons/${payload}`);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const SermonServices = {
  SermonImageUpload,
  postSermons,
  uploadPdfFile,
  getSermons,
  getMySermons,
  editASermon,
};

export default SermonServices;
