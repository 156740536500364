import React, { useEffect } from 'react';
import Layout from '../../utils/layout';
import { StoriesDiv } from './styles/message.style';
import { useDispatch, useSelector } from 'react-redux';
import { reset } from '../../features/bloggers/bloggersSlices';
import ViewFaqComponent from '../../components/DashboardComponent/viewFaqCoponents';
import { GetFaq } from '../../features/faq/faqServicesSyn';

const ViewFaq = () => {
  const dispatch = useDispatch();

  const { ordersLimit } = useSelector((state) => state.faq);

  useEffect(() => {
    dispatch(GetFaq(ordersLimit));

    return () => {
      dispatch(reset());
    };
  }, []);

  return (
    <Layout>
      <StoriesDiv>
        <div className="stories_header">
          <h2>Faq Page</h2>
          <p>We are Glad to see you again!</p>
        </div>

        <div className="stories_content">
          <ViewFaqComponent />
        </div>
      </StoriesDiv>
    </Layout>
  );
};

export default ViewFaq;
