import React from "react";
import { SingleUserTableDiv } from "./style/singleUserTable.style";
import EmptyState from "../../common/emptyState";

const SingleUserTable = () => {
  return (
    <SingleUserTableDiv>
      <div className="track">
        <div className="track_header">
          <h3>Tasks</h3>
        </div>

        <div className="track_table">
          <div className="track_table_headers">
            <div className="track_table_headers_State SN">
              <p>S/N</p>
            </div>

            <div className="track_table_headers_State course">
              <p>COURSE</p>
            </div>

            <div className="track_table_headers_State Name">
              <p>TASK TITLE</p>
            </div>

            <div className="track_table_headers_State Description">
              <p>DESCRIPTION</p>
            </div>

            <div className="track_table_headers_State Prerequisite">
              <p>PREREQUISITE</p>
            </div>

            <div className="track_table_headers_State Time_status">
              <p>TIME STATUS</p>
            </div>

            <div className="track_table_headers_State status">
              <p>STATUS</p>
            </div>

            <div className="track_table_headers_State Level">
              <p>ACTION</p>
            </div>
          </div>

          {/* {allAssessment?.map((item, key) => (
          <div className="track_table_body" key={key}>
            <div className="track_table_body_State S_SN">
              <p>{key + 1}</p>
            </div>

            <div className="track_table_body_State S_Course">
              <p>{item?.course}</p>
            </div>

            <div className="track_table_body_State S_Name">
              <p>{item?.title}</p>
            </div>

            <div className="track_table_body_State S_Description">
              <p>{item?.description}</p>
            </div>

            <div className="track_table_body_State S_Prerequisite">
              <p>{item?.prerequisite}</p>
            </div>

            <div className="track_table_body_State S_Time_status">
              <p>
                <Countdown
                  date={item?.time_status}
                  renderer={({ days, hours, minutes, seconds }) => (
                    <span>{`${days}:${hours}:${minutes}:${seconds}`}</span>
                  )}
                />
              </p>
            </div>

            <div className="track_table_body_State S_Status">
              <p>{item?.status}</p>
            </div>

            <div className="track_table_body_State S_Level">
              <button
                onClick={() => navigate(`/dashboard/task/${item?._id}`)}
              >
                View
              </button>
            </div>
          </div>
        ))} */}

          <EmptyState />
        </div>
      </div>
    </SingleUserTableDiv>
  );
};

export default SingleUserTable;
