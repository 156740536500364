import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  showNavBar: false,
};

export const utiliesSlice = createSlice({
  name: "utilities",
  initialState,
  reducers: {
    utilitiesReset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
      state.showNavBar = false;
    },

    ResetNavBar: (state, action) => {
      state.showNavBar = action.payload;
    },
  },
});

export const { utilitiesReset, ResetNavBar } = utiliesSlice.actions;
export default utiliesSlice.reducer;
