import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import useWindowDimensions from "../../../hooks/useWindowDimension";
import {
  PaginationDomDiv,
  PaginationMobile,
} from "./styles/paginationStyles.style";
import { InfinitySpin } from "react-loader-spinner";

const DomPaginationState = ({ onPrev, onNext, skip, loading }) => {
  const { width } = useWindowDimensions();
  return width <= 650 ? (
    <PaginationMobile className="__mobile__pagination">
      <div className="__left" onClick={() => onPrev()}>
        <AiOutlineArrowLeft size={20} />
      </div>
      <span className="__current">Page {skip}</span>
      <div className="__right" onClick={() => onNext()}>
        <AiOutlineArrowRight size={20} />
      </div>
    </PaginationMobile>
  ) : (
    <PaginationDomDiv className="notification__paginationWrapper">
      <div className="newTable__paginationNumber">Page {skip}</div>
      <div className="newTable__paginationNavigation">
        {!loading ? (
          <button
            className="newTable__paginationNavigation__button"
            onClick={() => onPrev()}
          >
            previous
          </button>
        ) : (
          <InfinitySpin width="100" color="#4fa94d" />
        )}

        {!loading ? (
          <button
            className="newTable__paginationNavigation__button"
            onClick={() => onNext()}
          >
            next
          </button>
        ) : (
          <InfinitySpin width="100" color="#4fa94d" />
        )}
      </div>
    </PaginationDomDiv>
  );
};

export { DomPaginationState };
