import React, { useEffect } from 'react';
import Layout from '../../utils/layout';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import LoaderScreen from '../../utils/loaderScreen';
import ShowFaq from '../../components/DashboardComponent/showFaq';
import { GetSingleFaq } from '../../features/faq/faqServicesSyn';

const ShowViewFaq = () => {
  const dispatch = useDispatch();

  const { singleFaqPageLoader } = useSelector((state) => state.faq);

  const { faqId } = useParams();

  useEffect(() => {
    dispatch(GetSingleFaq(faqId));
  }, [faqId]);

  return (
    <Layout>{singleFaqPageLoader ? <LoaderScreen /> : <ShowFaq />}</Layout>
  );
};

export default ShowViewFaq;
