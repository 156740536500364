import { toast } from 'react-toastify';
import APIs from '../apiUrl';

const getAllTransaction = async (payload) => {
  try {
    const response = await APIs.get(
      `/transactions/${payload.page}/${payload.limit}`
    );

    if (response?.data?.message === 'success') {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getAllTransactionStatus = async (payload) => {
  try {
    const response = await APIs.get(
      `/transactions/status/${payload.page}/${payload.limit}/${payload.status}`
    );

    if (response?.data?.message === 'success') {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const PaymentServices = { getAllTransaction, getAllTransactionStatus };

export default PaymentServices;
