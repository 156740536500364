import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import SocialSevices from "./socialServices";

export const CreateSocial = createAsyncThunk(
  "social/create_social",
  async (payload) => {
    try {
      let response = await SocialSevices.createSocial(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetSocial = createAsyncThunk("social/get_social", async () => {
  try {
    let response = await SocialSevices.getSocial();

    return response;
  } catch (error) {
    toast(error.message);
    throw error;
  }
});
