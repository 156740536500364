import { toast } from "react-toastify";
import APIs from "../apiUrl";

const BloggerImageUpload = async (payload) => {
  try {
    const response = await APIs.post("/uploadfiles", payload);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const postBlogger = async (payload) => {
  try {
    const response = await APIs.post("/create_blog", payload);

    if (response?.data?.message === "success") {
      toast("Posted successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const editABlogger = async (payload) => {
  try {
    const response = await APIs.patch("/edit_blog", payload);

    if (response?.data?.message === "success") {
      toast("Posted successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getBlogger = async (payload) => {
  try {
    const response = await APIs.get(`/get_blogs/${payload}`);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getMyBlogger = async (payload) => {
  try {
    const response = await APIs.get(`/get_my_blogs/${payload}`);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getAllBlogger = async (payload) => {
  try {
    const response = await APIs.get(`/get_blogs/${payload}`);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getSingleBlogger = async (payload) => {
  try {
    const response = await APIs.get(`/get_single_post/${payload}`);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getBloggerComment = async (payload) => {
  try {
    const response = await APIs.get(`/single_post_comment/${payload}`);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const createComment = async (payload) => {
  try {
    const response = await APIs.post(`/comment/${payload.id}`, payload);

    if (response?.data?.message === "success") {
      toast("successfully commented");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const BloggersServices = {
  BloggerImageUpload,
  postBlogger,
  getBlogger,
  getMyBlogger,
  getSingleBlogger,
  getAllBlogger,
  editABlogger,
  getBloggerComment,
  createComment,
};

export default BloggersServices;
