import React, { useRef, useState } from "react";
import ProfilePic from "../../../Assets/images/avatar-profile-photo.svg";
import CardImage from "../../../Assets/program.svg";
import { FiShare2 } from "react-icons/fi";
import { ShowViewDiv } from "./styles/showView.style";
import { useDispatch, useSelector } from "react-redux";
import HTMLReactParser from "html-react-parser";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { BsLinkedin, BsFacebook, BsTwitter, BsWhatsapp } from "react-icons/bs";
import { BiSolidCopy } from "react-icons/bi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { baseUrl } from "../../../utils/baseUrl";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import { FaCommentDots } from "react-icons/fa";
import Image from "../../../Assets/images/avatar-profile-photo.png";
import ThreeLoader, { InfinitySpinner } from "../../common/threeLoader";
import { PostComment } from "../../../features/bloggers/bloggersServicesSync";

const ShowView = () => {
  const dropRef = useRef();

  const dispatch = useDispatch();

  const [selectedId, setSelectedId] = useState();

  const [showComment, setShowComment] = useState(false);

  const [comment, setComment] = useState("");

  const { userDetails } = useSelector((state) => state.user);

  const { singleStoryPageDetails, allBlogComment, commentLoader } = useSelector(
    (state) => state.blogger
  );

  useOnClickOutside(dropRef, () => setSelectedId());

  const HandleShowComment = () => {
    setShowComment(!showComment);
  };

  const HandleComment = async () => {
    if (!comment) {
      toast("Comment Field must be field");

      return;
    }

    const payload = { comment: comment, id: singleStoryPageDetails?._id };

    await dispatch(PostComment(payload));

    setComment("");
  };

  const HandleChangeComment = (e) => {
    const { value } = e.target;

    setComment(value);
  };

  return (
    <ShowViewDiv>
      <div className="showView_header">
        <div className="showView_profile_details">
          <img src={ProfilePic} />

          <div className="showView_profile_details_name">
            <h4>{singleStoryPageDetails?.title}</h4>
            <p>
              {moment(singleStoryPageDetails?.date).format(
                "MMM DD, YYYY hh:mm"
              )}
            </p>
          </div>
        </div>
        <div className="cards_content_bottom_share">
          <div
            className="cards_content_bottom_share_icon"
            onClick={() => setSelectedId(singleStoryPageDetails?._id)}
          >
            <FiShare2 className="cards_content_bottom_share_icon_body" />
          </div>

          {selectedId && (
            <div className="social_media" ref={dropRef}>
              <CopyToClipboard
                text={`${baseUrl}/${singleStoryPageDetails?._id}`}
                onCopy={() => toast("successfully copied")}
              >
                <div className="social_media_container">
                  <div className="social_media_container_icon_body">
                    <BiSolidCopy className="social_media_container_icon" />
                  </div>
                  <p className="social_media_container_paragraph">CopyLink</p>
                </div>
              </CopyToClipboard>

              <NavLink
                to={`https://www.linkedin.com/shareArticle?url=${baseUrl}/${singleStoryPageDetails._id}&title=${singleStoryPageDetails?.title}`}
                target="blank"
                className="social_media_container"
              >
                <div className="social_media_container_icon_body">
                  <BsLinkedin className="social_media_container_icon" />
                </div>
                <p className="social_media_container_paragraph">Linkedin</p>
              </NavLink>

              <NavLink
                to={`https://www.facebook.com/sharer.php?u=${baseUrl}/${singleStoryPageDetails._id}`}
                target="blank"
                className="social_media_container"
              >
                <div className="social_media_container_icon_body">
                  <BsFacebook className="social_media_container_icon" />
                </div>
                <p className="social_media_container_paragraph">FaceBook</p>
              </NavLink>

              <NavLink
                to={`https://twitter.com/share?url=${baseUrl}/${singleStoryPageDetails._id}&text=${singleStoryPageDetails?.title}`}
                target="blank"
                className="social_media_container"
              >
                <div className="social_media_container_icon_body">
                  <BsTwitter className="social_media_container_icon" />
                </div>
                <p className="social_media_container_paragraph">Twitter</p>
              </NavLink>

              <NavLink
                to={`https://api.whatsapp.com/send?text=${singleStoryPageDetails?.title} ${baseUrl}/${singleStoryPageDetails._id}`}
                target="blank"
                className="social_media_container"
              >
                <div className="social_media_container_icon_body">
                  <BsWhatsapp className="social_media_container_icon" />
                </div>
                <p className="social_media_container_paragraph">Whatsapp</p>
              </NavLink>
            </div>
          )}
        </div>
      </div>
      <div className="showView_Wrapper_title">
        <div className="showView_articleWrapper__titleDetails">
          <h2 className="showView_articleWrapper__titleDetailsItem">
            {singleStoryPageDetails?.title}
          </h2>
        </div>
        <div className="showView_Wrapper__titleImageWrapper">
          <img
            src={
              singleStoryPageDetails?.cover_pic
                ? `${singleStoryPageDetails?.cover_pic}`
                : CardImage
            }
            className="showView_articleWrapper__titleImageWrapperItem"
          />
        </div>
      </div>
      <div className="showView_articleWrapper__articleDetails">
        <p className="showView_articleWrapper__articleDetailsItem">
          {singleStoryPageDetails?.article &&
            HTMLReactParser(HTMLReactParser(singleStoryPageDetails?.article))}
        </p>
      </div>

      <div>
        <div className={"articleWrapper__SocialMedai"}>
          <div className={"articleWrapper__SocialMedaiDetails"}>
            <div
              className={"articleWrapper__SocialMedaiDetailsLike"}
              onClick={HandleShowComment}
            >
              <div className={"articleWrapper__SocialMedaiDetailsLikeIconBody"}>
                <FaCommentDots
                  className={"articleWrapper__SocialMedaiDetailsLikeIcon"}
                />
              </div>
              <div className={"articleWrapper__SocialMedaiDetailsLikeContent"}>
                <p>{allBlogComment?.count}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="articleWrapper__comment_container">
          {showComment && (
            <div className="articleWrapper__comment">
              <div className="articleWrapper__commentSection">
                <div className="articleWrapper__commentSectionImageWrapper">
                  <img
                    src={Image}
                    alt="state"
                    className="articleWrapper__commentSectionImage"
                  />
                </div>
                <div className="articleWrapper__commentSectionStage">
                  <div className="articleWrapper__commentSectionStageTitle">
                    <p>{userDetails?.username}</p>
                  </div>
                  <textarea
                    value={comment || ""}
                    onChange={HandleChangeComment}
                    name="comment"
                    placeholder="Comment..."
                    className="articleWrapper__commentSectionStageTextarea"
                  />
                  <div className="articleWrapper__commentSectionStageButton">
                    {commentLoader ? (
                      <InfinitySpinner />
                    ) : (
                      <button onClick={HandleComment}>Comment</button>
                    )}
                  </div>
                </div>
              </div>

              <div className="articleWrapper__commentTextSectionWrapper">
                {allBlogComment?.data?.map((item, key) => (
                  <div className="articleWrapper__commentTextSection" key={key}>
                    <div className="articleWrapper__commentTextSectionWImageWrapper">
                      <img
                        src={Image}
                        alt="state"
                        className="articleWrapper__commentTextSectionWImage"
                      />
                    </div>
                    <div className="articleWrapper__commentTextSectionText">
                      <div className="articleWrapper__commentTextSectionTextTitle">
                        <p>
                          {`${item?.username} `}
                          <span className="articleWrapper__commentTextSectionTextTitleSpan">
                            {`on ${moment(item?.date).format(
                              "MMM DD, YYYY hh:mm"
                            )}`}
                          </span>
                        </p>
                      </div>
                      <div className="articleWrapper__commentTextSectionTextBody">
                        <p>{item?.comment}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </ShowViewDiv>
  );
};

export default ShowView;
