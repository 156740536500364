import React, { useEffect } from "react";
import Layout from "../../utils/layout";
import { CreatePostDiv } from "./styles/message.style";
import CreateSponsorComponent from "../../components/DashboardComponent/createSponsorComponent";
import { useDispatch } from "react-redux";
import { GetSponsors } from "../../features/sponsor/sponsorServicesSync";

const CreateSponsor = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetSponsors());
  }, []);

  return (
    <Layout>
      <CreatePostDiv>
        <div className="createpost_header">
          <h2>Create a Sponsor</h2>
        </div>
        <div className="createpost_content">
          <CreateSponsorComponent />
        </div>
      </CreatePostDiv>
    </Layout>
  );
};

export default CreateSponsor;
