import React, { useEffect, useState } from 'react';
import { LayoutDiv } from './styles/layout.styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../features/user/userServiceSync';
import LoaderScreen from './loaderScreen';
import { GetVolunteers } from '../features/volunteer/volunteerServiceSync';
import { GetTestimony } from '../features/testimony/testimonyServicesSync';

const Layout = ({ children }) => {
  const dispatch = useDispatch();

  const getToken = localStorage.getItem('token');

  const [loading, setLoading] = useState(false);

  const navigation = useNavigate();

  const { userDetails } = useSelector((state) => state.user);

  useEffect(() => {
    if (!getToken) {
      navigation('/');
    } else {
      if (userDetails === null) {
        dispatch(GetVolunteers());
        dispatch(GetTestimony());
        dispatch(getUser());
      }
    }
  }, [getToken]);

  return (
    <LayoutDiv>
      {userDetails ? (
        <div className="layout">{children}</div>
      ) : (
        <LoaderScreen />
      )}
    </LayoutDiv>
  );
};

export default Layout;
