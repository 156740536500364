import { createSlice } from '@reduxjs/toolkit';
import {
  EditCharity,
  GetCharity,
  GetCharityAdvanced,
  GetMyCharity,
  GetMyCharityAdvanced,
  GetSingleCharity,
  PostCharity,
  uploadImageCharity,
} from './charityServicesSync';

const initialState = {
  isError: false,
  isSuccess: false,
  isPicLoading: false,
  isBlogLoading: false,
  articleImageLoader: false,
  message: '',
  contentDetails: {},
  singleCharityDetails: {},
  charityArticleDetails: [],
  charityMyArticleDetails: [],
  mylimit: 10,
  myPagination: 0,
  ordersLimit: 10,
  orderPagination: 0,
  myCharityCount: 0,
  ordersCharityCount: 0,
  averageLimit: 10,
  charityPageLoad: false,
  charityTab: 'all',
  singleCharityPageLoader: false,
  singleCharityPageDetails: {},
};

export const charitySlice = createSlice({
  name: 'charity',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isPicLoading = false;
      state.isSuccess = false;
      state.isBlogLoading = false;
      state.message = '';
      state.contentDetails = {};
      state.charityArticleDetails = [];
      state.charityMyArticleDetails = [];
      state.charityPageLoad = false;
      state.mylimit = 10;
      state.myPagination = 0;
      state.ordersLimit = 10;
      state.orderPagination = 0;
      state.myCharityCount = 0;
      state.ordersCharityCount = 0;
      state.singleCharityPageLoader = false;
      state.singleCharityPageDetails = {};
      state.charityTab = 'all';
    },

    checkMessage: (state, action) => {
      state.message = action.payload;
    },

    updateCoverPics: (state, action) => {
      state.contentDetails = action.payload;
    },

    updateCharityPageLoader: (state, action) => {
      state.charityPageLoad = action.payload;
    },

    updateOrderCharityCount: (state, action) => {
      state.ordersCharityCount = action.payload;
    },

    updatesingleCharityDetails: (state, action) => {
      state.singleCharityDetails = action.payload;
    },

    updataesingleCharityPageLoader: (state, action) => {
      state.singleCharityPageLoader = action.payload;
    },

    updateCharityTab: (state, action) => {
      state.charityTab = action.payload;
    },
  },
  extraReducers: {
    [uploadImageCharity.pending]: (state, action) => {
      state.isPicLoading = true;
    },

    [uploadImageCharity.fulfilled]: (state, action) => {
      state.isPicLoading = false;
      state.message = action.payload?.message;
      state.contentDetails = action.payload?.data;
    },

    [uploadImageCharity.rejected]: (state, action) => {
      state.isPicLoading = false;
      state.message = action.payload?.message;
    },

    [PostCharity.pending]: (state, action) => {
      state.isBlogLoading = true;
    },

    [PostCharity.fulfilled]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
    },

    [PostCharity.rejected]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
    },

    [EditCharity.pending]: (state, action) => {
      state.isBlogLoading = true;
    },

    [EditCharity.fulfilled]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
    },

    [EditCharity.rejected]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
    },

    [GetCharity.pending]: (state, action) => {
      state.charityPageLoad = true;
    },

    [GetCharity.fulfilled]: (state, action) => {
      state.charityPageLoad = false;
      state.message = action.payload?.message;
      state.charityArticleDetails = action?.payload?.data;
      state.ordersCharityCount = action?.payload?.count;
    },

    [GetCharity.rejected]: (state, action) => {
      state.charityPageLoad = false;
      state.message = action.payload?.message;
    },

    [GetCharityAdvanced.pending]: (state, action) => {
      state.charityPageLoad = false;
    },

    [GetCharityAdvanced.fulfilled]: (state, action) => {
      state.charityPageLoad = false;
      state.message = action.payload?.message;
      state.orderPagination = state.orderPagination + 1;
      state.ordersLimit = state.ordersLimit + state.averageLimit;
      state.charityArticleDetails = [...action?.payload?.data];
      state.ordersCharityCount = action?.payload?.count;
    },

    [GetCharityAdvanced.rejected]: (state, action) => {
      state.charityPageLoad = false;
      state.message = action.payload?.message;
    },

    [GetMyCharity.pending]: (state, action) => {
      state.charityPageLoad = true;
    },

    [GetMyCharity.fulfilled]: (state, action) => {
      state.charityPageLoad = false;
      state.message = action.payload?.message;
      state.charityMyArticleDetails = action?.payload?.data;
      state.myCharityCount = action?.payload?.count;
    },

    [GetMyCharity.rejected]: (state, action) => {
      state.charityPageLoad = false;
      state.message = action.payload?.message;
    },

    [GetMyCharityAdvanced.pending]: (state, action) => {
      state.charityPageLoad = false;
    },

    [GetMyCharityAdvanced.fulfilled]: (state, action) => {
      state.charityPageLoad = false;
      state.message = action.payload?.message;
      state.myPagination = state.myPagination + 1;
      state.mylimit = state.mylimit + state.averageLimit;
      state.charityMyArticleDetails = [...action?.payload?.data];
      state.myCharityCount = action?.payload?.count;
    },

    [GetMyCharityAdvanced.rejected]: (state, action) => {
      state.charityPageLoad = false;
      state.message = action.payload?.message;
    },

    [GetSingleCharity.pending]: (state, action) => {
      state.singleCharityPageLoader = true;
    },

    [GetSingleCharity.fulfilled]: (state, action) => {
      state.singleCharityPageLoader = false;
      state.message = action.payload?.message;
      state.singleCharityPageDetails = action.payload?.data;
    },

    [GetSingleCharity.rejected]: (state, action) => {
      state.singleCharityPageLoader = false;
      state.message = action.payload?.message;
    },
  },
});

export const {
  reset,
  checkMessage,
  updateCoverPics,
  updateCharityPageLoader,
  updateOrderCharityCount,
  updatesingleCharityDetails,
  updataesingleCharityPageLoader,
  updateCharityTab,
} = charitySlice.actions;

export default charitySlice.reducer;
