import React, { useEffect } from "react";
import Layout from "../../utils/layout";
import { MessageDiv } from "./styles/message.style";
import { useDispatch, useSelector } from "react-redux";
import MiniLoader from "../../utils/miniLoader";
import AllVolunteersComponent from "../../components/DashboardComponent/allVolunteerComponent";
import { GetVolunteers } from "../../features/volunteer/volunteerServiceSync";

function AllVolunteer() {
  const dispatch = useDispatch();

  const { volunteerPageLoad } = useSelector((state) => state.volunteer);

  useEffect(() => {
    dispatch(GetVolunteers());
  }, []);

  return (
    <Layout>
      <MessageDiv>
        <div className="message_header">
          <h2>All Volunteers</h2>
        </div>
        {volunteerPageLoad ? (
          <MiniLoader />
        ) : (
          <div className="message_chat">
            <AllVolunteersComponent />
          </div>
        )}
      </MessageDiv>
    </Layout>
  );
}

export default AllVolunteer;
