import { createSlice } from '@reduxjs/toolkit';

import {
  DeleteAdmin,
  EditUserFromAdmin,
  GeneralTotalCount,
  GetAllUserDetail,
  GetAllUserDetailAdvance,
  GetOtherUserDetail,
  SearchForUserDetail,
  editUser,
  editUserPassword,
  getUser,
} from './userServiceSync';

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  isRegister: false,
  message: '',
  userDetails: null,
  editLoader: false,
  totalCountLoader: false,
  countDetails: [],
  completeUserDetails: {},
  userCollectionPage: 1,
  userCollectionLimit: 10,
  userCollectionCounter: 0,
  completeUserLoader: false,
  otherUserLoader: false,
  otherUserDetails: {},
  editOtherUserLoader: false,
  advanceLoading: false,
  searchCompleteUserDetails: [],
  userPrefillingDetails: {},
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.message = '';
      state.userDetails = null;
      state.editLoader = false;
      state.totalCountLoader = false;
      state.countDetails = [];
      state.completeUserDetails = {};
      state.completeUserLoader = false;
      state.otherUserLoader = false;
      state.otherUserDetails = {};
      state.editOtherUserLoader = false;
      state.advanceLoading = false;
      state.searchCompleteUserDetails = [];
      state.userCollectionPage = 1;
      state.userCollectionLimit = 10;
      state.userCollectionCounter = 0;
      state.userPrefillingDetails = {};
    },

    EditedLoader: (state, action) => {
      state.editLoader = action.payload;
    },

    UpdateCountDetails: (state, action) => {
      state.countDetails = action.payload;
    },

    upDateCompleteSkip: (state, action) => {
      state.userCollectionPage = action.payload;
    },

    upDateCompleteCounter: (state, action) => {
      state.userCollectionCounter = action.payload;
    },

    updateSearchCompleteUserDetails: (state, action) => {
      state.searchCompleteUserDetails = [];
    },

    updateUsersDetails: (state, action) => {
      state.completeUserDetails = action.payload;
    },

    updateUserPrefillDetails: (state, action) => {
      console.log(action.payload, 'since the state work properly');
      state.userPrefillingDetails = action.payload;
    },
  },
  extraReducers: {
    [getUser.pending]: (state, action) => {
      state.isLoading = true;
    },

    [getUser.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.message = action.payload?.message;
      state.userDetails = action.payload?.data;
    },

    [getUser.rejected]: (state, action) => {
      state.isLoading = false;
      state.isRegister = false;
      state.message = action.payload?.message;
    },

    [editUser.pending]: (state, action) => {
      state.editLoader = true;
    },

    [editUser.fulfilled]: (state, action) => {
      state.editLoader = false;
      state.message = action.payload?.message;
    },

    [editUser.rejected]: (state, action) => {
      state.editLoader = false;
      state.isRegister = false;
      state.message = action.payload?.message;
    },

    [DeleteAdmin.pending]: (state, action) => {
      state.editLoader = true;
    },

    [DeleteAdmin.fulfilled]: (state, action) => {
      state.editLoader = false;
      state.message = action.payload?.message;
    },

    [DeleteAdmin.rejected]: (state, action) => {
      state.editLoader = false;
      state.isRegister = false;
      state.message = action.payload?.message;
    },

    [editUserPassword.pending]: (state, action) => {
      state.editLoader = true;
    },

    [editUserPassword.fulfilled]: (state, action) => {
      state.editLoader = false;
      state.message = action.payload?.message;
      state.userDetails = action.payload?.data;
    },

    [editUserPassword.rejected]: (state, action) => {
      state.editLoader = false;
      state.isRegister = false;
      state.message = action.payload?.message;
    },

    [GeneralTotalCount.pending]: (state, action) => {
      state.totalCountLoader = true;
    },

    [GeneralTotalCount.fulfilled]: (state, action) => {
      state.totalCountLoader = false;
      state.message = action.payload?.message;
      state.countDetails = action.payload?.data;
    },

    [GeneralTotalCount.rejected]: (state, action) => {
      state.totalCountLoader = false;
      state.message = action.payload?.message;
    },

    [GetAllUserDetail.pending]: (state, action) => {
      state.completeUserLoader = true;
    },

    [GetAllUserDetail.fulfilled]: (state, action) => {
      state.completeUserLoader = false;
      state.message = action.payload?.message;
      state.completeUserDetails = action.payload?.data;
    },

    [GetAllUserDetail.rejected]: (state, action) => {
      state.completeUserLoader = false;
      state.message = action.payload?.message;
    },

    [GetAllUserDetailAdvance.pending]: (state, action) => {
      state.advanceLoading = true;
    },

    [GetAllUserDetailAdvance.fulfilled]: (state, action) => {
      state.advanceLoading = false;
      state.message = action.payload?.message;
      state.completeUserDetails = action.payload?.data;
    },

    [GetAllUserDetailAdvance.rejected]: (state, action) => {
      state.advanceLoading = false;
      state.message = action.payload?.message;
    },

    [GetOtherUserDetail.pending]: (state, action) => {
      state.otherUserLoader = true;
    },

    [GetOtherUserDetail.fulfilled]: (state, action) => {
      state.otherUserLoader = false;
      state.message = action.payload?.message;
      state.otherUserDetails = action.payload?.data;
    },

    [GetOtherUserDetail.rejected]: (state, action) => {
      state.otherUserLoader = false;
      state.message = action.payload?.message;
    },

    [EditUserFromAdmin.pending]: (state, action) => {
      state.editOtherUserLoader = true;
    },

    [EditUserFromAdmin.fulfilled]: (state, action) => {
      state.editOtherUserLoader = false;
      state.message = action.payload?.message;
      state.otherUserDetails = action.payload?.data;
    },

    [EditUserFromAdmin.rejected]: (state, action) => {
      state.editOtherUserLoader = false;
      state.message = action.payload?.message;
    },

    [SearchForUserDetail.pending]: (state, action) => {
      state.completeUserLoader = true;
    },

    [SearchForUserDetail.fulfilled]: (state, action) => {
      state.completeUserLoader = false;
      state.message = action.payload?.message;
      state.searchCompleteUserDetails = action.payload?.data;
    },

    [SearchForUserDetail.rejected]: (state, action) => {
      state.completeUserLoader = false;
      state.message = action.payload?.message;
    },
  },
});

export const {
  reset,
  EditedLoader,
  UpdateCountDetails,
  upDateCompleteSkip,
  updateSearchCompleteUserDetails,
  upDateCompleteCounter,
  updateUserPrefillDetails,
  updateUsersDetails,
} = userSlice.actions;
export default userSlice.reducer;
