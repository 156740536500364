import { createSlice } from '@reduxjs/toolkit';
import { AllTransaction, AllTransactionStatus } from './paymentServicesSync';

const initialState = {
  isError: false,
  isSuccess: false,
  transactionLoader: false,
  message: '',
  totalTransaction: {},
  mylimit: 10,
  page: 1,
  selectedStatus: '',
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.transactionLoader = false;
      state.isSuccess = false;
      state.totalTransaction = {};
      state.message = '';
      state.mylimit = 10;
      state.page = 1;
      state.selectedStatus = '';
    },

    updateStatus: (state, action) => {
      state.selectedStatus = action.payload;
    },
  },
  extraReducers: {
    [AllTransaction.pending]: (state, action) => {
      state.transactionLoader = true;
    },

    [AllTransaction.fulfilled]: (state, action) => {
      state.transactionLoader = false;
      state.message = action.payload?.message;
      state.page = action.payload?.data?.meta?.page;
      state.totalTransaction = action.payload?.data;
    },

    [AllTransaction.rejected]: (state, action) => {
      state.transactionLoader = false;
      state.message = action.payload?.message;
    },

    [AllTransactionStatus.pending]: (state, action) => {
      state.transactionLoader = true;
    },

    [AllTransactionStatus.fulfilled]: (state, action) => {
      state.transactionLoader = false;
      state.message = action.payload?.message;
      state.page = action.payload?.data?.meta?.page;
      state.totalTransaction = action.payload?.data;
    },

    [AllTransactionStatus.rejected]: (state, action) => {
      state.transactionLoader = false;
      state.message = action.payload?.message;
    },
  },
});

export const { reset, updateStatus } = paymentSlice.actions;

export default paymentSlice.reducer;
