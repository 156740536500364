import React, { useEffect } from 'react';
import Layout from '../../utils/layout';
import { StoriesDiv } from './styles/message.style';
import { useDispatch, useSelector } from 'react-redux';
import { reset } from '../../features/services/servicesSlices';
import { GetCharity } from '../../features/charity/charityServicesSync';
import CharityComponent from '../../components/DashboardComponent/charityComponent';

const Charity = () => {
  const dispatch = useDispatch();

  const { ordersLimit } = useSelector((state) => state.charity);

  useEffect(() => {
    dispatch(GetCharity(ordersLimit));

    return () => {
      dispatch(reset());
    };
  }, []);

  return (
    <Layout>
      <StoriesDiv>
        <div className="stories_header">
          <h2>Donation Page</h2>
        </div>

        <div className="stories_content">
          <CharityComponent />
        </div>
      </StoriesDiv>
    </Layout>
  );
};

export default Charity;
