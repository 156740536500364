import { toast } from "react-toastify";
import APIs from "../apiUrl";

const SponsorImageUpload = async (payload) => {
  try {
    const response = await APIs.post("/uploadfiles", payload);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const editASponsor = async (payload) => {
  try {
    const response = await APIs.patch("/edit_sponsor", payload);

    if (response?.data?.message === "success") {
      toast("Posted successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const postSponsor = async (payload) => {
  try {
    const response = await APIs.post("/create_sponsor", payload);

    if (response?.data?.message === "success") {
      toast("Posted successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getSponsor = async () => {
  try {
    const response = await APIs.get(`/all_sponsor`);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getMyCreatedSponsor = async () => {
  try {
    const response = await APIs.get(`/all_sponsor_for_admin`);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const SponsorServices = {
  SponsorImageUpload,
  postSponsor,
  getSponsor,
  editASponsor,
  getMyCreatedSponsor,
};

export default SponsorServices;
