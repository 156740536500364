import styled from "styled-components";

export const CreatedAssignment = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;

  .assignmentWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .assessmentAlign {
      width: 45%;
      display: flex;
      flex-direction: column;
      gap: 3px;

      input {
        padding: 10px 12px;
      }
    }
  }

  .assignmentWrapperWrap {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .assessmentAlign {
      width: 45%;
      display: flex;
      flex-direction: column;
      gap: 3px;

      input {
        padding: 10px 12px;
      }
    }
  }

  .assessment_editor {
    h2 {
      margin: 5px 0px;
    }

    .assessment_editor_div {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .assessmentAlign {
        width: 45%;
        display: flex;
        flex-direction: column;
        gap: 3px;

        input {
          padding: 10px 12px;
        }
      }

      .assessment_editor_button {
        .paddings {
          padding: 10px 20px;
        }
      }
    }
  }

  .assessment_editor_assessment {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .assessment_edit_state {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;
      border: 1px solid #00000056;
      padding: 5px 8px;

      .assessment_edit_state_counter_wrapper {
        margin: 0px;

        p {
          margin: 0px;
          font-size: 16px;
          font-weight: 600;
          color: #141414;
        }
      }

      .assessment_edit_state_wrapper {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .assessment_edit_state_style {
          display: flex;
          flex-direction: column;
          gap: 3px;
        }

        .assessment_edit_icons {
          display: flex;
          align-items: center;
          gap: 15px;

          .assessment_edit_icons_body {
            display: flex;
            align-items: center;
            justify-content: center;

            .assessment_edit_icons_delete_icon {
              font-size: 20px;
              color: #141414;
            }

            .assessment_edit_icons_edit_icon {
              font-size: 20px;
              color: #141414;
            }
          }
        }
      }
    }
  }

  .assessment_editor_editor_button {
    display: flex;
    justify-content: flex-end;

    .paddings {
      padding: 10px 20px;
    }
  }

  .assessment_editor_editor_button_edit_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .paddings {
      padding: 10px 20px;
    }

    .assessment_editor_editor_button_edit_sub {
      display: flex;
      align-items: center;
      gap: 30px;

      .paddings {
        padding: 10px 20px;
      }
    }
  }
`;
