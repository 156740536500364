import React from "react";
import Layout from "../../utils/layout";
import { CreatePostDiv } from "./styles/message.style";
import CreateResourcesComponent from "../../components/DashboardComponent/createResourcesComponent";

const CreateResources = () => {
  return (
    <Layout>
      <CreatePostDiv>
        <div className="createpost_header">
          <h2>Create a Resources</h2>
        </div>
        <div className="createpost_content">
          <CreateResourcesComponent />
        </div>
      </CreatePostDiv>
    </Layout>
  );
};

export default CreateResources;
