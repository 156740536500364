import { createSlice } from "@reduxjs/toolkit";
import {
  EditServices,
  GetMyServices,
  GetMyServicesAdvanced,
  GetServices,
  GetServicesAdvanced,
  GetSingleServices,
  PostServices,
  uploadImageServices,
} from "./servicesServicesSync";

const initialState = {
  isError: false,
  isSuccess: false,
  isPicLoading: false,
  isBlogLoading: false,
  articleImageLoader: false,
  message: "",
  contentDetails: {},
  singleServicesDetails: {},
  servicesArticleDetails: [],
  servicesMyArticleDetails: [],
  mylimit: 10,
  myPagination: 0,
  ordersLimit: 10,
  orderPagination: 0,
  myServicesCount: 0,
  ordersServicesCount: 0,
  averageLimit: 10,
  servicesPageLoad: false,
  servicesTab: "all",
  singleServicesPageLoader: false,
  singleServicesPageDetails: {},
};

export const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isPicLoading = false;
      state.isSuccess = false;
      state.isBlogLoading = false;
      state.message = "";
      state.contentDetails = {};
      state.servicesArticleDetails = [];
      state.servicesMyArticleDetails = [];
      state.servicesPageLoad = false;
      state.mylimit = 10;
      state.myPagination = 0;
      state.ordersLimit = 10;
      state.orderPagination = 0;
      state.myServicesCount = 0;
      state.ordersServicesCount = 0;
      state.singleServicesPageLoader = false;
      state.singleServicesDetails = {};
      state.servicesTab = "all";
    },

    checkMessage: (state, action) => {
      state.message = action.payload;
    },

    updateCoverPics: (state, action) => {
      state.contentDetails = action.payload;
    },

    updateServicesPageLoader: (state, action) => {
      state.servicesPageLoad = action.payload;
    },

    updateOrderServicesCount: (state, action) => {
      state.ordersServicesCount = action.payload;
    },

    updateSingleServicesDetails: (state, action) => {
      state.singleServicesDetails = action.payload;
    },

    updataeSingleServicesPageLoader: (state, action) => {
      state.singleServicesPageLoader = action.payload;
    },

    updateServicesTab: (state, action) => {
      state.servicesTab = action.payload;
    },
  },
  extraReducers: {
    [uploadImageServices.pending]: (state, action) => {
      state.isPicLoading = true;
    },

    [uploadImageServices.fulfilled]: (state, action) => {
      state.isPicLoading = false;
      state.message = action.payload?.message;
      state.contentDetails = action.payload?.data;
    },

    [uploadImageServices.rejected]: (state, action) => {
      state.isPicLoading = false;
      state.message = action.payload?.message;
    },

    [PostServices.pending]: (state, action) => {
      state.isBlogLoading = true;
    },

    [PostServices.fulfilled]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
    },

    [PostServices.rejected]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
    },

    [EditServices.pending]: (state, action) => {
      state.isBlogLoading = true;
    },

    [EditServices.fulfilled]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
    },

    [EditServices.rejected]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
    },

    [GetServices.pending]: (state, action) => {
      state.servicesPageLoad = true;
    },

    [GetServices.fulfilled]: (state, action) => {
      console.log(action?.payload?.data, "david ejike is a greate man");
      state.servicesPageLoad = false;
      state.message = action.payload?.message;
      state.servicesArticleDetails = action?.payload?.data;
      state.ordersServicesCount = action?.payload?.count;
    },

    [GetServices.rejected]: (state, action) => {
      state.servicesPageLoad = false;
      state.message = action.payload?.message;
    },

    [GetServicesAdvanced.pending]: (state, action) => {
      state.servicesPageLoad = false;
    },

    [GetServicesAdvanced.fulfilled]: (state, action) => {
      state.servicesPageLoad = false;
      state.message = action.payload?.message;
      state.orderPagination = state.orderPagination + 1;
      state.ordersLimit = state.ordersLimit + state.averageLimit;
      state.servicesArticleDetails = [...action?.payload?.data];
      state.ordersServicesCount = action?.payload?.count;
    },

    [GetServicesAdvanced.rejected]: (state, action) => {
      state.servicesPageLoad = false;
      state.message = action.payload?.message;
    },

    [GetMyServices.pending]: (state, action) => {
      state.servicesPageLoad = true;
    },

    [GetMyServices.fulfilled]: (state, action) => {
      state.servicesPageLoad = false;
      state.message = action.payload?.message;
      state.servicesMyArticleDetails = action?.payload?.data;
      state.myServicesCount = action?.payload?.count;
    },

    [GetMyServices.rejected]: (state, action) => {
      state.servicesPageLoad = false;
      state.message = action.payload?.message;
    },

    [GetMyServicesAdvanced.pending]: (state, action) => {
      state.servicesPageLoad = false;
    },

    [GetMyServicesAdvanced.fulfilled]: (state, action) => {
      state.servicesPageLoad = false;
      state.message = action.payload?.message;
      state.myPagination = state.myPagination + 1;
      state.mylimit = state.mylimit + state.averageLimit;
      state.servicesMyArticleDetails = [...action?.payload?.data];
      state.myServicesCount = action?.payload?.count;
    },

    [GetMyServicesAdvanced.rejected]: (state, action) => {
      state.servicesPageLoad = false;
      state.message = action.payload?.message;
    },

    [GetSingleServices.pending]: (state, action) => {
      state.singleServicesPageLoader = true;
    },

    [GetSingleServices.fulfilled]: (state, action) => {
      state.singleServicesPageLoader = false;
      state.message = action.payload?.message;
      state.singleServicesDetails = action.payload?.data;
    },

    [GetSingleServices.rejected]: (state, action) => {
      state.singleServicesPageLoader = false;
      state.message = action.payload?.message;
    },
  },
});

export const {
  reset,
  checkMessage,
  updateCoverPics,
  updateServicesPageLoader,
  updateOrderServicesCount,
  updateSingleServicesDetails,
  updataeSingleServicesPageLoader,
  updateServicesTab,
} = servicesSlice.actions;

export default servicesSlice.reducer;
