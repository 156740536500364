import { toast } from "react-toastify";
import APIs from "../apiUrl";

const MinistriesImageUpload = async (payload) => {
  try {
    const response = await APIs.post("/uploadfiles", payload);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const postMinistries = async (payload) => {
  try {
    const response = await APIs.post("/create_ministries", payload);

    if (response?.data?.message === "success") {
      toast("Posted successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const editAMinistry = async (payload) => {
  try {
    const response = await APIs.patch("/edit_ministries", payload);

    if (response?.data?.message === "success") {
      toast("Posted successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getMinistries = async (payload) => {
  try {
    const response = await APIs.get(`/get_ministries/${payload}`);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getMyMinistries = async (payload) => {
  try {
    const response = await APIs.get(`/get_my_ministries/${payload}`);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getSingleMinistry = async (payload) => {
  try {
    const response = await APIs.get(`/get_single_ministry/${payload}`);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const MinistriesServices = {
  MinistriesImageUpload,
  postMinistries,
  getMinistries,
  getMyMinistries,
  editAMinistry,
  getSingleMinistry,
};

export default MinistriesServices;
