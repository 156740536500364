import React, { useEffect } from "react";
import Layout from "../../utils/layout";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LoaderScreen from "../../utils/loaderScreen";
import { GetSingleResources } from "../../features/resources/resourcesServicesSync";
import SingleServicesComponent from "../../components/DashboardComponent/singleServicesComponent";
import { GetSingleServices } from "../../features/services/servicesServicesSync";

const ViewServices = () => {
  const dispatch = useDispatch();

  const { singleServicesPageLoader } = useSelector((state) => state.services);

  const { services } = useParams();

  useEffect(() => {
    dispatch(GetSingleServices(services));
  }, [services]);

  return (
    <Layout>
      {singleServicesPageLoader ? (
        <LoaderScreen />
      ) : (
        <SingleServicesComponent />
      )}
    </Layout>
  );
};

export default ViewServices;
