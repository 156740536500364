import { styled } from "styled-components";

export const SingleUserDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 90px;

  .singleUserHeader {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .singleUserHeader_details {
      display: flex;
      align-items: flex-start;
      gap: 30px;

      .singleUserHeader_details_image {
        width: 150px;
        height: 150px;
        border-radius: 50%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .singleUserHeader_details_wrapper {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .singleUserHeader_details_wrapper_content {
          display: flex;
          align-items: flex-start;
          gap: 15px;

          .singleUserHeader_title {
            font-size: 15px;
            color: #272727;
            font-weight: 500;
          }

          .singleUserHeader_text {
            font-size: 16px;
            color: #272727;
            font-weight: 700;
          }
        }
      }
    }

    .singleUserHeader_button {
      button {
        border: none;
        outline: none;
        background-color: transparent;
        height: 35px;
        font-size: 14px;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        padding: 0 15px;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f2f4f7;
        cursor: pointer;
      }
    }
  }

  .singleUserOther {
    display: flex;
    flex-direction: column;
    gap: 20px;

    h3 {
      font-size: 20px;
      color: #272727;
      font-weight: 700;
    }

    .singleUserOther_header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 100px;

      .singleUserOther_content {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .singleUserOther_content_label {
          display: flex;
          align-items: center;
          gap: 12px;

          .singleUserHeader_title {
            font-size: 15px;
            color: #272727;
            font-weight: 500;
          }

          .singleUserHeader_text {
            font-size: 16px;
            color: #272727;
            font-weight: 700;
          }
        }
      }

      .singleUserOther_social {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .singleUserOther_social_wrapper {
          display: flex;
          align-items: center;
          gap: 12px;
          cursor: pointer;
          text-decoration: none;

          .singleUserOther_social_icon_body {
            display: flex;
            align-items: center;
            justify-content: center;

            .singleUserOther_social_icon {
              font-size: 24px;
              color: #272727;
            }
          }

          p {
            font-size: 16px;
            color: #272727;
            font-weight: 800;
          }
        }
      }
    }
  }
`;
